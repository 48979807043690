import { createSelector } from 'reselect';

import { SIGNUP_STEPS, SLICE_NAME } from 'features/signUp/constants';

const {
  OWNER_INFO: { name: OWNER_INFO },
  OWNER_MOTIVATION: { name: OWNER_MOTIVATION },
  BUSINESS_INFO: { name: BUSINESS_INFO },
  ACCOUNT_DETAILS: { name: ACCOUNT_DETAILS },
} = SIGNUP_STEPS;

const getServerErrors = state => state.getIn([SLICE_NAME, 'serverErrors']);

const createErrorSelector = (entity, field) =>
  createSelector(getServerErrors, errors =>
    errors?.getIn([entity, field])?.join(', ')
  );

export const getEmailServerErrors = createErrorSelector('user', 'email');

export const getPhoneServerErrors = createErrorSelector('user', 'phone');

export const getSubmitPending = state =>
  state.getIn([SLICE_NAME, 'submitPending']);

export const getBusinessNameServerErrors = createErrorSelector(
  'location',
  'name'
);

export const getLocationNameCityState = state =>
  state.getIn([SLICE_NAME, 'locationNameCityState']);

export const getSubmitErrors = state =>
  state.getIn([SLICE_NAME, 'serverErrors']).toJS();

export const getCompletedStep = state =>
  state.getIn([SLICE_NAME, 'completedStep']);

export const getOwnerInfo = state =>
  Object.fromEntries(state.getIn([SLICE_NAME, OWNER_INFO]));

export const getOwnerMotivation = state =>
  Object.fromEntries(state.getIn([SLICE_NAME, OWNER_MOTIVATION]));

export const getOwnerFirstName = state => {
  const fullName = state.getIn([SLICE_NAME, OWNER_INFO, 'fullName']);
  return fullName.split(' ')[0];
};

export const getBusinessName = state =>
  state.getIn([SLICE_NAME, BUSINESS_INFO, 'businessName']);

export const getOwnerInitials = state => {
  const fullName = state.getIn([SLICE_NAME, OWNER_INFO, 'fullName']);
  const initials = fullName
    .split(' ')
    .map(c => c[0])
    .join('');

  return initials.length > 1
    ? `${initials[0]}${initials[initials.length - 1]}`
    : initials[0];
};

export const getBusinessInfo = state =>
  Object.fromEntries(state.getIn([SLICE_NAME, BUSINESS_INFO]));

export const getAccountDetails = state =>
  Object.fromEntries(state.getIn([SLICE_NAME, ACCOUNT_DETAILS]));

export const getObeSignupRebrand = state =>
  state.getIn([SLICE_NAME, 'obeSignupRebrand']);

export const getLoadingExperimentValue = state =>
  state.getIn([SLICE_NAME, 'loadingExperimentValue']);

export const getProductGrowthSignupOnboardingStyleValue = state =>
  state.getIn([SLICE_NAME, 'productgrowthSignupOnboardingStyle']);

export const getIsProductGrowthSignupOnboardingStyleVariant = state =>
  state.getIn([SLICE_NAME, 'productgrowthSignupOnboardingStyle']) === 1;

export const getProductGrowthReferralSignInExperimentValue = state =>
  state.getIn([SLICE_NAME, 'productgrowthReferralsSignIn']);
