import { HAS_MANY } from '../Entities/relationshipTypes';
import { createResource } from '../Resources';

const Location = createResource({
  name: 'location',
  path: '/core/location',
  schema: {
    relationships: {
      shifts: {
        relationshipType: HAS_MANY,
        inverseOf: 'owner',
      },
    },
  },
});

export default Location;
