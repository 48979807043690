import { Variant } from './List';

export const leftContentWidth = (isMedium: boolean, variant: Variant) => {
  if (isMedium) {
    switch (variant) {
      case 'bullet':
        return 24;
      case 'check':
        return 32;
      case 'checklist':
        return 32;
      case 'number':
        return 30;
      case 'icon':
        return 40;
      case 'illustration':
        return 40;
      default:
        return 24;
    }
  } else {
    switch (variant) {
      case 'bullet':
        return 24;
      case 'check':
        return 32;
      case 'checklist':
        return 32;
      case 'number':
        return 36;
      case 'icon':
        return 36;
      case 'illustration':
        return 36;
      default:
        return 24;
    }
  }
};
