import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

import PurchaseMethodModule from 'features/biller/implementations/stripe/PurchaseMethodModule';
import PurchaseMethodButton from 'features/biller/implementations/stripe/PurchaseMethodModule/PurchaseMethodButton';
import { CheckoutProps } from 'features/monetization/TipManagerCheckoutDrawer/views/types';

import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx, useTrackUxOnMount } from 'util/uxEvents';

const Checkout: React.FC<CheckoutProps> = ({
  locationName,
  onSuccess,
  onLoading,
  isLoading,
  price,
  subscriptionType,
}) => {
  const trackUx = useTrackUx();
  const primaryButtonText = toI18n('checkout_drawer.tip_manager.cta');

  useTrackUxOnMount(EVENT_ACTIONS.SIDE_DRAWER_VIEWED, TRACK_ACTION_TYPES.VIEW, {
    stage: 'checkout_started',
  });

  const handleOnClick = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      button_text: primaryButtonText,
      element: 'primary_conversion_cta',
    });
  }, [primaryButtonText, trackUx]);

  return (
    <>
      <Box ph={32}>
        <Box gap={12} column>
          <Text variant="heading2" i18n="checkout_drawer.tip_manager.title" />
          <Text
            variant="body"
            color="mono900"
            i18n="checkout_drawer.tip_manager.subtitle"
            i18nProps={{ locationName }}
            asHTML
          />
        </Box>
        <PurchaseMethodModule
          onSuccess={onSuccess}
          onLoading={onLoading}
          price={price}
          subscriptionType={subscriptionType}
        />
        <Box mt={24}>
          <Text
            variant="bodySm"
            color="mono900"
            i18n="checkout_drawer.tip_manager.footer"
            asHTML
          />
        </Box>
      </Box>
      <Box sticky hcenter mt={32} p={32} bottom={0} bgcolor="mono0">
        <PurchaseMethodButton
          fullWidth
          variant="primary"
          isLoading={isLoading}
          onClick={handleOnClick}
        >
          {primaryButtonText}
        </PurchaseMethodButton>
      </Box>
    </>
  );
};

export default Checkout;
