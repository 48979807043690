import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import {
  getListData,
  updateLocationSettings,
  updateUserSettings,
} from './actions';
import { SLICE_NAME } from './constants';

export const initialState = fromJS({
  getListData: {
    isPending: false,
    error: {},
    success: {},
  },
  updateLocationSettings: {
    isPending: false,
    error: {},
    success: {},
  },
  updateUserSettings: {
    isPending: false,
    error: {},
    success: {},
  },
  snackbarMessage: '',
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,

  reducers: {
    setSnackbarMessage: (state, { payload }) =>
      state.set('snackbarMessage', payload),
    resetSnackbarMessage: state => state.set('snackbarMessage', ''),
  },

  extraReducers: builder => {
    builder.addCase(getListData.pending, state =>
      state.mergeDeep({
        getListData: {
          isPending: true,
          success: {},
          error: {},
        },
      })
    );

    builder.addCase(getListData.fulfilled, (state, { payload }) =>
      state.mergeDeep({
        getListData: {
          isPending: false,
          success: {
            listData: payload.list,
            showSMSColumn: payload.show_sms_column,
            currentLocation: payload.currentLocation,
            isQsgRetrial: payload.qsg_retrial,
            nextStep: payload.next_task,
            showTooltipFirstLand: payload.show_get_help_first_land_tooltip,
          },
          error: {},
        },
      })
    );

    builder.addCase(getListData.rejected, (state, { payload }) =>
      state.mergeDeep({
        getListData: {
          isPending: false,
          success: {},
          error: payload,
        },
      })
    );

    builder.addCase(updateLocationSettings.pending, state =>
      state.mergeDeep({
        updateLocationSettings: {
          isPending: true,
          success: {},
          error: {},
        },
      })
    );

    builder.addCase(updateLocationSettings.fulfilled, (state, { payload }) =>
      state.mergeDeep({
        updateLocationSettings: {
          isPending: false,
          success: payload,
          error: {},
        },
      })
    );

    builder.addCase(updateLocationSettings.rejected, (state, { payload }) =>
      state.mergeDeep({
        updateLocationSettings: {
          isPending: false,
          success: {},
          error: payload,
        },
      })
    );

    builder.addCase(updateUserSettings.pending, state =>
      state.mergeDeep({
        updateUserSettings: {
          isPending: true,
          success: {},
          error: {},
        },
      })
    );

    builder.addCase(updateUserSettings.fulfilled, (state, { payload }) =>
      state.mergeDeep({
        updateUserSettings: {
          isPending: false,
          success: payload,
          error: {},
        },
      })
    );

    builder.addCase(updateUserSettings.rejected, (state, { payload }) =>
      state.mergeDeep({
        updateUserSettings: {
          isPending: false,
          success: {},
          error: payload,
        },
      })
    );
  },
});

export const { actions, reducer } = slice;
