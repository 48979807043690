import { fromJS, Map, Set } from 'immutable';
import { isEmpty } from 'lodash';
import { combineReducers } from 'redux-immutable';

import { actionTypes as addTeamActionTypes } from 'actions/addTeam';
import { actionTypes as employeeViewActionTypes } from 'actions/employeeView';
import { actionTypes as sessionActionTypes } from 'actions/session';
import { actionTypes as timecardActionTypes } from 'actions/timecard';
import { actionTypes as timesheetsActionTypes } from 'actions/timesheets';
import { actionTypes as payrollSummaryActionTypes } from 'actions/timesheets/payrollSummaries';

const dailyReviewActionTypes = {
  FETCH_DAILY_REVIEW_ROWS_FULFILLED:
    'dailyReview/fetchDailyReviewRows/fulfilled',
  FETCH_TIMESHEETS_INITIAL_DATA_FULFILLED:
    'dailyReview/fetchTimesheetsInitialData/fulfilled',
  FETCH_TIMESHEETS_INITIAL_DATA_PENDING:
    'dailyReview/fetchTimesheetsInitialData/pending',
};
import {
  INITIAL_GENERAL_STATE,
  INITIAL_PAGE_STATE,
  INITIAL_PAYROLL_SUMMARIES_STATE,
} from 'features/timesheets/constants';

import { trackCustomTimingEvent } from 'util/dataDogRUMCustomTimings';

const general = (state = INITIAL_GENERAL_STATE, action) => {
  switch (action.type) {
    case sessionActionTypes.UPDATE_SESSION:
      return state.merge(
        (action.payload.timesheets && action.payload.timesheets.general) || {}
      );
    case timesheetsActionTypes.OPEN_MONETIZATION_UPSELL_EXPORT_MODAL:
      return state.merge({
        monetizationUpsellExportModalOpen: true,
      });
    case timesheetsActionTypes.CLOSE_MONETIZATION_UPSELL_EXPORT_MODAL:
      return state.merge({
        monetizationUpsellExportModalOpen: false,
      });
    case timesheetsActionTypes.FETCH_INIT_DATA_SUCCESS:
      return state.merge({
        canShowGroupByTooltip: action.payload.canShowGroupByTooltip,
      });
    case timesheetsActionTypes.TOGGLE_GROUP_BY_TOOLTIP:
      return state.merge({
        groupByTooltipIsOpen: action.payload.isOpen,
        canShowGroupByTooltip: false,
      });
    case timesheetsActionTypes.TOGGLE_SHOW_TOOLS:
      return state.merge({
        showTools: !state.get('showTools'),
      });
    case timesheetsActionTypes.SET_TIME_SHEETS_GUIDED_SETUP_MAYBE_LATER_IN_SESSION:
      return state.setIn(
        ['timesheetsZeroState', 'shouldSeeTimesheetsZeroState'],
        false
      );
    default:
      return state;
  }
};

const page = (state = INITIAL_PAGE_STATE, action) => {
  switch (action.type) {
    case timesheetsActionTypes.HIDE_PPR_NAV:
      return state.set('showNav', false);
    case timesheetsActionTypes.SHOW_PPR_NAV:
      return state.set('showNav', true);
    case timesheetsActionTypes.UPDATE_HAS_TIMECARDS:
      return state.set('companyHasTimecards', true);
    case payrollSummaryActionTypes.FETCH_PAYROLL_PERIODS_SUCCESS:
      return state.set('locationEmployees', fromJS(action.payload.employees));
    case addTeamActionTypes.CREATE_EMPLOYEE_SUCCESS: {
      const employee = {
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        avatar: action.payload.avatar,
        user_id: action.payload.id,
        full_name: `${action.payload.first_name} ${
          action.payload.last_name || ''
        }`,
        id: action.payload.jobs[0].id,
        job_id: action.payload.jobs[0].id,
      };

      return state.merge({
        locationEmployees: fromJS([
          ...state.get('locationEmployees'),
          employee,
        ]),
        locationRolesUpdated: new Date().getTime(),
      });
    }
    case addTeamActionTypes.CREATE_EMPLOYEES_SUCCESS: {
      const employees = action.payload.users.map(user => ({
        first_name: user.first_name,
        last_name: user.last_name,
        avatar: user.avatar.medium.url,
        user_id: user.id,
        full_name: `${user.first_name} ${user.last_name}`,
        id: action.payload.jobs.find(j => j.user_id === user.id).id,
        job_id: action.payload.jobs.find(j => j.user_id === user.id).id,
      }));

      return state.merge({
        locationEmployees: fromJS([
          ...state.get('locationEmployees'),
          ...employees,
        ]),
        locationRolesUpdated: new Date().getTime(),
      });
    }
    case timesheetsActionTypes.TOGGLE_ALL_CARDS:
      return state.set('collapsedCards', Set(action.payload.keys));
    case timesheetsActionTypes.COLLAPSE_CARD: {
      const cards = state.get('collapsedCards');
      const key = action.payload.key;
      const newCollapsedCards = cards.has(key)
        ? cards.delete(key).delete('all')
        : cards.add(key);
      return state.set('collapsedCards', newCollapsedCards);
    }
    case timesheetsActionTypes.FETCH_LOCATION_TOTALS_REQUEST:
      return state.set('loadingTotals', action.meta.loadingTotals);
    case timesheetsActionTypes.FETCH_LOCATION_TOTALS_SUCCESS:
      return state.set('cacheKey', action.payload.cacheKey);
    case timesheetsActionTypes.FETCH_LOCATION_TOTALS_FAILURE:
      return state.set('loadingTotals', false);
    case timesheetsActionTypes.UPDATE_COLUMNS:
      return state.set('columns', action.payload.columns);
    case timesheetsActionTypes.UPDATE_TIP_ENGINE_ALERT:
      return state.set('tipEngineAlert', {
        show: true,
        error: {
          title: action.payload[0],
          body: action.payload[1],
        },
      });

    case timesheetsActionTypes.UPDATE_ALL_COLUMNS: {
      // Add Main column
      const newColumns = fromJS([state.get('columns').get(0)]);

      const result = action.payload.columns.reduce((list, column) => {
        const oldColumn =
          state.get('columns').find(c => c.get('key') === column.get('key')) ||
          fromJS({ key: column.get('id') });

        return list.push(oldColumn.set('visible', column.get('value')));
      }, newColumns);

      return state.set('columns', result);
    }

    case timesheetsActionTypes.HIDE_MAKE_BREAKS_OPTIONAL_TOOLTIP: {
      return state.set('showMakeBreaksOptional', false);
    }
    case timesheetsActionTypes.HIDE_TIP_POOLING_TOOLTIP: {
      return state.set('showTimesheetsTipPoolingTooltip', false);
    }
    case timesheetsActionTypes.UPDATE_TOTALS: {
      const newRows = new Map(action.payload.rows);

      if (window.Homebase.env === 'production') {
        trackCustomTimingEvent('timesheets_load_complete');
      }

      return state
        .set('loadingTotals', false)
        .set('loadingSummaries', false)
        .set('timecardsCount', action.payload.timecards_count)
        .set('cacheKey', action.payload.cacheKey)
        .updateIn(['rows'], rows =>
          newRows.reduce(
            (map, row) =>
              map.set(row.key, (rows.get(row.key) || Map()).merge(row)),
            rows
          )
        );
    }
    case timesheetsActionTypes.FETCH_JOB_TOTALS_SUCCESS: {
      const updatedRows = action.payload.rows.reduce((acc, [id, rowData]) => {
        acc[id] = rowData;
        return acc;
      }, {});

      return state.updateIn(['rows'], rows =>
        rows.map(row => {
          const updatedRow = updatedRows[row.get('id')];
          if (!updatedRow) return row;
          return row.merge(
            Object.keys(updatedRow)
              .filter(key => updatedRow[key] !== undefined)
              .reduce((acc, key) => {
                acc[key] = updatedRow[key];
                return acc;
              }, {})
          );
        })
      );
    }
    case timesheetsActionTypes.UPDATE_PAYROLL_PERIODS:
      return state.mergeDeepIn(['payrollPeriods'], action.payload);
    case timesheetsActionTypes.FETCH_INIT_DATA_REQUEST:
      return state.set('loadingInitData', true);
    case timesheetsActionTypes.SET_SORT_KEY: {
      return state.set('sortColumn', new Map(action.payload.sortColumn));
    }
    case timesheetsActionTypes.FETCH_INIT_DATA_SUCCESS:
      return state.set('loadingInitData', false).merge(action.payload);
    case timesheetsActionTypes.FETCH_TABLE_ROWS_REQUEST:
      return state.set('loadingRows', true);
    case timesheetsActionTypes.FETCH_TABLE_ROWS_FAILURE:
      return state.set('loadingRows', false);
    case timesheetsActionTypes.FETCH_TABLE_ROWS_SUCCESS: {
      const newRows = action.payload.rows;

      if (window.Homebase.env === 'production') {
        trackCustomTimingEvent('timesheets_first_response');
      }

      return state
        .set('loadingRows', false)
        .set('locationEmployees', fromJS(action.payload.employees))
        .updateIn(['rows'], rows =>
          newRows.reduce(
            (map, row) =>
              map.set(row.id, (rows.get(row.id) || Map()).merge(row)),
            rows
          )
        );
    }
    case timesheetsActionTypes.FETCH_LOCATION_DATA_REQUEST:
      return state.set('loadingLocationData', true);
    case timesheetsActionTypes.FETCH_LOCATION_DATA_FAILURE:
      return state.set('loadingLocationData', false);
    case timesheetsActionTypes.ADD_LOCATION_ROLE_SUCCESS:
      return state.setIn(
        ['locationRoles', state.get('locationRoles').size],
        fromJS(action.payload)
      );
    case timesheetsActionTypes.FETCH_LOCATION_DATA_SUCCESS: {
      let newState = state.merge({
        loadingLocationData: false,
        locationRoles: action.payload.roles,
        mandatedBreaks: action.payload.mandated_breaks,
        hideWithoutHours: action.payload.hide_without_hours,
        payrollProvider: action.payload.payrollProvider,
        defaultActivityTypes: action.payload.default_activity_types,
        locationDataFetched: new Date().getTime(),
      });

      if (action.payload.employees) {
        newState = newState.merge({
          locationEmployees: action.payload.employees,
        });
      }

      return newState;
    }
    case timesheetsActionTypes.UPDATE_SEARCH_PERIOD: {
      const newState = state.merge({
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      });
      if (action.payload.payrollPeriod) {
        return newState.merge({
          payrollPeriod: action.payload.payrollPeriod,
        });
      }
      return newState;
    }

    case timesheetsActionTypes.TURN_OFF_MANDATED_BREAK_SUCCESS: {
      const index = state
        .get('mandatedBreaks')
        .findIndex(mb => mb.get('id') === action.meta.mbId);
      return state.mergeIn(['mandatedBreaks', index, 'active'], false);
    }
    case timesheetsActionTypes.DISMISS_MANDATED_BREAK_SUCCESS: {
      const rows = state.get('rows').map(row => {
        const issues = row.get('issues_collection');

        if (issues) {
          return row.set(
            'issues_collection',
            issues.filter(issue => issue.get('meta') !== action.meta.mbId)
          );
        }

        return row;
      });

      const index = state
        .get('mandatedBreaks')
        .findIndex(mb => mb.get('id') === action.meta.mbId);

      return state
        .set('rows', rows)
        .setIn(['mandatedBreaks', index, 'track_errors'], false);
    }
    case timesheetsActionTypes.UPDATE_ROW_SUCCESS: {
      if (isEmpty(action.payload)) {
        return state.deleteIn(['rows', action.meta.shiftId]);
      }

      if ((action.meta || {}).reset) {
        return state.setIn(['rows', action.payload.id], fromJS(action.payload));
      }

      const date = window.moment(action.payload.date).format('YYYY-MM-DD');

      // Only a salaried row with no timecard can have a key like 1-2022-11-07
      const salaryRowKey = `${action.payload.job_id}-${date}`;

      const newState = state.deleteIn(['rows', salaryRowKey]);

      return newState.mergeDeepIn(['rows', action.payload.id], action.payload);
    }
    case timesheetsActionTypes.DELETE_ROW: {
      // When a manger starts to create a new timecard, an unsaved row is created.
      // This code clears that row.
      return state.deleteIn(['rows', action.payload.shiftId]);
    }
    case timesheetsActionTypes.DELETE_ROWS: {
      return state.set('rows', Map());
    }
    case timesheetsActionTypes.UPDATE_HIDE_WO_HOURS_SUCCESS: {
      return state.set('hideWithoutHours', action.meta.hideWOH);
    }
    case timesheetsActionTypes.REMOVE_ISSUE: {
      const issues = state.getIn([
        'rows',
        action.payload.row.get('id'),
        'issues_collection',
      ]);
      return state.setIn(
        ['rows', action.payload.row.get('id'), 'issues_collection'],
        issues.slice(1, issues.size)
      );
    }
    case timesheetsActionTypes.APPROVE_TIMECARD_SUCCESS: {
      const { shiftIds, approval } = action.meta;

      shiftIds.forEach(
        id =>
          (state = state.mergeIn(['rows', id], {
            approved: approval,
            lockedInfo: null,
          }))
      );

      return state;
    }
    case timesheetsActionTypes.CHANGE_EMPLOYEE_SORT_BY:
      return state.set('employeeSortBy', action.payload);
    case timesheetsActionTypes.CHANGE_LOCATION_SETTINGS_SUCCESS:
      return state.set('importCreditTips', action.payload.import_credit_tips);
    case timecardActionTypes.SUBMIT_CHANGES_SUCCESS: {
      const metaShiftId = action.meta.shiftId;
      const payloadShiftId = action.payload.shift_id;
      const payloadPath = ['rows', payloadShiftId];

      if (metaShiftId === payloadShiftId) {
        // If equals we just need to update the row
        return state.mergeIn(payloadPath, fromJS(action.payload));
      }
      // If different it means a Shift was inserted.
      // Meta is our "unsaved row" and payload is the shift persisted by submit changes.
      // - Initial shift row data comes from unsaved row.
      // - Payload activities, issues and id are merged to shift row.
      // - Shift row is added by merging it in payloadPath.
      // - Delete unsaved row
      const unsavedRow = state.getIn(['rows', metaShiftId]);
      const shiftRow = unsavedRow.merge({
        ...action.payload,
        id: payloadShiftId,
      });
      return state.mergeIn(payloadPath, shiftRow).deleteIn(['rows', null]);
    }
    case payrollSummaryActionTypes.UPDATE_PAYROLL_ID_SUCCESS: {
      const index = state
        .get('locationEmployees')
        .findIndex(employee => employee.get('id') === action.meta.job_id);

      state = state.setIn(
        ['locationEmployees', index, 'payroll_id'],
        action.meta.payroll_id
      );

      return state;
    }
    case employeeViewActionTypes.UPDATE_USER_INFO: {
      return state.set('locationRolesUpdated', new Date().getTime());
    }
    case dailyReviewActionTypes.FETCH_DAILY_REVIEW_ROWS_FULFILLED: {
      const employees = action.payload.employees.map(employee => ({
        ...employee,
        id: employee.job_id,
      }));
      return state.set('locationEmployees', fromJS(employees));
    }
    case dailyReviewActionTypes.FETCH_TIMESHEETS_INITIAL_DATA_FULFILLED: {
      return state.merge({
        loadingLocationData: false,
        locationRoles: action.payload.roles,
        locationIssues: action.payload.issues,
        mandatedBreaks: action.payload.mandated_breaks,
        hideWithoutHours: action.payload.hide_without_hours,
        payrollProvider: action.payload.payrollProvider,
        locationDataFetched: new Date().getTime(),
        breakPenaltiesEnabled: action.payload.breakPenaltiesEnabled,
        locationEligibleForTipPooling:
          action.payload.tip_pooling_location_eligible,
        showIntroductoryManageTipsButton:
          action.payload.show_introductory_manage_tips_button,
        subscribedToTipPooling: action.payload.subscribed_to_tip_pooling,
        tipPoolingPolicy: action.payload.tip_pooling_policy,
      });
    }
    case dailyReviewActionTypes.FETCH_TIMESHEETS_INITIAL_DATA_PENDING: {
      return state.set('loadingLocationData', true);
    }
    case timesheetsActionTypes.UPDATE_REFRESH_TIP_OUTS: {
      const isLocationEligibleForTipPooling = state.get(
        'locationEligibleForTipPooling'
      );
      if (isLocationEligibleForTipPooling) {
        return state.set('refreshTipOuts', true);
      }
      return state;
    }
    case timesheetsActionTypes.SHOW_TIP_CHANGES_ALERT: {
      return state.set('showTipChangesAlert', true);
    }
    case timesheetsActionTypes.DISMISS_TIP_CHANGES_ALERT: {
      return state.set('showTipChangesAlert', false);
    }
    case timesheetsActionTypes.SHOW_TIMESHEETS_DOWNLOADED_TOAST: {
      return state.set('showTimesheetsDownloadedToast', true);
    }
    case timesheetsActionTypes.CLOSE_TIMESHEETS_DOWNLOADED_TOAST: {
      return state.set('showTimesheetsDownloadedToast', false);
    }
    default:
      return state;
  }
};

const payrollSummaries = (state = INITIAL_PAYROLL_SUMMARIES_STATE, action) => {
  switch (action.type) {
    case payrollSummaryActionTypes.FETCH_PAYROLL_PERIODS_REQUEST:
      return state.set('loadingPeriods', true);
    case payrollSummaryActionTypes.FETCH_PAYROLL_PERIODS_SUCCESS: {
      const month = action.meta.month.format('MM-YYYY');

      action.payload.periods.forEach(period => {
        period[1].month = month;
      });

      return state
        .set('loadingPeriods', false)
        .mergeIn(['payrollPeriods'], action.payload.periods);
    }
    case payrollSummaryActionTypes.UPDATE_LOADING_TOTALS_FOR_PAYROLL_PERIODS:
      return state.set(
        'loadingTotalsForPayrollPeriods',
        action.payload.loading
      );
    case payrollSummaryActionTypes.FETCH_PAYROLL_PERIODS_FAILURE:
      return state.set('loadingPeriods', false);
    case payrollSummaryActionTypes.FETCH_INIT_DATA_REQUEST:
      return state.set('loadingPageData', true);
    case payrollSummaryActionTypes.FETCH_INIT_DATA_FAILURE:
      return state.set('loadingPageData', false);
    case payrollSummaryActionTypes.FETCH_INIT_DATA_SUCCESS:
      return state
        .set('pageData', fromJS(action.payload))
        .set('loadingPageData', false);
    case payrollSummaryActionTypes.CLEAR_TOTALS:
      return state.set('payrollPeriods', Map());
    case payrollSummaryActionTypes.UPDATE_TOTALS:
      return state.mergeDeepIn(
        ['payrollPeriods'],
        action.payload.payrollPeriods
      );
    case payrollSummaryActionTypes.UPDATE_COLUMNS: {
      const index = state
        .getIn(['pageData', 'columns'])
        .findIndex(column => column.get('key') === action.payload.key);
      return state.setIn(
        ['pageData', 'columns', index, 'visible'],
        action.payload.value
      );
    }
    case payrollSummaryActionTypes.REORDER_COLUMNS: {
      const oldItem = state
        .getIn(['pageData', 'columns'])
        .get(action.payload.oldIndex);
      const newState = state
        .getIn(['pageData', 'columns'])
        .delete(action.payload.oldIndex)
        .insert(action.payload.newIndex, oldItem);
      return state.setIn(['pageData', 'columns'], newState);
    }
    case payrollSummaryActionTypes.UPDATE_PAYROLL_CODES_SUCCESS:
      return state.mergeIn(
        ['pageData', 'payrollCodes'],
        action.meta.payrollCodes
      );
    default:
      return state;
  }
};

export default combineReducers({
  general,
  page,
  payrollSummaries,
});
