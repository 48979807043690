import { checkOnboardingComponent } from 'api';

import * as hiringConstants from 'features/hiring/constants';
import * as constants from 'features/modals/constants';
import { payrollRunActions } from 'features/payroll/PayrollRunView/payrollRunSlice';
import { trackAutoScheduleInfoModalUxEvents } from 'features/scheduleBuilder/tracking';

import {
  trackMultilocationTrialEvent,
  trackUserWebTimeclockEvent,
  trackUxEvent,
} from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
  TRACK_ACTIONS,
  TRACK_CATEGORY,
  TRACK_EVENTS,
  TRACK_SOURCES,
} from 'util/tracking_constants';

export const HIDE_MODAL = 'HIDE_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SET_MODAL_SOURCE = 'SET_MODAL_SOURCE';
export const SET_MODAL_PROMO_CODE = 'SET_MODAL_PROMO_CODE';

export const actionTypes = {
  HIDE_MODAL,
  SHOW_MODAL,
  SET_MODAL_SOURCE,
  SET_MODAL_PROMO_CODE,
  UPDATE_CURRENT_MODAL_PROP: 'UPDATE_CURRENT_MODAL_PROP',
};

export const showModal = (modalType, modalProps = {}) => ({
  type: SHOW_MODAL,
  payload: { modalType, modalProps },
});

export const setModalSource = (modalSource = null) => ({
  type: SET_MODAL_SOURCE,
  payload: { modalSource },
});

export const setModalPromoCode = (modalPromoCode = null) => ({
  type: SET_MODAL_PROMO_CODE,
  payload: { modalPromoCode },
});

export const hideModal = () => ({ type: HIDE_MODAL });

export const showExitWithoutSavingModal = onClickExit =>
  showModal(constants.EXIT_WITHOUT_SAVING_MODAL_TYPE, { onClickExit });

export const showPayBaseModal = type =>
  showModal(constants.PAY_BASE_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    type,
  });

export const showErrorModal = errors =>
  showModal(constants.ERROR_MODAL_TYPE, { deprecatedModal: true, errors });

export const showReadonlyModal = ({
  text,
  hideCloseIcon = true,
  persist = true,
  bold = true,
  ...props
}) =>
  showModal(constants.READONLY_MODAL_TYPE, {
    deprecatedModal: true,
    text,
    hideCloseIcon,
    persist,
    bold,
    ...props,
  });

export const showConfirmModal = ({
  onConfirm,
  title,
  message,
  submitText,
  cancelText,
  onCancel,
  showCustomIcon,
  shouldHandleOutsideClick,
  auto,
  width,
  altType,
  forceOpen,
  padding,
  onShow,
  onClose,
  fullWidthFooter,
  onDismiss,
  newDesign,
  destructive,
}) =>
  showModal(
    !altType ? constants.CONFIRM_MODAL_TYPE : constants.CONFIRM_MODAL_ALT_TYPE,
    {
      deprecatedModal: true,
      onConfirm,
      title,
      message,
      submitText,
      cancelText,
      onCancel,
      showCustomIcon,
      shouldHandleOutsideClick,
      auto,
      width,
      forceOpen,
      padding,
      onShow,
      onClose,
      fullWidthFooter,
      onDismiss,
      newDesign,
      destructive,
    }
  );

export const showConfirmWithSecondaryModal = ({
  onPrimaryAction,
  title,
  message,
  primaryAction,
  secondaryAction,
  onSecondaryAction,
  shouldHandleOutsideClick,
  onShow,
  onClose,
  onDismiss,
}) =>
  showModal(constants.CONFIRM_WITH_SECONDARY_MODAL_TYPE, {
    onPrimaryAction,
    title,
    message,
    primaryAction,
    secondaryAction,
    onSecondaryAction,
    shouldHandleOutsideClick,
    onShow,
    onClose,
    onDismiss,
  });

export const showDismissOnlyModal = ({
  title,
  message,
  cta,
  shouldHandleOutsideClick,
  onShow,
  onClose,
  onDismiss,
}) =>
  showModal(constants.DISMISS_ONLY_MODAL_TYPE, {
    title,
    message,
    cta,
    shouldHandleOutsideClick,
    onShow,
    onClose,
    onDismiss,
  });

export const showNewLocationModal = () =>
  showModal(constants.NEW_LOCATION_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
  });

export const showTeamPermissionsDialog = () =>
  showModal(constants.TEAM_PERMISSIONS_DIALOG, {
    noPadding: true,
    onClose: closeModal => {
      trackUxEvent({
        productArea: PRODUCT_AREAS.TEAM,
        eventCategory: EVENT_CATEGORIES.MANAGER_ACCESS_PAYWALL,
        eventAction: EVENT_ACTIONS.DISMISS_CLICKED,
        actionType: TRACK_ACTION_TYPES.CLICK,
      });
      closeModal();
    },
  });

export const checkComponentWithSignerTitleModal = ({
  checkComponentType,
  jurisdiction,
  signerTitle,
  signerLegalName,
  signerEmailAddress,
  onCloseCallback = () => {},
}) =>
  showModal(constants.PAYROLL_CHECK_COMPONENT_WITH_SIGNER_TITLE_MODAL, {
    deprecatedModal: true,
    componentUrl: checkOnboardingComponent(),
    checkComponentType,
    jurisdiction,
    signerTitle,
    signerLegalName,
    signerEmailAddress,
    onCloseCallback,
  });

export const showMessengerEveryoneWorkingTodayEmptyModal = () =>
  showModal(constants.MESSENGER_EVERYONE_WORKING_TODAY_EMPTY_MODAL, {
    deprecatedModal: true,
  });

export const showNewLocationDepartmentModal = () =>
  showModal(constants.NEW_LOCATION_DEPARTMENT_MODAL, {
    deprecatedModal: true,
    auto: true,
    onClose: closeModal => {
      trackMultilocationTrialEvent(
        TRACK_EVENTS.ADD_LOCATION,
        TRACK_ACTIONS.DISMISS,
        {
          source: TRACK_SOURCES.DEPARTMENTS,
        }
      );
      closeModal();
    },
  });

export const showSecondLocationTrialUpgradeModal = () =>
  showModal(constants.SECOND_LOCATION_TRIAL_UPGRADE_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
  });

export const showEndOfTrialModal1 = (
  trialManagers,
  secondLocationName,
  lastEndedTrialPeriodTierName
) =>
  showModal(constants.END_OF_TRIAL_MODAL_1, {
    deprecatedModal: true,
    trialManagers,
    secondLocationName,
    lastEndedTrialPeriodTierName,
    forceOpen: true,
    auto: true,
    noPadding: true,
  });

export const showEndOfTrialModal2 = trialManagers =>
  showModal(constants.END_OF_TRIAL_MODAL_2, {
    deprecatedModal: true,
    trialManagers,
    forceOpen: true,
    auto: true,
    noPadding: true,
  });

export const showManagerCapsBeforeEndOfTrialModal = trialManagers =>
  showModal(constants.BEFORE_END_OF_TRIAL_MODAL, {
    deprecatedModal: true,
    trialManagers,
    forceOpen: true,
    auto: true,
    noPadding: true,
  });

export const showEndOfTrialListDialog = ({
  trialManagers,
  secondLocationName,
}) =>
  showModal(constants.END_OF_TRIAL_LIST_DIALOG, {
    trialManagers,
    secondLocationName,
    forceOpen: true,
    auto: true,
    noPadding: true,
  });

export const showEndOfTrialManagerModal = () =>
  showModal(constants.END_OF_TRIAL_MANAGER_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
  });

export const showEndOfTrialLocationModal = secondLocationName =>
  showModal(constants.END_OF_TRIAL_LOCATION_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    secondLocationName,
  });

export const showDeclineReasonModal = props =>
  showModal(constants.HIRING_DECLINE_REASON_MODAL, {
    deprecatedModal: true,
    auto: true,
    overflowVisible: true,
    ...props,
  });

export const showADPRunInterruptModal = onClose =>
  showModal(constants.ADP_INTERRUPT_MODAL, { deprecatedModal: true, onClose });

export const showPageTipsModal = props =>
  showModal(constants.PAGE_TIPS_MODAL_TYPE, {
    deprecatedModal: true,
    auto: true,
    ...props,
  });

export const showMobileInterstitial = redirectUrl =>
  showModal(constants.MOBILE_INTERSTITIAL_MODAL_TYPE, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    forceOpen: true,
    fullScreenAtMobile: true,
    onClose: () => {
      hideModal();
      window.location = redirectUrl;
    },
  });

export const showOnboardingQuestionsModal = () =>
  showModal(constants.ONBOARDING_QUESTIONS_MODAL_TYPE, {
    deprecatedModal: true,
    forceOpen: true,
    auto: true,
    noPadding: true,
    overflowVisible: true,
  });

export const showSegmentedOnboardingModal = (
  businessSegment,
  steps,
  redirectUrl,
  showMobileInterstitialModal
) =>
  showModal(constants.ROOT_ONBOARDING_MODAL, {
    deprecatedModal: true,
    steps,
    businessSegment,
    redirectUrl,
    showMobileInterstitialModal,
    forceOpen: true,
    overflowVisible: true,
    fullScreenAtMobile: true,
    noPadding: true,
    auto: true,
  });

export const reopenSegmentedOnboardingModal = props =>
  showModal(constants.ROOT_ONBOARDING_MODAL, {
    ...props,
    deprecatedModal: true,
    forceOpen: true,
    overflowVisible: true,
    noPadding: true,
    auto: true,
  });

export const showToastPOSConnectModal = (callback = null) =>
  showModal(constants.TOAST_POS_CONNECT_MODAL, {
    deprecatedModal: true,
    overflowVisible: true,
    noPadding: true,
    auto: true,
    onClose: closeModal => {
      closeModal();

      if (callback) {
        callback();
      }
    },
  });

export const showGustoExportModal = props =>
  showModal(constants.GUSTO_EXPORT_MODAL, {
    auto: true,
    noPadding: true,
    startDate: props.startDate,
    endDate: props.endDate,
    onClose: closeModal => {
      closeModal();
    },
  });

export const showAdpRunExportModal = props =>
  showModal(constants.ADP_RUN_EXPORT_MODAL, {
    auto: true,
    noPadding: true,
    startDate: props.startDate,
    endDate: props.endDate,
    onClose: closeModal => {
      closeModal();
    },
  });

export const showLocationChooserModal = locations =>
  showModal(constants.LOCATION_CHOOSER_MODAL, {
    auto: true,
    locations,
    onClose: closeModal => {
      closeModal();
    },
  });

export const showSquareSuccessModal = () =>
  showModal(constants.SQUARE_SUCCESS_MODAL, {
    auto: true,
    noPadding: true,
    onClose: closeModal => {
      closeModal();
    },
  });

export const showGustoSuccessModal = () =>
  showModal(constants.GUSTO_SUCCESS_MODAL, {
    auto: true,
    noPadding: true,
    onClose: closeModal => {
      closeModal();
    },
  });

export const showMissingPayrollIdsModal = props =>
  showModal(constants.MISSING_PAYROLL_IDS_MODAL, {
    auto: true,
    noPadding: true,
    currentProvider: props.currentProvider.name,
    users: props.users,
    showMoreCount: props.showMoreCount,
    partnerView: props.partnerView,
    onClose: closeModal => {
      closeModal();
    },
  });

export const showUpserveConnectModal = () =>
  showModal(constants.UPSERVE_CONNECT_MODAL, {
    auto: true,
    noPadding: true,
    onClose: closeModal => {
      closeModal();
    },
  });

export const showRevelConnectModal = url =>
  showModal(constants.REVEL_CONNECT_MODAL, {
    auto: true,
    noPadding: true,
    urlSuffix: url,
    onClose: closeModal => {
      closeModal();
    },
  });

export const openHiringBoostModal = (jobRequestId, modalProps = {}) =>
  showModal(constants.HIRING_BOOST_UPSELL_MODAL_TYPE, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
    jobRequestId,
    ...modalProps,
  });

export const openGetMobileAppModal = props =>
  showModal(constants.GET_MOBILE_APP_MODAL, {
    deprecatedModal: true,
    forceOpen: true,
    auto: true,
    noPadding: true,
    overflowVisible: true,
    ...props,
  });

export const openJobRequestCreatedModal = props =>
  showModal(constants.ICON_MODAL_TYPE, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    iconSize: 64,
    width: 700,
    ...props,
  });

export const showDowngradeManagersModal = managers =>
  showModal(constants.DOWNGRADE_MANAGERS_MODAL, {
    deprecatedModal: true,
    managers,
    auto: true,
    noPadding: true,
  });

export const updateCurrentModalProp = (prop, value) => ({
  type: actionTypes.UPDATE_CURRENT_MODAL_PROP,
  payload: {
    [prop]: value,
  },
});

export const showRetrialOnboardingModal = (steps, startAtStep = 0) =>
  showModal(constants.ROOT_RETRIAL_ONBOARDING_MODAL, {
    deprecatedModal: true,
    steps,
    startAtStep,
    forceOpen: true,
    overflowVisible: true,
    noPadding: true,
    auto: true,
  });

export const showRootMfaEnableModal = (
  options = { beforeCloseCallback: undefined, beforeCloseLabel: undefined }
) =>
  showModal(constants.ROOT_MFA_ENABLE_MODAL, {
    overflowVisible: true,
    noPadding: true,
    auto: true,
    beforeCloseCallback: options.beforeCloseCallback,
    beforeCloseLabel: options.beforeCloseLabel,
  });

export const showRootMfaEditModal = (
  currentPhone,
  options = { beforeCloseCallback: undefined }
) =>
  showModal(constants.ROOT_MFA_EDIT_MODAL, {
    overflowVisible: true,
    noPadding: true,
    auto: true,
    currentPhone,
    beforeCloseCallback: options.beforeCloseCallback,
  });

export const showRootMfaModal = (currentPhone, workflow, beforeCloseCallback) =>
  showModal(constants.ROOT_MFA_MODAL, {
    overflowVisible: true,
    noPadding: true,
    auto: true,
    currentPhone,
    workflow,
    beforeCloseCallback,
  });

export const showAutoSchedulingGuidelinesModal = () =>
  showModal(constants.AUTO_SCHEDULING_GUIDELINES_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    onClose: closeModal => {
      trackAutoScheduleInfoModalUxEvents({
        eventAction: EVENT_ACTIONS.AUTO_SCHEDULE_INFO_MODAL_CLOSED,
      });
      closeModal();
    },
  });

export const openCloverTimeClockInterstitial = () =>
  showModal(constants.CLOVER_TIMECLOCK_INTERSTITIAL, {
    deprecatedModal: false,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
  });

export const openSharedTimeclockModal = () =>
  showModal(constants.SHARED_TIMECLOCK_MODAL, {
    deprecatedModal: false,
    auto: true,
    noPadding: true,
    onClose: closeModal => {
      closeModal();
    },
  });
export const openUserWebTimeclockModal = trackingProperties =>
  showModal(constants.USER_WEB_TIMECLOCK_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    onClose: closeModal => {
      trackUserWebTimeclockEvent(
        TRACK_CATEGORY.NONE,
        TRACK_ACTIONS.CLOSED,
        trackingProperties
      );
      closeModal();
    },
    trackingProperties,
  });

export const openChooseTimeClockModal = (trackingProperties = {}) =>
  showModal(constants.CHOOSE_TIME_CLOCK_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    trackingProperties,
  });

export const openPremiumBoostsNotAvailableModal = () =>
  showModal(constants.PREMIUM_BOOSTS_NOT_AVAILABLE_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
  });

export const openHiringDashboardVideoModal = () =>
  showModal(constants.DASHBOARD_VIDEO_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
  });

export const openHrProVideoModal = () =>
  showModal(constants.HR_PRO_VIDEO_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
  });

export const showPartnerEndOfIntegrationModal = partnerName =>
  showModal(constants.PARTNER_END_OF_INTEGRATION_MODAL, {
    deprecatedModal: true,
    partnerName,
  });

export const openCancelInterviewConfirmationModal = (
  firstName,
  interviewTime,
  applicationId,
  interviewId
) =>
  showModal(constants.CANCEL_INTERVIEW_CONFIRMATION_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    firstName,
    interviewTime,
    applicationId,
    interviewId,
  });

export const showDownloadMessageLogsModal = () =>
  showModal(constants.DOWNLOAD_MESSAGE_LOGS_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    overflowVisible: true,
  });

export const showArchiveApplicantsModal = (
  unhiredApplicationsGreaterThan21Days,
  id,
  isLocationCard
) =>
  showModal(hiringConstants.ARCHIVE_APPLICANTS_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
    forceOpen: true,
    unhiredApplicationsGreaterThan21Days,
    id,
    isLocationCard,
  });

export const showPayrollRunTimecardModal =
  (employee, itemId, roleId, jobId, paymentType, isEmbedded = false) =>
  dispatch =>
    dispatch(
      showModal(constants.PAYROLL_RUN_TIMECARD_MODAL, {
        noPadding: true,
        fullScreen: isEmbedded,
        hideCloseIcon: isEmbedded,
        roleId,
        employee,
        itemId,
        jobId,
        paymentType,
        onClose: closeModal => {
          dispatch(payrollRunActions.resetTimecards());
          closeModal();
        },
      })
    );

// Multi-wage version of the BonusModal
export const showPayrollOtherEarningsModal =
  ({
    bonus,
    severance,
    paymentId,
    allowance,
    correction,
    commission,
    tipShortage,
    paymentType,
    employeeName,
    isContractor,
    otherImputed,
    clergyHousing,
    groupTermLife,
    ffcraSickOther,
    ffcraFamilyLeave,
    reportedAllowance,
    additionalEarnings,
    ffcraSickIndividual,
    reimbursementAmount,
    reportedClergyHousing,
    isCheckEmployeeClergyMember,
    fullScreen,
    hideCloseIcon,
    noPadding,
  }) =>
  dispatch =>
    dispatch(
      showModal(constants.PAYROLL_RUN_OTHER_EARNINGS_MODAL, {
        bonus,
        severance,
        allowance,
        paymentId,
        correction,
        commission,
        auto: true,
        paymentType,
        tipShortage,
        employeeName,
        otherImputed,
        isContractor,
        clergyHousing,
        groupTermLife,
        ffcraSickOther,
        ffcraFamilyLeave,
        reportedAllowance,
        additionalEarnings,
        reimbursementAmount,
        ffcraSickIndividual,
        reportedClergyHousing,
        isCheckEmployeeClergyMember,
        fullScreen,
        hideCloseIcon,
        noPadding,
      })
    );

export const showPayrollPaystubNotesModal =
  ({ employeeName, paymentId, paymentType, paystubNotes, showPaystubNotes }) =>
  dispatch =>
    dispatch(
      showModal(constants.PAYROLL_RUN_PAYSTUB_NOTES_MODAL, {
        employeeName,
        paymentId,
        paymentType,
        paystubNotes,
        showPaystubNotes,
      })
    );

export const showPayrollRunSubmissionModal =
  (modalType, props = {}) =>
  dispatch =>
    dispatch(
      showModal(constants.PAYROLL_RUN_SUBMISSION_MODAL, {
        deprecatedModal: true,
        modalType,
        props,
        transparent: true,
        auto: true,
        noPadding: true,
        forceOpen: true,
        shouldCloseOnOverlayClick: false,
      })
    );

export const showPayrollContactModal = props => dispatch =>
  dispatch(
    showModal(constants.PAYROLL_CONTACT_MODAL, {
      deprecatedModal: true,
      auto: true,
      noPadding: true,
      ...props,
    })
  );

export const showMissedPayrollRunModal = props => dispatch =>
  dispatch(
    showModal(constants.PAYROLL_MISSED_RUN_MODAL, {
      auto: true,
      noPadding: true,
      forceOpen: true,
      hideCloseIcon: false,
      ...props,
    })
  );

export const showPayrollGenericContactModal = props => dispatch =>
  dispatch(
    showModal(constants.PAYROLL_CONTACT_GENERIC_MODAL, {
      deprecatedModal: true,
      auto: true,
      noPadding: true,
      ...props,
    })
  );

export const showRequestTaxWithholdingModal = props => dispatch =>
  dispatch(
    showModal(constants.REQUEST_TAX_WITHHOLDING_MODAL, {
      deprecatedModal: true,
      auto: true,
      noPadding: true,
      ...props,
    })
  );

export const showPayrollSelfSetupTimelineModal = props => dispatch =>
  dispatch(
    showModal(constants.PAYROLL_SELF_SETUP_TIMELINE_MODAL, {
      auto: true,
      noPadding: true,
      ...props,
    })
  );

export const showPayrollConnectBankAccountModal = props => dispatch =>
  dispatch(
    showModal(constants.PAYROLL_CONNECT_BANK_ACCOUNT_MODAL, {
      auto: true,
      noPadding: true,
      hideCloseIcon: true,
      ...props,
    })
  );

export const showPayrollSelfSetupFeinRejectedModal = props => dispatch =>
  dispatch(
    showModal(constants.PAYROLL_SELF_SETUP_FEIN_REJECTED_MODAL, {
      deprecatedModal: true,
      auto: true,
      noPadding: true,
      hideCloseIcon: true,
      ...props,
    })
  );

export const showRunLatePayrollModal = (payDate, latePayDate) => dispatch =>
  dispatch(
    showModal(constants.PAYROLL_RUN_LATE_PAYROLL_RUN_MODAL, {
      deprecatedModal: true,
      auto: true,
      noPadding: true,
      payDate,
      latePayDate,
    })
  );

export const showPayrollLatePaydayModal = () => dispatch =>
  dispatch(
    showModal(constants.PAYROLL_PAYDAY_MODAL, {
      deprecatedModal: true,
      auto: true,
      noPadding: true,
    })
  );

export const showPayrollInProductLeadModal =
  ({ onClose } = {}) =>
  dispatch =>
    dispatch(
      showModal(constants.PAYROLL_IN_PRODUCT_LEAD_MODAL, {
        deprecatedModal: true,
        auto: true,
        noPadding: true,
        onClose: closeModal => {
          trackUxEvent({
            productArea: PRODUCT_AREAS.TIMESHEETS,
            eventCategory: EVENT_CATEGORIES.HOMEBASE_PAYROLL_MODAL,
            eventAction: EVENT_ACTIONS.CLOSE_CLICKED,
          });
          closeModal();

          if (onClose) {
            onClose();
          }
        },
      })
    );

export const showChangeOwnershipModal = () =>
  showModal(constants.CHANGE_OWNERSHIP_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
  });

export const showEnforcementDialog = ({
  productArea,
  canCloseDialog = false,
}) =>
  showModal(constants.ENFORCEMENT_DIALOG, {
    productArea,
    noPadding: true,
    forceOpen: !canCloseDialog,
  });

export const showMultilocationEnforcementDialog = ({
  canCloseMultilocationEnforcementDialog = false,
  currentStep,
}) =>
  showModal(constants.MULTILOCATION_ENFORCEMENT_DIALOG, {
    noPadding: true,
    forceOpen: !canCloseMultilocationEnforcementDialog,
    currentStep,
    onClose: closeModal => {
      trackUxEvent({
        productArea: PRODUCT_AREAS.BILLING,
        eventCategory: EVENT_CATEGORIES.MULTILOCATION_ENFORCEMENT,
        eventAction: EVENT_ACTIONS.DISMISS_CLICKED,
        actionType: TRACK_ACTION_TYPES.CLICK,
      });
      closeModal();
    },
  });

export const showMobileTimeClockSettingsDialog = () =>
  showModal(constants.MOBILE_TIME_CLOCK_CAP_SETTINGS_DIALOG, {
    auto: true,
    noPadding: true,
    onClose: closeModal => {
      trackUxEvent({
        productArea: PRODUCT_AREAS.SETTINGS,
        eventCategory: EVENT_CATEGORIES.GHOST_PACKAGING_PAYWALL_MTC,
        eventAction: EVENT_ACTIONS.DISMISS_CLICKED,
        actionType: TRACK_ACTION_TYPES.CLICK,
      });

      closeModal();
    },
  });

export const showMobileTimeClockTrialEndDialog = () =>
  showModal(constants.MOBILE_TIME_CLOCK_TRIAL_END_DIALOG, {
    auto: true,
    noPadding: true,
    forceOpen: true,
    hideCloseIcon: false,
    onClose: closeModal => {
      trackUxEvent({
        productArea: PRODUCT_AREAS.SIGN_IN,
        eventCategory: EVENT_CATEGORIES.MTC_TRIAL_PROMO_MODAL,
        eventAction: EVENT_ACTIONS.DISMISS_CLICKED,
        actionType: TRACK_ACTION_TYPES.CLICK,
      });

      closeModal();
    },
  });

export const showChangeOwnershipConfirmationModal = (
  userDetails,
  newOwnerId,
  location
) =>
  showModal(constants.CHANGE_OWNERSHIP_CONFIRMATION_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    userDetails,
    newOwnerId,
    location,
  });

export const showChangeOwnershipNonOwnerModal = () =>
  showModal(constants.CHANGE_OWNERSHIP_NON_OWNER_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
  });

export const showClickToCallDialog = ({
  viewPrefix = '',
  talkdeskId = null,
  utmContent = null,
  utmCampaign = null,
  hideScheduleCall = false,
  customTitleWithinBusinessHours = null,
  customTitleOutsideBusinessHours = null,
  outsideBusinessHoursCustomComponentKey = null,
} = {}) =>
  showModal(constants.CLICK_TO_CALL_DIALOG, {
    viewPrefix,
    talkdeskId,
    auto: true,
    utmContent,
    utmCampaign,
    noPadding: true,
    hideScheduleCall,
    customTitleWithinBusinessHours,
    customTitleOutsideBusinessHours,
    outsideBusinessHoursCustomComponentKey,
  });

export const showStrideHealthEmployerFlowDismissDialog = ({ openToast }) =>
  showModal(constants.STRIDE_HEALTH_EMPLOYER_FLOW_DISMISS_DIALOG, {
    openToast,
    auto: true,
    noPadding: true,
  });

export const showDeletePolicyConfirmationModal = (
  policyName,
  policyId,
  enrolledPolicyUsers,
  categoryName,
  handleClickDelete
) =>
  showModal(constants.DELETE_POLICY_CONFIRMATION_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    policyName,
    policyId,
    enrolledPolicyUsers,
    categoryName,
    handleClickDelete,
  });

export const showArchiveCustomDocumentConfirmationModal = customDocument =>
  showModal(constants.ARCHIVE_CUSTOM_DOCUMENT_CONFIRMATION_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: false,
    customDocument,
  });

export const showResendDocumentModal = (
  fullName,
  firstName,
  documentName,
  handleResendDocument,
  onSuccess
) =>
  showModal(constants.RESEND_DOCUMENTS_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    fullName,
    firstName,
    documentName,
    handleResendDocument,
    onSuccess,
  });

export const showPreferPaperChecksConfirmationModal = (
  packet,
  completeDirectDepositForm
) =>
  showModal(constants.PREFER_PAPER_CHECKS_CONFIRMATION_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    overlayWindow: 'prefer_paper_checks_button',
    transparentOverlayWithShadow: true,
    packet,
    completeDirectDepositForm,
  });

export const showAddExistingEmployeeInviteModal = (attrs, numberOfRequests) =>
  showModal(constants.ADD_EXISTING_USER_INVITE_MODAL, {
    deprecatedModal: true,
    userMergeRequestId: attrs.id,
    userName: attrs.user_name,
    companyName: attrs.company_name,
    userLevel: attrs.user_level,
    numberOfRequests,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
    shouldCloseOnOverlayClick: false,
  });

export const openVideoModal = url =>
  showModal(constants.VIDEO_MODAL, {
    deprecatedModal: true,
    url,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
  });

export const showNewLocationsSuccessModal = newLocations =>
  showModal(constants.NEW_LOCATIONS_SUCCESS_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    newLocations,
  });

export const showNewLocationsModal = biller =>
  showModal(constants.NEW_LOCATIONS_MODAL, {
    deprecatedModal: true,
    biller,
    auto: true,
    noPadding: true,
  });

export const showUnsupportedMultiLocationModal = () =>
  showModal(constants.UNSUPPORTED_MULTI_LOCATION_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
  });

export const showAdditionalPayrollLocationModal = () =>
  showModal(constants.ADDITIONAL_PAYROLL_LOCATION_MODAL, {
    width: 700,
  });

export const showFailedPaymentsAutomaticallyRefundedModal = (
  payrollRunId,
  onCloseCallback
) =>
  showModal(constants.FAILED_PAYMENTS_REFUNDED_MODAL, {
    disableBackdropClick: true,
    width: 611,
    payrollRunId,
    onClose: closeModal => {
      onCloseCallback();
      closeModal();
    },
  });

export const showNewPayrollLocationSuccessModal = () =>
  showModal(constants.PAYROLL_NEW_LOCATION_SUCCESS_MODAL, {
    width: 510,
  });

export const showPayrollTimeOffsModal = props => async dispatch =>
  dispatch(
    showModal(constants.PAYROLL_TIME_OFFS_MODAL, {
      deprecatedModal: true,
      auto: true,
      noPadding: true,
      ...props,
    })
  );

export const showPayrollCommitmentModal = props => async dispatch =>
  dispatch(
    showModal(constants.PAYROLL_COMMITMENT_MODAL, {
      deprecatedModal: true,
      auto: true,
      noPadding: true,
      hideCloseIcon: true,
      ...props,
    })
  );

export const showPayrollDocumentUploadModal = props => async dispatch =>
  dispatch(
    showModal(constants.PAYROLL_DOCUMENT_UPLOAD_MODAL, {
      deprecatedModal: true,
      auto: true,
      noPadding: true,
      ...props,
    })
  );

export const openTimeOffModal = props =>
  showModal(constants.TIME_OFF_MODAL, {
    auto: true,
    noPadding: false,
    ...props,
  });

export const openReadyToRunPayrollModal = props =>
  showModal(constants.READY_TO_RUN_PAYROLL_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: false,
    hideCloseIcon: true,
    shouldCloseOnOverlayClick: false,
    ...props,
  });

export const openPayrollFailedPaymentResolutionModal = props =>
  showModal(constants.PAYROLL_FAILED_PAYMENT_RESOLUTION_MODAL, {
    auto: true,
    noPadding: true,
    ...props,
  });

export const openPayrollFailedPaymentWireFundsModal = props =>
  showModal(constants.PAYROLL_FAILED_PAYMENT_WIRE_FUNDS_MODAL, {
    auto: true,
    noPadding: true,
    ...props,
  });

export const openPayrollFailedPaymentReattemptWithdrawalModal = props =>
  showModal(constants.PAYROLL_FAILED_PAYMENT_REATTEMPT_WITHDRAWAL_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    ...props,
  });

export const openInviteEmployeeModal = props =>
  showModal(constants.INVITE_EMPLOYEE_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
    ...props,
  });

export const openDrawerCloseConfirmationModal = props =>
  showModal(constants.CONFIRM_MODAL_TYPE, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
    ...props,
  });

export const openCarouselMediaModal = props =>
  showModal(constants.SCHEDULE_BUILDER_CAROUSEL_DEMO_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
    fullScreenAtMobile: true,
    shouldCloseOnOverlayClick: false,
    ...props,
  });

export const openFreeTrialModal = props =>
  showModal(constants.FREE_TRIAL_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
    fullScreenAtMobile: true,
    shouldCloseOnOverlayClick: false,
    ...props,
  });

export const openCompletedGuideModal = props =>
  showModal(constants.COMPLETED_GUIDE_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
    fullScreenAtMobile: true,
    shouldCloseOnOverlayClick: false,
    ...props,
  });

export const openConfirmationModal = props =>
  showModal(constants.CONFIRMATION_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    hideCloseIcon: true,
    fullScreenAtMobile: true,
    shouldCloseOnOverlayClick: false,
    ...props,
  });

export const showInvoicedCompanyModal = () =>
  showModal(constants.INVOICED_COMPANY_MODAL, { deprecatedModal: true });

export const showPayrollErrorModal = props =>
  showModal(constants.PAYROLL_RUN_ERROR_MODAL, {
    deprecatedModal: true,
    auto: true,
    hideCloseIcon: true,
    shouldCloseOnOverlayClick: false,
    ...props,
  });

export const showAutopayrollEnabledModal = () =>
  showModal(constants.AUTOPAYROLL_ENABLED_MODAL, {
    auto: true,
    noPadding: true,
    width: 540,
  });

export const showAutopayrollDisableModal = () =>
  showModal(constants.AUTOPAYROLL_DISABLE_MODAL, {
    auto: true,
  });

export const showDeleteAutopayrollDraftModal = props =>
  showModal(constants.DELETE_AUTOPAYROLL_DRAFT_MODAL, {
    auto: true,
    ...props,
  });

export const showAutopayrollFiveMinuteWarningModal = () =>
  showModal(constants.AUTOPAYROLL_FIVE_MINUTE_WARNING_MODAL, {
    auto: true,
  });

export const showQuickbooksExportModal = () =>
  showModal(constants.QUICKBOOKS_EXPORT_MODAL, {
    auto: true,
    onClose: closeModal => {
      trackUxEvent({
        productArea: PRODUCT_AREAS.TIMESHEETS,
        eventCategory: EVENT_CATEGORIES.QUICKBOOKS_TIMESHEETS_EXPORT,
        eventAction: EVENT_ACTIONS.DISMISS_CLICKED,
        actionType: TRACK_ACTION_TYPES.CLICK,
      });

      closeModal();
    },
  });

export const showSetupPayrollPeriodModal = reloadTimesheets =>
  showModal(constants.SETUP_PAYROLL_PERIOD_MODAL, {
    auto: true,
    onClose: closeModal => {
      trackUxEvent({
        productArea: PRODUCT_AREAS.TIMESHEETS,
        eventCategory: EVENT_CATEGORIES.PAY_PERIOD_SETTINGS_MODAL,
        eventAction: EVENT_ACTIONS.DISMISS_CLICKED,
        actionType: TRACK_ACTION_TYPES.CLICK,
      });

      closeModal();
    },
    reloadTimesheets,
  });

export const showPayrollSelfSetupDocumentsUploadModal = props => dispatch =>
  dispatch(
    showModal(constants.PAYROLL_SELF_SETUP_DOCUMENTS_UPLOAD_MODAL, {
      width: 731,
      ...props,
    })
  );

export const showPayrollSelfSetupDocumentsUploadSuccessModal = props =>
  showModal(constants.PAYROLL_SELF_SETUP_DOCUMENTS_UPLOAD_SUCCESS_MODAL, {
    width: 611,
    ...props,
  });

export const showEndOfTrialNbkDialog = ({
  trialManagers,
  secondLocationName,
}) =>
  showModal(constants.END_OF_TRIAL_NBK_DIALOG, {
    trialManagers,
    secondLocationName,
    forceOpen: true,
    auto: true,
  });

export const showYearToDateManualEntryModal = ({
  redirectToDashboard,
  ...props
}) =>
  showModal(constants.YEAR_TO_DATE_MANUAL_ENTRY_SUCCESS_MODAL, {
    width: 611,
    onClose: closeModal => {
      closeModal();
      redirectToDashboard();
    },
    redirectToDashboard,
    ...props,
  });

export const showEmployeeCertificateModal = ({ isEmbedded, ...props }) =>
  showModal(constants.EMPLOYEE_CERTIFICATE_MODAL, {
    auto: true,
    width: 960,
    hideCloseIcon: isEmbedded,
    fullScreen: isEmbedded,
    isEmbedded,
    ...props,
  });

export const showGoToHomebaseModal = props =>
  showModal(constants.CLOVER_GO_TO_HOMEBASE_MODAL, {
    auto: true,
    hideCloseIcon: true,
    disableBackdropClick: false,
    noPadding: true,
    ...props,
  });

export const showPostTimesheetsDownloadDialog = ({ initiatedFrom }) =>
  showModal(constants.POST_TIMESHEETS_DOWNLOAD_DIALOG, {
    initiatedFrom,
    auto: true,
  });

export const showMobileChangeTierModal = ({ biller }) =>
  showModal(constants.BILLER_MOBILE_CHANGE_TIER_MODAL, {
    auto: true,
    biller,
  });
