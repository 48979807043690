import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InitialViewKeyType } from './constants';

type OpenTaskManagerCheckoutDrawerPayloadActionType =
  | { initialViewKey: InitialViewKeyType }
  | undefined;

const slice = createSlice({
  name: 'taskManagerCheckoutDrawer',

  initialState: {
    isOpen: false,
    initialViewKey: 'Checkout',
  },

  reducers: {
    openTaskManagerCheckoutDrawer: (
      state,
      { payload }: PayloadAction<OpenTaskManagerCheckoutDrawerPayloadActionType>
    ) => {
      state.isOpen = true;
      state.initialViewKey = payload?.initialViewKey || 'Checkout';
    },

    closeTaskManagerCheckoutDrawer: state => {
      state.isOpen = false;
    },
  },
});
export const { openTaskManagerCheckoutDrawer, closeTaskManagerCheckoutDrawer } =
  slice.actions;
export default slice.reducer;
