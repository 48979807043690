function timeOffSortCompare(a, b) {
  const t1 = a.attributes.startAt;
  const t2 = b.attributes.startAt;

  if (a.attributes.isWholeDay !== b.attributes.isWholeDay) {
    if (a.attributes.isWholeDay) {
      return 1;
    } else if (b.attributes.isWholeDay) {
      return -1;
    }
  }

  if (t1 !== t2) {
    return new Date(t1) < new Date(t2) ? -1 : 1;
  }

  return parseInt(a.id, 10) < parseInt(b.id, 10) ? -1 : 1;
}

export default timeOffSortCompare;
