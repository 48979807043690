import { HAS_MANY } from 'fe-core/Entities/relationshipTypes';
import { createResource } from 'fe-core/Resources';

const Department = createResource({
  name: 'department',
  path: '/schedule_builder/department',
  schema: {
    attributes: ['name'],
    relationships: {
      roles: {
        relationshipType: HAS_MANY,
        inverseOf: 'department',
      },
    },
  },
});

export default Department;
