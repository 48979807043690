import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { pollWorkerStatus } from 'api/workerStatus';
import { fromJS } from 'immutable';

import { SLICE_NAME } from 'features/signUp/clover/constants';
import { createBusinessTracking } from 'features/signUp/util/apiHelper';

import { browserHistory } from 'util/router';

import { createCloverAccount, fetchCloverEmployees } from './actions';
import {
  postCreateEmbeddedCloverAccount,
  postSyncCloverEmployees,
  putOnboardingProperties,
} from './api';

const updateOnboardingProperties = createAsyncThunk(
  `${SLICE_NAME}/updateOnboardingProperties`,
  (
    { nextScreen, usePush = false, shouldReload = false, ...payload },
    { rejectWithValue }
  ) =>
    putOnboardingProperties(payload)
      .then(() => {
        if (shouldReload) {
          window.location.href = nextScreen;
          return;
        }

        if (usePush) {
          browserHistory.push(nextScreen);
        } else {
          browserHistory.replace(nextScreen);
        }
      })
      .catch(error => rejectWithValue(error.body || error.message))
);

const createEmbeddedCloverAccount = createAsyncThunk(
  `${SLICE_NAME}/createEmbeddedAccount`,
  ({ redirectPath }, { rejectWithValue }) =>
    postCreateEmbeddedCloverAccount()
      .then(params => {
        createBusinessTracking({ ...params });

        postSyncCloverEmployees({
          locationId: params.location.id,
          sendInvite: false,
          pos: true,
        })
          .then(resp => {
            pollWorkerStatus(resp.job_id)
              .catch(() => {
                sessionStorage.setItem(
                  'error',
                  'error occurred while employee sync'
                );
              })
              .finally(() => {
                window.location.href = redirectPath;
              });
          })
          .catch(() => {
            sessionStorage.setItem(
              'error',
              'error occurred while employee sync'
            );
            window.location.href = redirectPath;
          });

        return params;
      })
      .catch(error => rejectWithValue(error.body || error.message))
);

export const initialState = fromJS({
  isPending: false,
  error: {},
  success: {},
  companyName: '',
  employees: [],
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,

  reducers: {
    setUserSelection: (state, { payload: userSelection }) =>
      state.mergeDeep(fromJS(userSelection)),
  },

  extraReducers: builder => {
    builder.addCase(createCloverAccount.pending, state =>
      state.set('isPending', true).set('error', fromJS({}))
    );

    builder.addCase(createCloverAccount.fulfilled, (state, { payload }) =>
      state.mergeDeep({
        isPending: false,
        success: payload,
        error: {},
        companyName: payload ? payload.company.name : '',
      })
    );

    builder.addCase(createCloverAccount.rejected, (state, { payload }) =>
      state.set('isPending', false).set('error', fromJS(payload))
    );

    builder.addCase(createEmbeddedCloverAccount.pending, state =>
      state.set('isPending', true).set('error', fromJS({}))
    );

    builder.addCase(
      createEmbeddedCloverAccount.fulfilled,
      (state, { payload }) =>
        state.mergeDeep({
          isPending: false,
          success: payload,
          error: {},
          companyName: payload ? payload.company.name : '',
        })
    );

    builder.addCase(
      createEmbeddedCloverAccount.rejected,
      (state, { payload }) =>
        state.set('isPending', false).set('error', fromJS(payload))
    );

    builder.addCase(updateOnboardingProperties.pending, state =>
      state.set('isPending', true).set('error', fromJS({}))
    );

    builder.addCase(updateOnboardingProperties.fulfilled, state =>
      state.mergeDeep({
        isPending: false,
        success: {},
        error: {},
      })
    );

    builder.addCase(updateOnboardingProperties.rejected, (state, { payload }) =>
      state.set('isPending', false).set('error', fromJS(payload))
    );

    builder.addCase(fetchCloverEmployees.pending, state =>
      state.set('isPending', true).set('error', fromJS({}))
    );

    builder.addCase(fetchCloverEmployees.fulfilled, (state, { payload }) =>
      state.mergeDeep({
        isPending: false,
        success: {},
        error: {},
        employees: payload.users,
      })
    );

    builder.addCase(fetchCloverEmployees.rejected, (state, { payload }) =>
      state.set('isPending', false).set('error', fromJS(payload))
    );
  },
});

export const { actions, reducer } = slice;
