import { BELONGS_TO } from 'fe-core/Entities/relationshipTypes';
import { createResource } from 'fe-core/Resources';

const ShiftConflict = createResource({
  name: 'shiftConflict',
  schema: {
    attributes: ['type'],
    relationships: {
      shift: {
        relationshipType: BELONGS_TO,
        inverseOf: 'shiftConflicts',
      },
      conflict: {
        relationshipType: BELONGS_TO,
        inverseOf: 'createsConflicts',
      },
    },
  },
});

export default ShiftConflict;
