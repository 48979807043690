/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import IconButton from 'fe-design-base/molecules/IconButton';
import { compose } from 'redux';

import {
  getActiveTipPolicyId,
  getCurrentLocationName,
} from 'selectors/session';

import {
  fetchProductTipManager as getFetchProductTipManager,
  fetchTipManagerSubscriptions as getFetchTipManagerSubscriptions,
} from 'features/managePlan/actions';
import { selectProductTipManagerPrice } from 'features/managePlan/selectors';
import { updateTipPolicyEffectiveDate as updatePolicyEffectiveDate } from 'features/monetization/TipManagerCheckoutDrawer/thunk';
import { TipManagerCheckoutDrawerProps } from 'features/monetization/TipManagerCheckoutDrawer/types';
import {
  getCurrentPayrollPeriod,
  getPreviousPayrollPeriod,
} from 'features/settings/TipManagerSettingView/selectors';
import { fetchLocationTipPolicies as getFetchLocationTipPolicies } from 'features/settings/TipManagerSettingView/thunks';

import Drawer from 'components/Drawer';

import { cxHelpers } from 'util/className';
import { EVENT_CATEGORIES, PRODUCT_AREAS } from 'util/tracking_constants';
import { withUxRoot } from 'util/uxEvents';

import { selectInitialViewKey } from './selectors';
import { closeTipManagerCheckoutDrawer as getCloseTipManagerCheckoutDrawer } from './slice';
import * as views from './views';

const { cx } = cxHelpers('TipManagerCheckoutDrawer');

export const TipManagerCheckoutDrawer: React.FC<
  TipManagerCheckoutDrawerProps
> = ({
  closeTipManagerCheckoutDrawer,
  initialViewKey,
  fetchLocationTipPolicies,
  currentPayrollPeriod,
  currentLocationName,
  fetchProductTipManager,
  fetchTipManagerSubscriptions,
  price,
  subscriptionType,
  updateTipPolicyEffectiveDate,
  previousPayrollPeriod,
  tipPolicyId,
}) => {
  const [currentViewKey, setCurrentViewKey] = useState(initialViewKey);
  const [selectedEffectiveDate, setSelectedEffectiveDate] = useState('current');
  const [isLoading, setIsLoading] = useState(false);
  const CurrentView = views[currentViewKey as keyof typeof views];
  const currentPayPeriodStartDate = currentPayrollPeriod?.[0] || '';
  const previousPayPeriodStartDate = previousPayrollPeriod?.[0] || '';
  const handleOnLoading = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);
  const handleOnSuccess = useCallback(() => {
    setCurrentViewKey('Success');
    fetchLocationTipPolicies();
    fetchTipManagerSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchLocationTipPolicies();
    fetchProductTipManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTipPolicy = useCallback(() => {
    if (selectedEffectiveDate !== 'current') {
      updateTipPolicyEffectiveDate({
        id: tipPolicyId,
        effective_date: previousPayPeriodStartDate,
      });
    }
  }, [
    previousPayPeriodStartDate,
    selectedEffectiveDate,
    tipPolicyId,
    updateTipPolicyEffectiveDate,
  ]);

  return (
    <Drawer className={cx()} maxWidth="464px" open>
      <Box hright mt={16} mr={16}>
        <IconButton
          onClick={closeTipManagerCheckoutDrawer}
          icon="Close"
          size="small"
          uxElement="checkout_drawer.close_button"
        />
      </Box>
      <Box mt={12} ph={32}>
        <CurrentView
          onSuccess={handleOnSuccess}
          onLoading={handleOnLoading}
          isLoading={isLoading}
          currentPayPeriodStartDate={currentPayPeriodStartDate}
          previousPayPeriodStartDate={previousPayPeriodStartDate}
          closeDrawer={closeTipManagerCheckoutDrawer}
          locationName={currentLocationName}
          price={price}
          subscriptionType={subscriptionType}
          updateTipPolicy={updateTipPolicy}
          setSelectedEffectiveDate={setSelectedEffectiveDate}
        />
      </Box>
    </Drawer>
  );
};
export default compose(
  withUxRoot({
    productArea: PRODUCT_AREAS.TIP_MANAGER,
    eventCategory: EVENT_CATEGORIES.CHECKOUT,
  }),
  connect(
    state => ({
      initialViewKey: selectInitialViewKey(state),
      currentPayrollPeriod: getCurrentPayrollPeriod(state),
      previousPayrollPeriod: getPreviousPayrollPeriod(state),
      currentLocationName: getCurrentLocationName(state),
      price: selectProductTipManagerPrice(state),
      subscriptionType: 'tip_manager',
      tipPolicyId: getActiveTipPolicyId(state),
    }),
    {
      closeTipManagerCheckoutDrawer: getCloseTipManagerCheckoutDrawer,
      fetchLocationTipPolicies: getFetchLocationTipPolicies,
      fetchProductTipManager: getFetchProductTipManager,
      fetchTipManagerSubscriptions: getFetchTipManagerSubscriptions,
      updateTipPolicyEffectiveDate: updatePolicyEffectiveDate,
    }
  )
)(TipManagerCheckoutDrawer);
