import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as notificationsActions from 'actions/notifications';

import { buildGetDrawerOpen } from 'selectors/drawers';
import * as notificationsSelectors from 'selectors/notifications';
import { getCurrentUserIsManagerial } from 'selectors/session';

import Box from 'components/Box';
import IconButton from 'components/clickables/IconButton';
import Drawer from 'components/Drawer';
import TabbedNav from 'components/TabbedNav';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { toI18n } from 'util/i18n';

import { NOTIFICATIONS_DRAWER_KEY } from '../../drawers/keys';
import { EVENT_ACTIONS, trackNotificationEvents } from '../tracking';

import NotificationList from './NotificationsList';

const NAV_HEADERS = [
  toI18n('notifications.requests.title'),
  toI18n('notifications.alerts.title'),
];

const EMPLOYEE_NAV_HEADERS = [toI18n('notifications.requests.title')];

const DRAWER_CONTAINER_STYLE = {
  boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
  width: '390px',
};

@connect(
  (state, props) => ({
    requestsItems: notificationsSelectors.getRequestsItems(state, props),
    alertsItems: notificationsSelectors.getAlertsItems(state, props),
    isManagerial: getCurrentUserIsManagerial(state, props),
    isFetching: notificationsSelectors.getIsFetchingNotifications(state, props),
    hasMoreRequests: notificationsSelectors.getHasMoreRequests(state, props),
    hasMoreAlerts: notificationsSelectors.getHasMoreAlerts(state, props),
    isOpen: buildGetDrawerOpen(NOTIFICATIONS_DRAWER_KEY)(state, props),
  }),
  {
    fetchNotifications: notificationsActions.fetchNotifications,
    onToggle: notificationsActions.toggleNotificationsShown,
  }
)
@cxHelpers('NotificationsView')
export default class NotificationsView extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    requestsItems: ImmutablePropTypes.orderedMap.isRequired,
    alertsItems: ImmutablePropTypes.orderedMap.isRequired,
    isManagerial: PropTypes.bool,
    fetchNotifications: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    hasMoreRequests: PropTypes.bool.isRequired,
    hasMoreAlerts: PropTypes.bool.isRequired,
  };

  handleClose = () => {
    trackNotificationEvents({
      eventAction: EVENT_ACTIONS.CLOSE_CLICKED,
    });
    this.props.onToggle(false);
  };

  handleAlertsTabClick = () => {
    trackNotificationEvents({
      eventAction: EVENT_ACTIONS.ALERTS_CLICKED,
    });
  };

  handleRequestsTabClick = () => {
    trackNotificationEvents({
      eventAction: EVENT_ACTIONS.REQUESTS_CLICKED,
    });
  };

  handleNav = () => (this.scrollContainer.scrollTop = 0);

  getContainerRef = container => {
    this.scrollContainer = container;
  };

  getScrollContainer = () => this.scrollContainer;

  getHeaders() {
    return (this.props.isManagerial ? NAV_HEADERS : EMPLOYEE_NAV_HEADERS).map(
      content => {
        const collection =
          content === toI18n('notifications.requests.title')
            ? this.props.requestsItems
            : this.props.alertsItems;

        const handleTabClick =
          content === toI18n('notifications.requests.title')
            ? this.handleRequestsTabClick
            : this.handleAlertsTabClick;

        return {
          content: (
            <Box onClick={handleTabClick} inline relative>
              <Text>{content}</Text>
              {collection.size > 0 && (
                <Box
                  tag="span"
                  inlineBlock
                  top={-2}
                  right={-10}
                  absolute
                  bg="red"
                  bradius={15}
                  w={8}
                  h={8}
                  className={this.cxEl('red-dot')}
                />
              )}
            </Box>
          ),
        };
      }
    );
  }

  renderNotificationsList(type, items) {
    const { fetchNotifications, isFetching, hasMoreRequests, hasMoreAlerts } =
      this.props;

    return (
      <Box key={type}>
        <NotificationList
          notificationType={type}
          notificationsItems={items}
          fetchNotifications={fetchNotifications}
          isFetching={isFetching}
          hasMore={type === 'requests' ? hasMoreRequests : hasMoreAlerts}
          getScrollContainer={this.getScrollContainer}
          onClose={this.handleClose}
        />
      </Box>
    );
  }

  renderContent() {
    const { requestsItems, alertsItems, isManagerial } = this.props;

    const content = [this.renderNotificationsList('requests', requestsItems)];

    if (isManagerial) {
      content.push(this.renderNotificationsList('alerts', alertsItems));
    }

    return content;
  }

  renderCloseIconButton = () => (
    <IconButton
      onClick={this.handleClose}
      type="mdClose"
      iconColor="grayDark"
      className={this.cxEl('close-button')}
      size="sm"
    />
  );

  render() {
    if (!this.props.isOpen) return null;

    return (
      <Drawer containerStyle={DRAWER_CONTAINER_STYLE} open>
        <Box className={this.cx()} column w={390} maxh="100%">
          <TabbedNav
            noBorder
            large
            fixedHeader
            headers={this.getHeaders()}
            getRef={this.getContainerRef}
            onNav={this.handleNav}
            renderHeaderRight={this.renderCloseIconButton}
          >
            {this.renderContent()}
          </TabbedNav>
        </Box>
      </Drawer>
    );
  }
}
