import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postJSON } from 'api/fetch';

export const updatePaymentResolution = createAsyncThunk(
  'updatePaymentResolution',
  (payload, { rejectWithValue }) =>
    postJSON('/payroll/nsf/update_resolution', payload).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const getPaymentWireDetails = createAsyncThunk(
  'getPaymentWireDetails',
  (payload, { rejectWithValue }) =>
    postJSON('/payroll/nsf/wire_details', payload).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

const payrollFailedPaymentSlice = createSlice({
  name: 'payrollFailedPayment',

  initialState: {
    isUpdatingPaymentResolution: false,
    isFetchingWireDetails: false,
    wireDetails: {},
  },

  reducers: {
    setWireDetails: (state, action) => {
      state.wireDetails = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(updatePaymentResolution.pending, state => {
      state.isUpdatingPaymentResolution = true;
    });

    builder.addCase(updatePaymentResolution.fulfilled, state => {
      state.isUpdatingPaymentResolution = false;
    });

    builder.addCase(updatePaymentResolution.rejected, state => {
      state.isUpdatingPaymentResolution = false;
    });

    builder.addCase(getPaymentWireDetails.pending, state => {
      state.isFetchingWireDetails = true;
    });

    builder.addCase(getPaymentWireDetails.fulfilled, state => {
      state.isFetchingWireDetails = false;
    });

    builder.addCase(getPaymentWireDetails.rejected, state => {
      state.isFetchingWireDetails = false;
    });
  },
});

// selectors
export const getWireDetails = state =>
  state.get('payroll').payrollFailedPayment.wireDetails;
export const getIsUpdatingPaymentResolution = state =>
  state.get('payroll').payrollFailedPayment.isUpdatingPaymentResolution;
export const getIsFetchingWireDetails = state =>
  state.get('payroll').payrollFailedPayment.isFetchingWireDetails;

export const payrollFailedPaymentReducer = payrollFailedPaymentSlice.reducer;
export const payrollFailedPaymentActions = payrollFailedPaymentSlice.actions;
