import capitalize from 'lodash/capitalize';

export const VALID_TIERS = [
  'basic',
  'essentials',
  'plus',
  'enterprise',
  'all-in-one',
] as const;

export type ValidTiers =
  | 'basic'
  | 'essentials'
  | 'plus'
  | 'enterprise'
  | 'all-in-one';

export const isValidTierName = (tierName?: string | null): boolean =>
  Boolean(tierName && (VALID_TIERS as unknown as string[]).includes(tierName));

export const labelForPlanName = (planName?: string | null) => {
  if (!planName) {
    return '';
  }

  if (
    planName.toLowerCase() === 'enterprise' ||
    planName.toLowerCase() === 'all-in-one'
  ) {
    return 'All-In-One';
  }

  return capitalize(planName);
};

export const slugForPlanName = (planName?: string | null) =>
  planName === 'enterprise' ? 'all-in-one' : (planName as ValidTiers);

export const isCtcCtaDisabled = ({
  dirty,
  values,
  isValid,
  isSubmitting,
}: {
  dirty: boolean;
  isValid?: boolean;
  isSubmitting?: boolean;
  values: { phone?: string | null };
}) => isSubmitting || (dirty && !isValid) || (!dirty && !values.phone);
