import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON, putJSON } from 'api/fetch';

export const fetchTipManagerFee = createAsyncThunk('fetchTipManager', () =>
  fetchJSON('/product_and_prices.json?product_name=Tip%20Manager')
);

export const updateTipPolicyEffectiveDate = createAsyncThunk(
  'tipPoolingPolicies/updateTipPolicy',
  (payload: { id: number; effective_date: string }, { rejectWithValue }) =>
    putJSON(`/timesheets/tip_pooling_policies/${payload.id}/`, {
      effective_date: payload.effective_date,
    }).catch(err =>
      err.response.json().then((body: string) => rejectWithValue(body))
    )
);
