import { useMemo } from 'react';
import FDBButton from 'fe-design-base/molecules/Button';
import { Size } from 'fe-design-base/molecules/Button/Button';

import useGoToManagePlanPage from 'features/productGrowth/hooks/useGoToManagePlanPage';

import Button from 'components/clickables/Button';

import { cxHelpers } from 'util/className';
const { cx } = cxHelpers('GoToManagePlanPageButton');

export interface GoToManagePlanPageButtonProps {
  size?: Size;
  i18n: string;
  theme: string;
  linkTo?: string;
  pageUrl?: string;
  planName?: string;
  children?: string;
  fullWidth?: boolean;
  onClick?: () => void;
  baseComponent?: string;
  loadFullPage?: boolean;
  focusedFeature?: string;
  i18nProps?: Record<string, string>;
}

export const GoToManagePlanPageButton = ({
  onClick,
  pageUrl,
  planName,
  children = '',
  focusedFeature,
  loadFullPage = false,
  baseComponent = 'clickables',
  ...props
}: GoToManagePlanPageButtonProps) => {
  const Component = useMemo(
    () => (baseComponent === 'designbase' ? FDBButton : Button),
    [baseComponent]
  );

  const { extraProps, handleOnClick } = useGoToManagePlanPage({
    onClick,
    pageUrl,
    planName,
    loadFullPage,
    focusedFeature,
    linkTo: props.linkTo,
  });

  return (
    <Component
      {...props}
      {...extraProps}
      className={cx()}
      onClick={handleOnClick}
    >
      {children}
    </Component>
  );
};

export default GoToManagePlanPageButton;
