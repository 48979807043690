import { BELONGS_TO, HAS_MANY } from 'fe-core/Entities/relationshipTypes';
import { createResource } from 'fe-core/Resources';

const ScheduleAvailability = createResource({
  name: 'scheduleAvailability',
  schema: {
    attributes: ['title', 'allDay', 'startAt', 'endAt', 'date'],
    relationships: {
      job: {
        relationshipType: BELONGS_TO,
        inverseOf: 'scheduleAvailabilities',
      },
      createsConflicts: {
        relationshipType: HAS_MANY,
        inverseOf: 'conflict',
      },
    },
  },
});

export default ScheduleAvailability;
