import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON, postJSON } from 'api/fetch';

import { toI18n } from 'util/i18n';

export const fetchTeamMember = createAsyncThunk(
  'payroll/fetchTeamMember',
  userId => fetchJSON(`/payroll/implementation/team_member/${userId}`)
);

export const updateTeamMember = createAsyncThunk(
  'payroll/updateTeamMember',
  ({ userId, data }, { rejectWithValue }) =>
    postJSON(`/payroll/implementation/team_member/${userId}`, data).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

const getUserJob = (currentLocationId, jobs) =>
  jobs.find(j => j.location_id === currentLocationId) ||
  jobs
    .filter(j => j.hire_date)
    .sort((a, b) => new Date(a.hire_date) - new Date(b.hire_date))[0] ||
  jobs[0];

const teamSetupSlice = createSlice({
  name: 'payrollTeamSetup',

  initialState: {
    editingUser: null,
    isFetchingTeamMember: false,
    isUpdatingTeamMember: false,
    teamFilterByStatus: toI18n(
      'payroll.tasks_dashboard.task_modules.team_setup.filter.missing'
    ),
  },

  reducers: {
    resetEditingUser: state => {
      state.editingUser = null;
    },

    setFilterByStatus: (state, action) => {
      state.teamFilterByStatus = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchTeamMember.pending, state => {
      state.isFetchingTeamMember = true;
      state.editingUser = null;
    });

    builder.addCase(fetchTeamMember.fulfilled, (state, action) => {
      state.isFetchingTeamMember = false;

      const {
        id,
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        currentLocationId,
        jobs,
        tax_classification: jobTaxClassification,
        contractor_type: contractorType,
        included_in_payroll: includedInPayroll,
        has_jobs_at_more_than_one_company: hasJobsAtMoreThanOneCompany,
        tax_classification_is_editable: hasNoActivePayments,
        has_paid_payroll_runs_for_job: hasPaidPayrollRunsForJob,
        has_itin: hasITIN,
      } = action.payload;
      const job = getUserJob(currentLocationId, jobs);

      const wageAttributes = job.wages.map(wage => ({
        id: wage.id,
        role_name: wage.role_name,
        wage_rate: wage.rate,
        wage_type: wage.type,
      }));

      state.editingUser = {
        id,
        jobId: job.id,
        firstName,
        lastName,
        email,
        wageAttributes,
        phone,
        hireDate: job.hire_date,
        level: job.level,
        includedInPayroll,
        jobTaxClassification,
        contractorType,
        hasJobsAtMoreThanOneCompany,
        hasITIN,
        hasNoActivePayments,
        hasPaidPayrollRunsForJob,
        offeredHealthBenefits: !!job.offered_health_benefits,
        fourTensRuleEnabled: !!job.four_tens_rule_enabled,
      };
    });

    builder.addCase(fetchTeamMember.rejected, state => {
      state.isFetchingTeamMember = false;
    });

    builder.addCase(updateTeamMember.pending, state => {
      state.isUpdatingTeamMember = true;
    });

    builder.addCase(updateTeamMember.fulfilled, state => {
      state.isUpdatingTeamMember = false;
    });

    builder.addCase(updateTeamMember.rejected, state => {
      state.isUpdatingTeamMember = false;
    });
  },
});

// Selectors
export const getEditingUser = state =>
  state.get('payroll').payrollTeamSetup.editingUser;
export const getIsFetchingTeamMember = state =>
  state.get('payroll').payrollTeamSetup.isFetchingTeamMember;
export const getIsUpdatingTeamMember = state =>
  state.get('payroll').payrollTeamSetup.isUpdatingTeamMember;
export const getTeamFilterByStatus = state =>
  state.get('payroll').payrollTeamSetup.teamFilterByStatus;

export const payrollTeamSetupReducer = teamSetupSlice.reducer;
export const payrollTeamSetupActions = teamSetupSlice.actions;
