import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';

export const fetchPayrollHistory = createAsyncThunk(
  'payroll/fetchPayrollHistory',
  ({ startDate, endDate }) =>
    fetchJSON(
      `/payroll/history.json?start_date=${startDate}&end_date=${
        endDate || startDate
      }`
    )
);

const payrollHistorySlice = createSlice({
  name: 'payrollHistory',

  initialState: {
    isFetching: false,
    errors: [],
    payrollRuns: [],
  },

  extraReducers: builder => {
    builder.addCase(fetchPayrollHistory.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(fetchPayrollHistory.fulfilled, (state, action) => {
      state.isFetching = false;
      state.payrollRuns = action.payload;
    });

    builder.addCase(fetchPayrollHistory.rejected, state => {
      state.isFetching = false;
    });
  },
});

export const payrollHistoryReducer = payrollHistorySlice.reducer;
export const payrollHistoryActions = payrollHistorySlice.actions;
