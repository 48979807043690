import { fromJS, Map } from 'immutable';

import { toCurrency } from 'util/currency';
import { toI18n } from 'util/i18n';

// Types
export type PayrollPeriod = 'week' | 'twice_week' | 'month' | 'twice_month';
export type PayrollPeriodOption = {
  value: PayrollPeriod;
  label: string;
};

interface Summary {
  [key: string]: string;
}

interface FilteredSummary {
  all: Summary;
  filtered: {
    [key: string]: Summary;
  };
}

interface JobsSummary {
  [jobId: string]: Summary;
}

interface DaysSummary {
  [date: string]: FilteredSummary;
}

interface RolesSummary {
  [roleId: string]: FilteredSummary;
}

// Constants
export const PAYROLL_PERIODS: PayrollPeriod[] = [
  'week',
  'twice_week',
  'month',
  'twice_month',
];
export const MAX_DAYS_FOR_BI_MONTH_PAYROLL = 28;
export const PAYROLL_PERIOD_OPTIONS: PayrollPeriodOption[] =
  PAYROLL_PERIODS.map(value => ({
    value,
    label: toI18n(`location.payroll_period.${value}`),
  }));

// Field definitions for different types of data
const NUMERIC_FIELDS = [
  'double_overtimes',
  'regular',
  'unpaid_breaks_hours',
  'break_penalty',
  'ffcra_paid_sick_leave_hours',
  'ffcra_care_for_others_hours',
  'ffcra_care_for_child_hours',
  'time_off_hours',
  'sick_time_hours',
  'spread_of_hours',
  'blue_laws_hours',
  'split_shift_hours',
  'holiday_pay',
  'overtime',
  'paid',
  'actual',
  'variance',
  'scheduled_hours',
] as const;

const CURRENCY_FIELDS = ['costs', 'cash_tips', 'credit_tips'] as const;

// Helper functions for data manipulation
function parseNumericValue(value: string | number): number {
  return parseFloat(value as string) || 0;
}

function parseCurrencyValue(value: string): number {
  if (!value || typeof value !== 'string') return 0;
  return parseFloat(value.replace(/[$,]/g, '')) || 0;
}

function formatNumericValue(value: number | string): string {
  return parseNumericValue(value).toFixed(2);
}

// Summary object creation and manipulation
function createEmptySummary(): Summary {
  return {
    ...Object.fromEntries(NUMERIC_FIELDS.map(field => [field, '0.00'])),
    ...Object.fromEntries(CURRENCY_FIELDS.map(field => [field, toCurrency(0)])),
  };
}

function createFilteredSummaryStructure(): FilteredSummary {
  return {
    all: createEmptySummary(),
    filtered: {},
  };
}

function updateSummaryWithData(summary: Summary, row: Map<string, any>): void {
  if (!row || !row.size) return;

  // Handle numeric fields
  NUMERIC_FIELDS.forEach(field => {
    const current = parseNumericValue(summary[field]);
    const addition = parseNumericValue(row.get(field));
    summary[field] = formatNumericValue(current + addition);
  });

  // Handle currency fields
  CURRENCY_FIELDS.forEach(field => {
    const current = parseCurrencyValue(summary[field]);
    const addition = parseCurrencyValue(row.get(field));
    summary[field] = toCurrency(current + addition);
  });
}

function standardizeDate(date: string): string {
  if (!date || !date.includes('/')) return date;

  const [month, day, year] = date.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

// Main calculation functions
export function calculateJobsSummary(rows: Map<string, any>): Map<string, any> {
  const jobsSummary: JobsSummary = {};

  rows.toList().forEach(row => {
    const jobId = row.get('job_id')?.toString();
    if (!jobId) return;

    if (!jobsSummary[jobId]) {
      jobsSummary[jobId] = createEmptySummary();
    }

    updateSummaryWithData(jobsSummary[jobId], row);
  });

  return fromJS(jobsSummary);
}

export function calculateDaysSummary(rows: Map<string, any>): Map<string, any> {
  const daysSummary: DaysSummary = {};

  rows.toList().forEach(row => {
    const date = standardizeDate(row.get('date'));
    if (!date) return;

    const jobId = row.get('job_id')?.toString();
    if (!jobId) return;

    // Initialize structures if they don't exist
    if (!daysSummary[date]) {
      daysSummary[date] = createFilteredSummaryStructure();
    }
    if (!daysSummary[date].filtered[jobId]) {
      daysSummary[date].filtered[jobId] = createEmptySummary();
    }

    // Update both the day total and job-specific totals
    updateSummaryWithData(daysSummary[date].all, row);
    updateSummaryWithData(daysSummary[date].filtered[jobId], row);
  });

  return fromJS(daysSummary);
}

export function calculateRolesSummary(
  rows: Map<string, any>
): Map<string, any> {
  const rolesSummary: RolesSummary = {
    '': createFilteredSummaryStructure(), // Default summary for all roles
  };

  rows.toList().forEach(row => {
    const roleId = row.get('role_id');
    const roleIdString = roleId ? roleId.toString() : '';
    const jobId = row.get('job_id')?.toString();
    if (!jobId) return;

    // Initialize role summary if it doesn't exist
    if (!rolesSummary[roleIdString]) {
      rolesSummary[roleIdString] = createFilteredSummaryStructure();
    }

    // Update both role-specific and overall totals
    [roleIdString, ''].forEach(rid => {
      if (!rolesSummary[rid].filtered[jobId]) {
        rolesSummary[rid].filtered[jobId] = createEmptySummary();
      }
      updateSummaryWithData(rolesSummary[rid].all, row);
      updateSummaryWithData(rolesSummary[rid].filtered[jobId], row);
    });
  });

  return fromJS(rolesSummary);
}

export function calculateFooterTotals(
  rows: Map<string, any>
): Map<string, any> {
  const totals = createEmptySummary();
  rows.toList().forEach(row => updateSummaryWithData(totals, row));
  return fromJS(totals);
}
