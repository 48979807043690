import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  openCloverTimeClockInterstitial,
  openSharedTimeclockModal,
} from 'actions/modals';

import getIsEmbedded from 'util/embedded';
import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

export const useHubTimeClock = () => {
  const dispatch = useDispatch();
  const isEmbedded = getIsEmbedded();

  return useCallback(
    (trackingProperties = {}) => {
      trackUxEvent({
        productArea: PRODUCT_AREAS.TIMESHEETS,
        eventCategory: EVENT_CATEGORIES.GET_A_TIME_CLOCK,
        eventAction: EVENT_ACTIONS.WEB_TIME_CLOCK_LAUNCH_CLICKED,
        properties: trackingProperties,
      });

      if (isEmbedded) {
        dispatch(openCloverTimeClockInterstitial());
      } else {
        dispatch(openSharedTimeclockModal());
      }
    },
    [isEmbedded, dispatch]
  );
};
