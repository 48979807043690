import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { fetchLightspeedEmployees } from 'features/signUp/lightspeed/actions';

import { SLICE_NAME } from './constants';

export const initialState = fromJS({
  isPending: false,
  error: {},
  success: {},
  companyName: '',
  employees: [],
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,

  reducers: {
    setUserSelection: (state, { payload: userSelection }) =>
      state.mergeDeep(fromJS(userSelection)),
  },

  extraReducers: builder => {
    builder.addCase(fetchLightspeedEmployees.pending, state =>
      state.set('isPending', true).set('error', fromJS({}))
    );

    builder.addCase(fetchLightspeedEmployees.fulfilled, (state, { payload }) =>
      state.mergeDeep({
        isPending: false,
        success: {},
        error: {},
        employees: payload.users,
      })
    );

    builder.addCase(fetchLightspeedEmployees.rejected, (state, { payload }) =>
      state.set('isPending', false).set('error', fromJS(payload))
    );
  },
});

export const { actions, reducer } = slice;
