import { HAS_MANY } from 'fe-core/Entities/relationshipTypes';
import { createResource } from 'fe-core/Resources';

const User = createResource({
  name: 'user',
  schema: {
    relationships: {
      jobs: {
        defaultType: 'job',
        relationshipType: HAS_MANY,
        inverseOf: 'user',
      },
    },
  },
});

export default User;
