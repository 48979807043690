import { fromJS, List } from 'immutable';

import { toI18n } from 'util/i18n';

export const LAST_PUBLISHED_ID = 'LAST_PUBLISHED_ID';
export const DEFAULT_ROLE_COLOR = 'blue';

export const INITIAL_PUBLISH_SCHEDULE_DRAWER_STATE = fromJS({
  data: null,
  schedulePublished: false,
  template: null,
  templateRecurrence: null,
  templateIsChanged: null,
  hasCreatedNote: false,
});

export const INITIAL_EMPTY_SCHEDULE_DRAWER_STATE = fromJS({
  date: null,
  lastPublishedSchedule: null,
  templates: List(),
  templatesIsFetching: false,
  hasBeenClosed: false,
});

export const INITIAL_SYNC_SHIFTS_DRAWER_STATE = fromJS({
  startDate: null,
  endDate: null,
  errorsCount: null,
});

export const INITIAL_SCHEDULE_BUILDER_STATE = fromJS({
  showScheduleSmsDiscoverability: false,
});

export const INITIAL_PRINT_DRAWER_STATE = fromJS({
  startDate: null,
  endDate: null,
  viewType: null,
});

export const REPEATING_TEMPLATES_FOREVER_OPTION = {
  value: -1,
  label: toI18n(
    'schedule_builder.publish_drawer.add_schedule_recurrence.repeating_template_options.forever'
  ),
};

export const REPEATING_TEMPLATES_OPTIONS = [1, 2, 3, 4, 10]
  .map(value => {
    const key =
      'schedule_builder.publish_drawer.add_schedule_recurrence.repeating_template_options.weeks';
    const label = toI18n(key, { props: { count: value } });

    return { value: value - 1, label };
  })
  .concat([REPEATING_TEMPLATES_FOREVER_OPTION]);

export const COLOR_PICKER_OPTIONS_V1 = [
  'blue',
  'blue2',
  'fiolet',
  'green',
  'green2',
  'green3',
  'orange',
  'orange2',
  'red',
  'red2',
  'yellow',
  'yellow2',
];

export const COLOR_PICKER_OPTIONS_V2 = [
  'blue2',
  'fiolet3',
  'fiolet',
  'green',
  'blue',
  'fiolet2',
  'yellow',
  'orange2',
  'red',
  'red2',
  'fiolet4',
  'yellow2',
];

export const COLORMAP_CLASSNAMES = {
  V1: 'option',
  V2: 'option-v2',
  ROLE_V1: 'role',
  ROLE_V2: 'role-v2',
};

export const DEFAULT_SHIFT_COLOR_V1 = COLOR_PICKER_OPTIONS_V1[0];
export const DEFAULT_SHIFT_COLOR_V2 = COLOR_PICKER_OPTIONS_V2[0];

export const DAYS_MAP = new Map([
  [1, 'M'],
  [2, 'T'],
  [3, 'W'],
  [4, 'T'],
  [5, 'F'],
  [6, 'S'],
  [0, 'S'],
]);

export const DAYS_OF_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const ROLE_PICKER_TRANSLATION_PATH =
  'schedule_builder.react_page.role_picker';

export const NO_ROLE_LABEL = toI18n(`${ROLE_PICKER_TRANSLATION_PATH}.no_role`);

export const CREATE_ROLE_LABEL = toI18n(
  `${ROLE_PICKER_TRANSLATION_PATH}.create_role`
);

export const TEAM_ROLE_LABEL = toI18n(
  `${ROLE_PICKER_TRANSLATION_PATH}.team_roles`
);

export const EMPLOYEE_ROLE_TYPE = 'employee';

export const TEAM_ROLE_TYPE = 'team';

export const INBUILT_ROLE_TYPE = 'inbuilt';

export const TEMPLATE_DETAILS_PATH = 'schedule_builder.react_page.template';

export type PARTNERS = 'toast';

export const SCHEDULE_BUILDER_PATH = '/schedule_builder';
