import { ACTION_UPDATE } from 'fe-core/module-constants';
import { createResource } from 'fe-core/Resources';
import { create } from 'fe-core/Resources/actions';

const JobPosition = createResource({
  name: 'jobPosition',
  path: '/schedule_builder/job_positions',
});

JobPosition.sort = payload =>
  create({
    path: '/schedule_builder/job_positions/sort',
    name,
    action: ACTION_UPDATE,
    payload,
  });

export default JobPosition;
