import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { sendDownloadAppSms } from './actions';
import { SLICE_NAME } from './constants';

const slice = createSlice({
  name: SLICE_NAME,

  initialState: fromJS({
    submitPending: false,
    serverErrors: null,
  }),

  reducers: {},

  extraReducers: builder => {
    builder.addCase(sendDownloadAppSms.pending, state =>
      state.merge({
        serverErrors: null,
        submitPending: true,
      })
    );

    builder.addCase(sendDownloadAppSms.fulfilled, (state, { meta: { arg } }) =>
      state.merge({
        sentPhone: arg,
        submitPending: false,
      })
    );

    builder.addCase(sendDownloadAppSms.rejected, (state, { payload }) =>
      state.merge({
        submitPending: false,
        serverErrors: payload,
      })
    );
  },
});

export const { actions, reducer } = slice;
