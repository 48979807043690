import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';
import { findIndex, times } from 'lodash';

import { COLUMNS } from 'features/addTeam/bulkAdd/constants';

import { getFieldName } from 'components/EditableTable/util';

import { toCurrency } from 'util/currency';
import { toI18n } from 'util/i18n';

function generateRandomPin(pinLength) {
  return Math.random()
    .toString()
    .slice(2, 2 + pinLength);
}

const newRow = state => ({
  level: { value: 'employee', columnName: 'level' },
  pin: { value: generateRandomPin(state.get('pinLength')), columnName: 'pin' },
  locations: {
    value: [state.get('defaultLocationId')],
    columnName: 'locations',
  },
  wage_type: { value: 0, columnName: 'wage_type' },
});

function getMatchedFieldNames(fieldName, columnName) {
  if (columnName === 'wage_rate') {
    return {
      wageField: fieldName,
      roleField: fieldName.replace('wage_rate', 'role'),
    };
  }
  return {
    roleField: fieldName,
    wageField: fieldName.replace('role', 'wage_rate'),
  };
}

const showWageRateLimitError = (state, errorPath) =>
  state.setIn(
    errorPath,
    toI18n('add_team.bulk_add.table.validations.wage_rate_limit', {
      props: { limit: toCurrency(10000, { precision: 0 }) },
    })
  );

const isHourly = wageType => wageType === 0;

const isYearly = wageType => wageType === 1;

function validateWageRoles(state, action) {
  const { rowIndex, columnName, fieldName, value } = action.payload;
  const { roleField, wageField } = getMatchedFieldNames(fieldName, columnName);

  const minRoles = fieldName === columnName ? 1 : 0;
  const errorPath = ['rows', rowIndex, wageField, 'error'];
  const wageType = state.getIn(['rows', rowIndex, 'wage_type', 'value']);

  if (wageField === 'wage_rate' && isYearly(wageType)) {
    return state.deleteIn(errorPath);
  }

  const wageValue = state.getIn(['rows', rowIndex, wageField, 'value']);
  const roleValues = state.getIn(['rows', rowIndex, roleField, 'value']);
  const roleValuesLength = (value || (roleValues && roleValues.toJS()) || [])
    .length;

  if (roleValuesLength > minRoles && !wageValue) {
    return state.setIn(errorPath, 'Wage rate is required for multiple roles');
  }

  if (isHourly(wageType) && wageValue && wageValue >= 10000) {
    return showWageRateLimitError(state, errorPath);
  }

  return state.deleteIn(errorPath);
}

const validateField = (state, action) => {
  const validations = COLUMNS[action.payload.columnName].validations || [];

  if (validations.length) {
    const value = state.getIn([
      'rows',
      action.payload.rowIndex,
      action.payload.fieldName,
      'value',
    ]);

    const errorPath = [
      'rows',
      action.payload.rowIndex,
      action.payload.fieldName,
      'error',
    ];

    const validationAttrs =
      action.payload.fieldName === 'pin'
        ? { pinLength: state.get('pinLength') }
        : {};

    // eslint-disable-next-line
    for (const validation of validations) {
      const validationError = validation(value, validationAttrs);
      if (validationError) {
        return state.setIn(errorPath, validationError);
      }
    }

    return state.deleteIn(errorPath);
  } else if (action.payload.columnName === 'wage_rate') {
    state = validateWageRoles(state, action);
  } else if (action.payload.columnName === 'wage_type') {
    const wageRateColumnName = 'wage_rate';
    const wageType = state.getIn([
      'rows',
      action.payload.rowIndex,
      action.payload.fieldName,
      'value',
    ]);

    // set the value
    state = state.mergeIn(['rows', action.payload.rowIndex, 'wage_type'], {
      value: wageType,
    });

    // validate the wage rate value for this row
    const wageRateErrorPath = [
      'rows',
      action.payload.rowIndex,
      wageRateColumnName,
      'error',
    ];

    if (isHourly(wageType)) {
      // hourly
      const wageValue = state.getIn([
        'rows',
        action.payload.rowIndex,
        wageRateColumnName,
        'value',
      ]);

      if (wageValue && wageValue >= 10000) {
        state = showWageRateLimitError(state, wageRateErrorPath);
      }
    } else if (isYearly(wageType)) {
      // salaried
      state = state.deleteIn(wageRateErrorPath);
    }
  }

  return state;
};

const setValue = (state, action) => {
  let newState = state.mergeIn(
    ['rows', action.payload.rowIndex, action.payload.fieldName],
    {
      value: action.payload.value,
      columnName: action.payload.columnName,
      fieldName: action.payload.fieldName,
      changed: true,
    }
  );

  // insert a new row
  if (action.payload.rowIndex === newState.get('rows').size - 1) {
    newState = newState.set(
      'rows',
      newState.get('rows').push(fromJS(newRow(state)))
    );
  }

  if (action.payload.fieldName === 'wage_type') {
    const isAnnualWage = action.payload.value === 1;

    if (isAnnualWage) {
      // allow to choose only one role per employee

      let currentRole =
        newState.getIn(['rows', action.payload.rowIndex, 'role', 'value']) ||
        [];

      if (typeof currentRole === 'string') {
        currentRole = [currentRole];
      }

      newState = newState.mergeIn(['rows', action.payload.rowIndex, 'role'], {
        value: currentRole.slice(0, 1),
        meta: { single: true },
      });
    } else {
      // make role field - multiselect
      newState = newState.deleteIn([
        'rows',
        action.payload.rowIndex,
        'role',
        'meta',
        'single',
      ]);
    }

    // disable or enable role wages based on wage type
    // if annual - disable
    // if hourly - enable
    const attr = { disabled: isAnnualWage };
    const valueAttr = isAnnualWage ? { value: null } : {};

    times(state.get('wagesCount') - 1, i => {
      newState = newState.mergeIn(
        ['rows', action.payload.rowIndex, `wage_rate_${i + 2}`],
        { ...attr, ...valueAttr, error: null }
      );
      newState = newState.mergeIn(
        ['rows', action.payload.rowIndex, `role_${i + 2}`],
        { ...attr, ...valueAttr }
      );
    });
  } else if (action.payload.columnName === 'role') {
    newState = validateWageRoles(newState, action);
  }

  return newState;
};

const setDefaults = (state, action) => {
  const newState = state.merge({
    defaultLocationId: action.payload.locationId,
    pinLength: action.payload.pinLength,
  });

  const rows = times(action.payload.count, () => newRow(newState));

  return newState.set('rows', fromJS(rows));
};

export const updateRows = (state, action) => {
  const { rows, columnNames } = action.payload;
  let { rowIndex } = action.payload;

  const columns = columnNames.slice(
    findIndex(
      columnNames,
      column => column.name === action.payload.column.name
    ),
    columnNames.length
  );

  // we shouldn't update rows BEFORE the row where we pasted the info
  const finalRows = Array(rowIndex).fill({});

  const lastIndex = state.get('rows').size;

  rows.forEach(row => {
    const changes = rowIndex < lastIndex ? {} : newRow(state);

    columns.forEach((column, index) => {
      const columnName = column.name;
      const fieldName = getFieldName(column);
      const columnData = COLUMNS[columnName];
      const value = row[index];
      if (columnData.paste && value) {
        changes[fieldName] = { value, changed: true };

        const validationAttrs =
          fieldName === 'pin' ? { pinLength: state.get('pinLength') } : {};

        if (columnData.validations) {
          // eslint-disable-next-line
          for (const validation of columnData.validations) {
            const validationError = validation(value, validationAttrs);
            if (validationError) {
              changes[fieldName].error = validationError;
            } else {
              delete changes[fieldName].error;
            }
          }
        }
      }
    });

    finalRows.push(changes);
    rowIndex += 1;
  });

  return state.mergeDeepIn(['rows'], finalRows);
};

const mergeRows = (state, action) =>
  state.mergeDeepIn(['rows'], action.payload);

const bulkAddSlice = createSlice({
  name: 'bulkAdd',

  initialState: fromJS({
    rows: [],
    wagesCount: 1,
  }),

  reducers: {
    validateField,
    setValue,
    setDefaults,
    setWagesCount: (state, action) => state.set('wagesCount', action.payload),
    updateRows,
    mergeRows,
  },
});

export const bulkAddReducer = bulkAddSlice.reducer;
export const bulkAddActions = bulkAddSlice.actions;
