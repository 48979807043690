import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import List from 'fe-design-base/molecules/List/List';

import { SuccessProps } from 'features/monetization/TipManagerCheckoutDrawer/views/types';

import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx, useTrackUxOnMount } from 'util/uxEvents';

import success_src from './confirmation_success.png';
import { EffectiveDateSelector } from './EffectiveDateSelector';
import { effectiveDateSelectorOptions, successData } from './util';

const Success: React.FC<SuccessProps> = ({
  currentPayPeriodStartDate,
  previousPayPeriodStartDate,
  closeDrawer,
  updateTipPolicy,
  setSelectedEffectiveDate,
}) => {
  const trackUx = useTrackUx();

  useTrackUxOnMount(EVENT_ACTIONS.SIDE_DRAWER_VIEWED, TRACK_ACTION_TYPES.VIEW, {
    stage: 'checkout_completed',
  });

  const handlePageRedirectToTimesheets = useCallback(() => {
    window.location.href = '/timesheets/pay_period_review';
  }, []);

  const handleOnClickPrimary = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      button_text: toI18n('checkout_drawer.success.cta.primary'),
      element: 'primary_cta',
      stage: 'checkout_completed',
    });
    closeDrawer?.();
    updateTipPolicy();
  }, [closeDrawer, trackUx, updateTipPolicy]);

  const handleOnClickSecondary = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      button_text: toI18n('checkout_drawer.success.cta.secondary'),
      element: 'secondary_cta',
      stage: 'checkout_completed',
    });
    closeDrawer?.();
    handlePageRedirectToTimesheets();
    updateTipPolicy();
  }, [closeDrawer, handlePageRedirectToTimesheets, trackUx, updateTipPolicy]);

  return (
    <>
      <Box hcenter>
        <img src={success_src} style={{ width: 164, height: 164 }} />
      </Box>
      <Box gap={16} column mt={16}>
        <Text variant="heading2" i18n="checkout_drawer.success.title" />
        <Text variant="body" i18n="checkout_drawer.success.subtitle" asHTML />
        <EffectiveDateSelector
          selectorOptions={effectiveDateSelectorOptions(
            currentPayPeriodStartDate,
            previousPayPeriodStartDate
          )}
          setSelectedValue={setSelectedEffectiveDate}
        />
        <List
          title={toI18n('checkout_drawer.success.list_title')}
          size="small"
          data={successData}
        />
      </Box>
      <Box gap={16} mt={56} column>
        <Button variant="primary" onClick={handleOnClickPrimary}>
          {toI18n('checkout_drawer.success.cta.primary')}
        </Button>
        <Button variant="secondary" onClick={handleOnClickSecondary}>
          {toI18n('checkout_drawer.success.cta.secondary')}
        </Button>
      </Box>
    </>
  );
};

export default Success;
