import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON, postJSON } from 'api/fetch';

import { slugForPlanName } from '../util';

import { MODULE_NAME } from './constants';

export const fetchDowngradeWorkFlowData = createAsyncThunk(
  `${MODULE_NAME}/fetchDowngradeWorkFlowData`,
  plan =>
    fetchJSON(`/monetization/downgrade_workflow.json?downgrade_to_plan=${plan}`)
);

export const acceptDiscount = createAsyncThunk(
  `${MODULE_NAME}/acceptDiscount`,
  downgrade_to_plan =>
    postJSON(`/monetization/accept_offer`, {
      downgrade_to_plan,
    })
);

const slice = createSlice({
  name: MODULE_NAME,

  initialState: {
    reasons: [],
    amountSaved: '',
    freeUntilDate: '',
    isFulfilled: false,
    currentPlanName: '',
    downgradeToPlanName: '',
  },

  extraReducers: builder => {
    builder.addCase(fetchDowngradeWorkFlowData.pending, state => {
      state.isFulfilled = false;
    });

    builder.addCase(
      fetchDowngradeWorkFlowData.fulfilled,
      (
        state,
        {
          payload: {
            reasons,
            amount_saved,
            downgrade_type,
            free_until_date,
            is_multilocation,
            current_plan_name,
            other_discount_active,
            downgrade_to_plan_name,
            days_to_expire_accepted_offer,
            has_scheduled_downgrade_to_basic,
          },
        }
      ) => ({
        reasons,
        isFulfilled: true,
        amountSaved: amount_saved,
        downgradeType: downgrade_type,
        freeUntilDate: free_until_date,
        isMultilocation: is_multilocation,
        otherDiscountActive: other_discount_active,
        downgradeToPlanName: downgrade_to_plan_name,
        currentPlanName: slugForPlanName(current_plan_name),
        daysToExpireAcceptedOffer: days_to_expire_accepted_offer,
        hasScheduledDowngradeToBasic: has_scheduled_downgrade_to_basic,
      })
    );
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
