import React, { Fragment } from 'react';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Box from 'fe-design-base/atoms/Box';
import { IconName } from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import Divider from 'fe-design-base/molecules/Divider';

import ListItem from './ListItem';

export const LIST_VARIANTS = [
  'bullet',
  'check',
  'checklist',
  'number',
  'icon',
  'illustration',
] as const;
export type Variant = (typeof LIST_VARIANTS)[number];

export type DataType = {
  primary: string;
  secondary?: React.ReactNode | string;
  iconName?: IconName;
  illustration?: React.ReactNode;
  success?: boolean;
  loading?: boolean;
}[];

export interface ListProps {
  data: DataType;
  size?: 'medium' | 'small';
  title?: string;
  variant?: Variant;
  quiet?: boolean;
  dividers?: boolean;
  asHTML?: boolean;
  gapOverride?: string;
}

const List = ({
  asHTML,
  cx,
  cxEl,
  data,
  dividers,
  size = 'medium',
  title,
  variant = 'bullet',
  quiet = false,
  gapOverride,
}: ListProps & CxProps) => {
  const isMedium = size === 'medium';
  const listGap = gapOverride || (isMedium ? '20px' : '16px');

  return (
    <Box className={cx()}>
      {title && (
        <Box mb={isMedium ? 20 : 16} className={cxEl('title')}>
          <Text variant={isMedium ? 'heading2' : 'heading4'} color="mono900">
            {title}
          </Text>
        </Box>
      )}
      <Box
        className={cxEl('list-container')}
        column
        gap={listGap}
        component="ul"
      >
        {data.map(
          (
            { primary, secondary, iconName, illustration, success, loading },
            index
          ) => {
            const isLast = index === data.length - 1;
            return (
              <Fragment key={`${primary}-${index}`}>
                <ListItem
                  primary={primary}
                  secondary={secondary}
                  iconName={iconName}
                  illustration={illustration}
                  index={index}
                  variant={variant}
                  quiet={quiet}
                  isMedium={isMedium}
                  asHTML={asHTML}
                  success={success}
                  loading={loading}
                />
                {dividers && !isLast && <Divider color="mono400" spacing={0} />}
              </Fragment>
            );
          }
        )}
      </Box>
    </Box>
  );
};

export default withCx<ListProps>('FDBList')(List);
