import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON, postFormData, postJSON, putJSON } from 'api/fetch';

import { createOneTimeEvent } from 'actions/session';

import * as flashNotice from 'util/flashNotice';

export const fetchInitialData = createAsyncThunk(
  'documents/fetchInitialData',
  () => fetchJSON(`/documents.json`)
);

export const uploadDocument = createAsyncThunk(
  'documents/uploadDocument',
  params => {
    const { userId, ...formData } = params;
    return postFormData(`/users/${userId}/documents`, formData);
  }
);

export const createSeenLandingPageEvent = createAsyncThunk(
  'documents/createSeenLandingPageEvent',
  (level, { dispatch }) =>
    dispatch(createOneTimeEvent('first_time_documents_page', level))
);

export const sendPacket = createAsyncThunk(
  'documents/sendPacket',
  ({ userId, chosenTemplate }) =>
    postJSON('/send_packet', {
      user_id: userId,
      chosen_template: chosenTemplate,
    })
);

export const sendSingleDocumentPacket = createAsyncThunk(
  'documents/sendSingleDocumentPacket',
  ({ userId, customDocumentId, category }, { rejectWithValue }) =>
    postJSON('/documents/send_single_document_packet', {
      user_id: userId,
      custom_document_id: customDocumentId,
      category,
    }).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const resendSingleDocumentPacket = createAsyncThunk(
  'documents/resendSingleDocumentPacket',
  ({ userId, customDocumentId, category }, { rejectWithValue }) =>
    putJSON('/documents/resend_single_document_packet', {
      user_id: userId,
      custom_document_id: customDocumentId,
      category,
    }).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const updateEmployee = createAsyncThunk(
  'documents/updateEmployee',
  (
    { userId, employeeData, singleDocumentPacket, category, customDocumentId },
    { rejectWithValue }
  ) =>
    putJSON('/documents/update_employee', {
      user_id: userId,
      user: employeeData,
      single_document_packet: singleDocumentPacket,
      category,
      custom_document_id: customDocumentId,
    }).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const archiveCustomDocument = createAsyncThunk(
  'newHire/archiveCustomeDocument',
  ({ id }) => postJSON(`/employee_onboarding/custom_documents/${id}/archive`)
);

const documents = createSlice({
  name: 'documents',

  initialState: {
    hasSeenLandingPage: true,
    users: [],
    customDocuments: [],
    showStateFormColumn: true,
    isFetching: false,
    showSendPacketButton: false,
    missingContactInfoModalErrors: null,
    editingCustomDocument: {},
    packetTemplateRequiredCustomDocumentIds: [],
    locationHasPacketTemplate: false,
  },

  reducers: {
    clearMissingContactInfoModalErrors: state => {
      state.missingContactInfoModalErrors = null;
    },

    setEditingCustomDocument: (state, action) => {
      const customDocumentId = action.payload;
      state.editingCustomDocument = state.customDocuments.find(
        customDocument => customDocument.id === customDocumentId
      );
    },

    resetEditingCustomDocument: state => {
      state.editingCustomDocument = {};
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchInitialData.fulfilled, (state, action) => {
      state.hasSeenLandingPage = action.payload.hasSeenLandingPage;
      state.users = action.payload.users;
      state.customDocuments = action.payload.custom_documents;
      state.showStateFormColumn = action.payload.showStateFormColumn;
      state.isFetching = false;
      state.showSendPacketButton = action.payload.showSendPacketButton;
      state.packetTemplateRequiredCustomDocumentIds =
        action.payload.packetRequiredCustomDocumentIds;
      state.locationHasPacketTemplate =
        action.payload.locationHasPacketTemplate;
    });

    builder.addCase(fetchInitialData.rejected, state => {
      state.isFetching = false;
    });

    builder.addCase(fetchInitialData.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(createSeenLandingPageEvent.fulfilled, state => {
      state.hasSeenLandingPage = true;
    });

    builder.addCase(uploadDocument.fulfilled, (state, action) => {
      const data = {
        id: action.payload.id,
        uploaded_date: action.payload.documents_uploaded_date,
        uploaded_by: action.payload.documents_uploaded_by,
        url: action.payload.url,
      };

      const userIdx = state.users.findIndex(
        u => u.id === action.payload.user_id
      );

      if (userIdx === -1) {
        return;
      }

      if (action.payload.custom_document_id) {
        const customDocumentIdx = state.users[
          userIdx
        ].custom_documents.findIndex(
          c => c.custom_document_id === action.payload.custom_document_id
        );

        if (customDocumentIdx === -1) {
          return;
        }

        state.users[userIdx].custom_documents[customDocumentIdx].data = data;
        return;
      }

      state.users[userIdx][action.payload.category] = data;
    });

    builder.addCase(sendPacket.fulfilled, (state, action) => {
      if (action.payload.users) {
        state.users = action.payload.users;
      }
    });

    builder.addCase(sendSingleDocumentPacket.fulfilled, (state, action) => {
      if (action.payload.users) {
        state.users = action.payload.users;
      }
    });

    builder.addCase(sendSingleDocumentPacket.rejected, (state, action) => {
      flashNotice.error(action.payload.errors[0]);
    });

    builder.addCase(resendSingleDocumentPacket.fulfilled, (state, action) => {
      if (action.payload.users) {
        state.users = action.payload.users;
      }
    });

    builder.addCase(resendSingleDocumentPacket.rejected, (state, action) => {
      flashNotice.error(action.payload.errors[0]);
    });

    builder.addCase(updateEmployee.pending, state => {
      state.missingContactInfoModalErrors = null;
    });

    builder.addCase(updateEmployee.rejected, (state, action) => {
      state.missingContactInfoModalErrors = action.payload.errors;
    });

    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      state.missingContactInfoModalErrors = null;
      state.users = action.payload.users;
    });

    builder.addCase(archiveCustomDocument.fulfilled, (state, action) => {
      state.customDocuments = state.customDocuments.filter(
        customDocument => customDocument.id !== action.payload.id
      );
      state.editingCustomDocument = {};
    });
  },
});

export const documentsReducer = documents.reducer;
export const documentsActions = documents.actions;
