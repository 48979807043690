import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';

import { df, momentInZone } from 'util/dateTime';

import { SLICE_NAME } from './constants';
import { SalesAndLaborData } from './types';

export const fetchLocationSalesAndLabor = createAsyncThunk(
  'companyDashboard/fetchLocationSalesAndLabor',
  async (locationId: number) => {
    const timeNow = momentInZone();
    const startDate = timeNow.clone().subtract(1, 'day').startOf('day');
    const endDate = timeNow;

    const queryParams = new URLSearchParams({
      step: 'day',
      start_date: startDate.format(df('parsable_date')), // MM/DD/YYYY format
      end_date: endDate.format(df('parsable_date')),
    }).toString();

    const data = await fetchJSON(
      `/dashboard/company/sales_and_labor/${locationId}.json?${queryParams}`
    );

    return data;
  }
);

interface CompanyDashboardState {
  salesAndLabor: Record<string, SalesAndLaborData>;
  loading: Record<string, boolean>;
  errors: Record<string, string | null>;
}

const initialState: CompanyDashboardState = {
  salesAndLabor: {},
  loading: {},
  errors: {},
};

const companyDashboardSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchLocationSalesAndLabor.pending, (state, action) => {
        const locationId = action.meta.arg;
        state.loading[locationId] = true;
        state.errors[locationId] = null;
      })
      .addCase(fetchLocationSalesAndLabor.fulfilled, (state, action) => {
        const locationId = action.meta.arg;
        const data = action.payload;
        state.loading[locationId] = false;
        state.salesAndLabor[locationId] = data;
      })
      .addCase(fetchLocationSalesAndLabor.rejected, (state, action) => {
        const locationId = action.meta.arg;
        state.loading[locationId] = false;
        state.errors[locationId] = action.error.message || 'An error occurred';
      });
  },
});

export const { reducer } = companyDashboardSlice;
