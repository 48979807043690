import { createResource } from 'fe-core/Resources';

const ScheduleBuilderSession = createResource({
  name: 'session',
  path: '/schedule_builder/session',
});

ScheduleBuilderSession.selectCurrentUserId = state => {
  const session = ScheduleBuilderSession.selectEntity(state);

  return session && session.relationships.currentUser.data.id;
};

ScheduleBuilderSession.selectCurrentLocationId = state => {
  const session = ScheduleBuilderSession.selectEntity(state);

  return session && session.relationships.currentLocation.data.id;
};

ScheduleBuilderSession.selectIsEmployee = state => {
  const session = ScheduleBuilderSession.selectEntity(state);

  return session && session.attributes.employeeLevelExperience;
};

ScheduleBuilderSession.selectIsManager = state => {
  const session = ScheduleBuilderSession.selectEntity(state);

  return session && !session.attributes.employeeLevelExperience;
};

ScheduleBuilderSession.selectCanManageEmployees = state => {
  const session = ScheduleBuilderSession.selectEntity(state);

  return session && session.attributes.canManageEmployees;
};

ScheduleBuilderSession.selectColorMappingVersion = state => {
  const session = ScheduleBuilderSession.selectEntity(state);

  return session && session.attributes.colorMappingVersion;
};

ScheduleBuilderSession.selectUserReactOptIn = state => {
  const session = ScheduleBuilderSession.selectEntity(state);

  return session && session.attributes.reactOptIn;
};

ScheduleBuilderSession.selectUserReactOptOut = state => {
  const session = ScheduleBuilderSession.selectEntity(state);

  return session && session.attributes.reactOptOut;
};

ScheduleBuilderSession.selectNumberOfLocations = state => {
  const session = ScheduleBuilderSession.selectEntity(state);

  return session && session.relationships.locations.data.length;
};

export default ScheduleBuilderSession;
