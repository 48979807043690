import { createSlice } from '@reduxjs/toolkit';

import { MODULE_NAME } from './constants';

const slice = createSlice({
  name: MODULE_NAME,

  initialState: {
    shouldShowDialog: false,
  },

  reducers: {
    showNextIntegrationDialog: state => {
      state.shouldShowDialog = true;
    },

    closeNextIntegrationDialog: state => {
      state.shouldShowDialog = false;
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
