import './CardContainer.scss';

import React from 'react';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Box from 'fe-design-base/atoms/Box';
import { Props } from 'fe-design-base/atoms/Box/interface';

const CardContainer = ({
  cx,
  children,
  selected,
  disabled,
  isAI,
  isAIAnimating,
  ...props
}: Props<{
  selected: boolean;
  disabled: boolean;
  isAI?: boolean;
  isAIAnimating?: boolean;
}> &
  CxProps) => {
  const cardComponent = (
    <Box
      className={cx({ selected, disabled, isAI, isAIAnimating })}
      tabIndex={0}
      {...props}
      bradiusm
      shadow={!isAI && !isAIAnimating && 'z1'}
      aria-disabled={disabled}
    >
      {children}
    </Box>
  );

  if (isAI || isAIAnimating) {
    return (
      <Box isAI={isAI} isAIAnimating={isAIAnimating} bradiusm w="fit-content">
        {cardComponent}
      </Box>
    );
  }

  return cardComponent;
};

export default withCx<Props>('FDBCardContainer')(CardContainer);
