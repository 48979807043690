import {
  RANGE_TYPES,
  VIEW_TYPES,
} from 'features/scheduleBuilder/ScheduleBuilderView/constants';

import { formatDate } from 'util/dateTime';
import { browserHistory } from 'util/router';

import { pageUrl } from '../utils/urlParamsToReduxProps';

// Navigates to the week view where the Open shift exists
// and opens Open shift modal (openApproveClaimModal)
export const navigateToOpenShift = (date, openShiftId) => {
  const dateFormatted = formatDate(date, 'parsable_reversed');
  const urlParams = {
    viewType: VIEW_TYPES.employee,
    rangeType: RANGE_TYPES.week,
    rangeStart: dateFormatted,
  };

  browserHistory.replace(`${pageUrl(urlParams)}?openShiftId=${openShiftId}`);
};
