import {
  ChangeEvent,
  forwardRef,
  MouseEvent,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import MUIRadio from '@mui/material/Radio';
import withCx, { CxProps } from 'fe-core/util/withCx';
import { BaseInputProps, InputChangeEvent } from 'fe-design-base/baseTypes';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

export interface RadioButtonProps
  extends Omit<BaseInputProps, 'onFocus' | 'onBlur' | 'onChange'> {
  checked?: boolean;
  uxElement?: string;
  label?: string | ReactNode;
  onChange: (event: InputChangeEvent) => void;
}

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps & CxProps>(
  // Destructured cxEl here to prevent error when included in props
  (
    {
      cx,
      cxEl,
      disabled,
      label,
      name,
      onChange,
      uxElement,
      dataTestId,
      ...props
    },
    ref
  ) => {
    const trackUx = useTrackUx(
      useMemo(
        () => ({
          element: uxElement,
          field: label && typeof label === 'string' ? label : name,
        }),
        [label, name, uxElement]
      ) as any
    );
    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
          e.preventDefault();
          return;
        }

        if (uxElement) {
          trackUx(
            EVENT_ACTIONS.RADIO_BUTTON_CLICKED,
            TRACK_ACTION_TYPES.CLICK,
            {
              checked: e.target.checked,
              value: e.target.value,
            }
          );
          onChange(e);
        }
      },
      [trackUx, uxElement, onChange, disabled]
    );

    //  Prevention from clicking on a disabled RadioButtonField or  when in a RadioButtonGroup
    const handleClick = useCallback(
      (e: MouseEvent<HTMLElement>) => {
        if (disabled) {
          e.preventDefault();
        }
      },
      [disabled]
    );

    return (
      <MUIRadio
        className={cx()}
        aria-disabled={disabled}
        name={name}
        disableTouchRipple
        disableRipple
        inputRef={ref}
        onClick={handleClick}
        onChange={handleChange}
        {...props}
        inputProps={
          {
            'data-testid': dataTestId,
            'aria-label': name,
            disabled,
          } as any
        }
      />
    );
  }
);

export default withCx<RadioButtonProps>('FDBRadioButton')(RadioButton);
