import './NextIntegrationDialog.scss';

import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { postJSON } from 'api/fetch';
import Box from 'fe-design-base/atoms/Box';
import Dialog from 'fe-design-base/organisms/Dialog';
import { useScript } from 'hooks/useScript';

import { actions as monetizationActions } from 'features/monetization/NextIntegrationDialog/slice';

import { cxHelpers } from 'util/className';
import { sendIncrementMetric } from 'util/dataDogMetrics';
import { error as flashError } from 'util/flashNotice';
const { cx } = cxHelpers('NextIntegrationDialog');

interface NextIntegrationDialogProps {
  closeNextIntegrationDialog: () => void;
}

interface QuoteData {
  env: string;
  timestamp: string;
  signature: boolean;
  prefill_token: string;
  affiliate_name: string;
  prefill_request: string;
  partner_request_id: string;
}

export const NextIntegrationDialog = ({
  closeNextIntegrationDialog,
}: NextIntegrationDialogProps) => {
  const [quoteData, setQuoteData] = useState<QuoteData | null>(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { status, scriptElement } = useScript(
    window.Homebase.env === 'production'
      ? 'https://app.nextinsurance.com/ni-sdk.bundle.js'
      : 'https://uat.nextinsurance.com/ni-sdk.bundle.js'
  );

  const handleClickOnClose = useCallback(() => {
    if (scriptElement) (scriptElement as HTMLScriptElement).remove();
    closeNextIntegrationDialog();
  }, [closeNextIntegrationDialog, scriptElement]);

  useEffect(() => {
    postJSON('/monetization/next/affiliate_data')
      .then(({ data }) => {
        setQuoteData(data);
        sendIncrementMetric({
          metricName: 'growth.next_insurance.widget_loading.web',
          tags: ['status:success'],
        });
      })
      .catch(e => {
        setErrorMessage(e.message);
        sendIncrementMetric({
          metricName: 'growth.next_insurance.widget_loading.web',
          tags: ['status:failure'],
        });
      });
  }, []);

  useEffect(() => {
    if (errorMessage) {
      flashError(errorMessage);
      handleClickOnClose();
    }
  }, [errorMessage, handleClickOnClose]);

  if (!quoteData) return null;

  return (
    <Dialog
      open
      noPadding
      disableBackdropClick
      onClose={handleClickOnClose}
      uxElement="next-integration-dialog"
    >
      {() => (
        <Box
          h={645}
          w={1000}
          bgcolor="mono0"
          className={cx()}
          overflow="hidden"
        >
          {status === 'ready' && quoteData.signature && (
            <div
              data-embed="true"
              data-env={quoteData.env}
              className="next-insurance-button"
              data-testid="next-insurance-button"
              data-signature={quoteData.signature}
              data-timestamp={quoteData.timestamp}
              data-affiliate-name={quoteData.affiliate_name}
              data-partner-request-id={quoteData.partner_request_id}
              // If prefill_token exists, it means the quote has not been created on NEXT yet
              {...(quoteData.prefill_token
                ? {
                    'data-pft': quoteData.prefill_token,
                    'data-pfr': quoteData.prefill_request,
                  }
                : {})}
            />
          )}
        </Box>
      )}
    </Dialog>
  );
};

export default connect(null, {
  closeNextIntegrationDialog: monetizationActions.closeNextIntegrationDialog,
})(NextIntegrationDialog);
