import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON, postFormData, postJSON, putJSON } from 'api/fetch';

export const fetchPacketTemplate = createAsyncThunk(
  'newHire/fetchPacketTemplate',
  () => fetchJSON(`/new_hire_onboarding.json`)
);

export const createCustomDocument = createAsyncThunk(
  'newHire/createCustomDocument',
  params => postFormData('/employee_onboarding/custom_documents', params)
);

export const updateCustomDocument = createAsyncThunk(
  'newHire/updateCustomDocument',
  ({ id, params }) =>
    putJSON(`/employee_onboarding/custom_documents/${id}`, params)
);

export const fetchCustomDocument = createAsyncThunk(
  'newHire/fetchCustomDocument',
  ({ id }) =>
    fetchJSON(
      `/employee_onboarding/custom_documents/${id}/custom_document_editor.json`
    )
);

export const analyzeFile = createAsyncThunk('newHire/analyzeFile', params =>
  postFormData('/employee_onboarding/analyze_file', params)
);

export const savePacketTemplate = createAsyncThunk(
  'newHire/savePacketTemplate',
  (payload, { rejectWithValue }) => {
    const { currentLocationId, ...locationPayload } = payload;
    return putJSON(`/locations/${currentLocationId}`, locationPayload).catch(
      err => err.response.json().then(body => rejectWithValue(body))
    );
  }
);

export const activateHireOnboarding = createAsyncThunk('newHire/activate', () =>
  postJSON('new_hire_onboarding/activate')
);

const setCustomDocumentsCheckboxes = (requiredIds, rawCustomDocuments) =>
  rawCustomDocuments.reduce((accumulator, current) => {
    accumulator[current.id] = requiredIds.includes(current.id);
    return accumulator;
  }, {});

const preparePacketAttributes = (rawPacketTemplate, rawCustomDocuments) => ({
  id: rawPacketTemplate.id,
  requireW4: rawPacketTemplate.require_w4,
  requireI9: rawPacketTemplate.require_i9,
  requireState: rawPacketTemplate.require_state_withholding,
  requireW9: rawPacketTemplate.require_w9,
  requireDirectDeposit: rawPacketTemplate.require_direct_deposit,
  showStateForm: rawPacketTemplate.state_has_withholding_form,
  customDocumentsCheckboxes: setCustomDocumentsCheckboxes(
    rawPacketTemplate.required_custom_documents_ids,
    rawCustomDocuments
  ),
});

const reducePacketTemplate = (state, action) => {
  state.isFetching = false;
  if (action.payload.newHireOnboardingActivated) {
    state.employeePacketTemplate = preparePacketAttributes(
      action.payload.employee_packet_template,
      action.payload.custom_documents
    );
    state.contractorPacketTemplate = preparePacketAttributes(
      action.payload.contractor_packet_template,
      action.payload.custom_documents
    );

    state.customDocuments = action.payload.custom_documents;
    state.employerDetails.ein = action.payload.ein;
    state.employerDetails.address_1 = action.payload.location.address_1;
    state.employerDetails.address_2 = action.payload.location.address_2;
    state.employerDetails.city = action.payload.location.city;
    state.employerDetails.state = action.payload.location.state;
    state.employerDetails.zip = action.payload.location.zip;
    state.employerDetails.name = action.payload.location.name;
  }
  state.newHireOnboardingActivated = action.payload.newHireOnboardingActivated;
};

const hirePacketConfiguration = createSlice({
  name: 'hirePacketConfiguration',

  initialState: {
    employeePacketTemplate: {
      id: null,
      requireW4: true,
      requireI9: true,
      requireState: true,
      requireW9: false,
      requireDirectDeposit: true,
      showStateForm: true,
      customDocumentsCheckboxes: {},
    },
    contractorPacketTemplate: {
      id: null,
      requireW4: false,
      requireI9: false,
      requireState: false,
      requireW9: true,
      requireDirectDeposit: true,
      showStateForm: false,
      customDocumentsCheckboxes: {},
    },
    employerDetails: {
      ein: null,
      name: null,
      address_1: null,
      address_2: null,
      city: null,
      state: null,
      zip: null,
    },
    customDocuments: [],
    errors: [],
    saveDisabled: true,
    newHireOnboardingActivated: false,
    isFetching: false,
    pdfCustomDocumentPageInfo: {},
    employeeCustomDocument: {},
  },

  reducers: {
    updateValue: (state, action) => {
      state.employerDetails[action.payload.key] = action.payload.value;
      state.saveDisabled = false;
      state.errors = [];
    },

    updatePacketTemplateValue: (state, action) => {
      if (
        state.contractorPacketTemplate.id === action.payload.packetTemplateId
      ) {
        state.contractorPacketTemplate[action.payload.key] =
          action.payload.value;
      } else {
        state.employeePacketTemplate[action.payload.key] = action.payload.value;
      }
      state.saveDisabled = false;
      state.errors = [];
    },

    updateCustomDocumentsCheckboxes: (state, action) => {
      if (
        state.contractorPacketTemplate.id === action.payload.packetTemplateId
      ) {
        state.contractorPacketTemplate.customDocumentsCheckboxes[
          action.payload.key
        ] = action.payload.value;
      } else {
        state.employeePacketTemplate.customDocumentsCheckboxes[
          action.payload.key
        ] = action.payload.value;
      }
      state.saveDisabled = false;
      state.errors = [];
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchPacketTemplate.rejected, state => {
      state.isFetching = false;
    });

    builder.addCase(fetchPacketTemplate.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(fetchPacketTemplate.fulfilled, reducePacketTemplate);

    builder.addCase(activateHireOnboarding.rejected, state => {
      state.isFetching = false;
    });

    builder.addCase(activateHireOnboarding.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(activateHireOnboarding.fulfilled, reducePacketTemplate);

    builder.addCase(savePacketTemplate.rejected, (state, action) => {
      state.errors = action.payload.errors;
      state.saveDisabled = false;
    });

    builder.addCase(savePacketTemplate.fulfilled, (state, action) => {
      state.employerDetails.ein = action.payload.ein;
      state.employerDetails.address_1 = action.payload.address_1;
      state.employerDetails.address_2 = action.payload.address_2;
      state.employerDetails.city = action.payload.city;
      state.employerDetails.state = action.payload.state;
      state.employerDetails.zip = action.payload.zip;
      state.employerDetails.name = action.payload.name;
      state.errors = [];
      state.saveDisabled = true;
    });

    builder.addCase(analyzeFile.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(analyzeFile.rejected, state => {
      state.isFetching = false;
    });

    builder.addCase(analyzeFile.fulfilled, (state, action) => {
      state.isFetching = false;
      state.pdfCustomDocumentPageInfo = action.payload;
    });

    builder.addCase(createCustomDocument.fulfilled, (state, action) => {
      state.customDocuments.push(action.payload);
      state.employeePacketTemplate.customDocumentsCheckboxes[
        action.payload.id
      ] = true;
      state.contractorPacketTemplate.customDocumentsCheckboxes[
        action.payload.id
      ] = true;
    });

    builder.addCase(fetchCustomDocument.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(fetchCustomDocument.rejected, state => {
      state.isFetching = false;
    });

    builder.addCase(fetchCustomDocument.fulfilled, (state, action) => {
      state.isFetching = false;
      state.employeeCustomDocument = action.payload;
    });
  },
});

export const hirePacketConfigurationReducer = hirePacketConfiguration.reducer;
export const hirePacketConfigurationActions = hirePacketConfiguration.actions;
