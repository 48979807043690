import { formatDateLongDate } from 'util/dateTime';
import { toI18n } from 'util/i18n';

export const successData = [
  {
    primary: toI18n('checkout_drawer.success.point_1'),
  },
  {
    primary: toI18n('checkout_drawer.success.point_2'),
  },
  {
    primary: toI18n('checkout_drawer.success.point_3'),
  },
];

export const effectiveDateSelectorInitialValues = {
  effectiveDate: 'current',
};

export const effectiveDateSelectorOptions = (
  currentPayPeriodStartDate: string,
  previousPayPeriodStartDate: string
) => [
  {
    key: 'current',
    label: toI18n('checkout_drawer.success.effective_date_form.current'),
    value: 'current',
    helperText: formatDateLongDate(currentPayPeriodStartDate),
  },
  {
    key: 'previous',
    label: toI18n('checkout_drawer.success.effective_date_form.previous'),
    value: 'previous',
    helperText: formatDateLongDate(previousPayPeriodStartDate),
  },
];
