import { isDateRangesOverlapping } from 'util/dateTime';

export const hasShiftConflict = ({ shifts, startAt, endAt, jobId }) => {
  if (!shifts.length) return false;

  return shifts.some(shift => {
    if (
      shift.relationships.owner.data.type === 'job' &&
      shift.relationships.owner.data.id === jobId
    ) {
      return isDateRangesOverlapping(
        [startAt, endAt],
        [shift.attributes.startAt, shift.attributes.endAt]
      );
    }
    return false;
  });
};

export const sortByName = (a, b) => {
  const nameA = a.attributes.name.toLowerCase();
  const nameB = b.attributes.name.toLowerCase();

  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
};

export const getUser = (userIdEmployeeNameMap, jobIdUserIdMap, jobId) =>
  userIdEmployeeNameMap[jobIdUserIdMap[jobId]];

export const sortByAttribute = (
  primaryAttributeA,
  primaryAttributeB,
  secondaryAttributeA,
  secondaryAttributeB
) => {
  if (primaryAttributeA < primaryAttributeB) return -1;
  if (primaryAttributeA > primaryAttributeB) return 1;

  if (primaryAttributeA === primaryAttributeB) {
    if (secondaryAttributeA < secondaryAttributeB) return -1;
    if (secondaryAttributeA > secondaryAttributeB) return 1;
  }
  return 0;
};
