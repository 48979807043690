import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { putJSON } from 'api/fetch';

export const removeLocations = createAsyncThunk(
  'enforcement/removeLocations',
  ({ locations }, { rejectWithValue }) =>
    putJSON('/directory/locations/archive', {
      location_ids: locations.map(l => l.id),
    }).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

const enforcementSlice = createSlice({
  name: 'enforcement',

  initialState: {
    removeLocationsError: null,
    removeLocationsPending: false,
    removedLocations: [],
    selectedTierName: null,
    selectedLocationsById: {},
    step: 1,
  },

  reducers: {
    setSelectedTierName: (state, { payload }) => {
      state.selectedTierName = payload;
    },

    setSelectedLocationsById: (state, { payload }) => {
      state.selectedLocationsById[payload.locationId] = payload.checked;
    },

    setStep: (state, { payload }) => {
      state.step = payload.value;
    },
  },

  extraReducers: builder => {
    builder.addCase(removeLocations.pending, state => {
      state.removeLocationsPending = true;
      state.removeLocationsError = null;
    });

    builder.addCase(removeLocations.rejected, (state, action) => {
      state.removeLocationsPending = false;
      state.removeLocationsError = action.payload.errored_locations;
    });

    builder.addCase(removeLocations.fulfilled, (state, action) => {
      state.removeLocationsPending = false;
      state.removeLocationsError = null;
      state.removedLocations = action.payload.successful_locations;
    });
  },
});

export const { actions, reducer } = enforcementSlice;
