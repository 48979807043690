import { browserHistory } from 'util/router';

import {
  selectCurrentDateRangeEnd,
  selectCurrentDateRangeStart,
  selectRangeType,
  selectViewType,
} from '../selectors';
import { pageUrl } from '../utils/urlParamsToReduxProps';

// an action that allows you to change schedule builder filter(s)
// in URL without specifying all other filters,
// they will stay as is. So if you need to change only viewType -
// just pass viewType inside props to this method.
// No need to pass rangeStart, rangeEnd and rangeType
export const navigateToSchedule = (state, props) => {
  const params = {
    viewType: selectViewType(state),
    rangeType: selectRangeType(state),
    rangeStart: selectCurrentDateRangeStart(state),
    rangeEnd: selectCurrentDateRangeEnd(state),
    ...props,
  };

  browserHistory.push(pageUrl(params));
};

export const navigateToScheduleAction = props => (dispatch, getState) => {
  navigateToSchedule(getState(), props);
};
