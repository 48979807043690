import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';
import { fromJS } from 'immutable';
import queryString from 'query-string';

import * as thunks from './thunks';

/**
 * @param {Object} [options] - contains query string object. E.g { nbk_dialog: true }
 */
const checkHours = createAsyncThunk(
  'help/checkHours',
  ({ callback, ...options } = {}) =>
    fetchJSON(`/check_hours.json?${queryString.stringify(options)}`).finally(
      () => callback && callback()
    )
);

const slice = createSlice({
  name: 'tipPoolingPolicies',

  initialState: fromJS({
    locationEligibleForPurchase: false,
    currentPayrollPeriod: [],
    previousPayrollPeriod: [],
    tipPoolingPolicies: null,
    isLoading: true,
    showCreatedModal: false,
    showTipPoolDisabledAlert: false,
    showTipPoolEnabledAlert: false,
    showTipPoolUpdatedAlert: false,
    tipPoolEffectiveDate: '',
    locationRoles: [],
    showNewRoleAddedSuccessAlert: false,
    showRequestUnsubscribeCallModal: false,
    showRequestUnsubscribeCallSuccessModal: false,
    withinBusinessHours: false,
  }),

  reducers: {
    dismissCreatedModal: state => state.set('showCreatedModal', false),

    dismissTipPoolDisabledAlert: state =>
      state.set('showTipPoolDisabledAlert', false),

    dismissTipPoolEnabledAlert: state =>
      state.set('showTipPoolEnabledAlert', false),

    dismissTipPoolUpdatedAlert: state =>
      state.set('showTipPoolUpdatedAlert', false),

    dismissNewRoleAddedSuccessAlert: state =>
      state.set('showNewRoleAddedSuccessAlert', false),

    dismissRequestUnsubscribeCallModal: state =>
      state.set('showRequestUnsubscribeCallModal', false),

    openRequestUnsubscribeCallModal: state =>
      state.set('showRequestUnsubscribeCallModal', true),

    dismissRequestUnsubscribeCallSuccessModal: state =>
      state.set('showRequestUnsubscribeCallSuccessModal', false),
  },

  extraReducers: builder => {
    builder.addCase(thunks.createTipPolicy.pending, state =>
      state.merge({ isSubmitting: true })
    );

    builder.addCase(thunks.createTipPolicy.rejected, state =>
      state.merge({ isSubmitting: false })
    );

    builder.addCase(thunks.createTipPolicy.fulfilled, (state, { payload }) =>
      state
        .merge({ isSubmitting: false })
        .set('tipPoolingPolicies', [payload])
        .set('tipPoolEffectiveDate', payload.effective_date)
        .set('showCreatedModal', true)
    );

    builder.addCase(thunks.enableTipPolicy.pending, state =>
      state.merge({ isSubmitting: true })
    );

    builder.addCase(thunks.enableTipPolicy.rejected, state =>
      state.merge({ isSubmitting: false })
    );

    builder.addCase(thunks.enableTipPolicy.fulfilled, (state, { payload }) =>
      state
        .merge({ isSubmitting: false })
        .set('showTipPoolEnabledAlert', true)
        .set('tipPoolingPolicies', [payload])
    );

    builder.addCase(thunks.updateTipPolicy.pending, state =>
      state.merge({ isSubmitting: true })
    );

    builder.addCase(thunks.updateTipPolicy.rejected, state =>
      state.merge({ isSubmitting: false })
    );

    builder.addCase(thunks.updateTipPolicy.fulfilled, (state, { payload }) =>
      state
        .merge({ isSubmitting: false })
        .set('showTipPoolUpdatedAlert', true)
        .set('tipPoolingPolicies', [payload])
    );

    builder.addCase(thunks.disableTipPolicy.pending, state =>
      state.merge({ isSubmitting: true })
    );

    builder.addCase(thunks.disableTipPolicy.rejected, state =>
      state.merge({ isSubmitting: false })
    );

    builder.addCase(thunks.disableTipPolicy.fulfilled, (state, { payload }) =>
      state
        .merge({ isSubmitting: false })
        .set('showTipPoolDisabledAlert', true)
        .set('tipPoolingPolicies', [payload])
    );

    builder.addCase(thunks.fetchLocationTipPolicies.pending, state =>
      state.set('isLoading', true)
    );

    builder.addCase(
      thunks.fetchLocationTipPolicies.fulfilled,
      (state, { payload }) => {
        state = state
          .set('tipPoolingPolicies', payload.tip_pooling_policies)
          .set(
            'currentPayrollPeriod',
            fromJS(payload.current_payroll_period).toArray()
          )
          .set(
            'previousPayrollPeriod',
            fromJS(payload.previous_payroll_period).toArray()
          )
          .set('twiceWeekPayrollInfo', payload.twice_week_payroll_info)
          .set(
            'locationEligibleForPurchase',
            fromJS(payload.location_eligible_for_purchase)
          )
          .set('subscribed', payload.subscription?.subscribed)
          .set('price', payload.subscription?.pricing?.price)
          .set('systemPriceId', payload.subscription?.pricing?.system_id)
          .set('locationEligibleForPos', payload.location_eligible_for_pos)
          .set('tipPoolingSources', fromJS(payload.tip_pooling_sources))
          .set('isLoading', false);
        if (payload.roles) {
          state = state.set('locationRoles', payload.roles);
        }
        return state;
      }
    );

    builder.addCase(thunks.fetchLocationTipPolicies.rejected, state =>
      state.set('isLoading', false)
    );

    builder.addCase(thunks.addNewRole.pending, state =>
      state.set('isLoading', true)
    );

    builder.addCase(thunks.addNewRole.fulfilled, (state, { payload }) => {
      payload.department_name = 'Dept. Not Set';
      const roles = [...state.get('locationRoles'), payload];
      return state
        .set('locationRoles', roles)
        .set('showNewRoleAddedSuccessAlert', true)
        .set('isLoading', false);
    });

    builder.addCase(thunks.addNewRole.rejected, state =>
      state.set('isLoading', false)
    );

    builder.addCase(thunks.requestCallbackToUnsubscribe.fulfilled, state =>
      state
        .set('isLoading', false)
        .set('showRequestUnsubscribeCallModal', false)
        .set('showRequestUnsubscribeCallSuccessModal', true)
    );

    builder.addCase(checkHours.pending, state =>
      state.set('withinBusinessHours', false)
    );

    builder.addCase(checkHours.fulfilled, (state, { payload }) =>
      state.set('withinBusinessHours', payload.within_business_hours)
    );

    builder.addCase(checkHours.rejected, state =>
      state.set('withinBusinessHours', false)
    );
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
