import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON, postJSON, putJSON } from 'api/fetch';

export const updateContactInformation = createAsyncThunk(
  'packetWorkFlow/updateContactInformation',
  ({ packetId, data, queryParams }, { rejectWithValue }) =>
    putJSON(
      // eslint-disable-next-line max-len
      `/employee_onboarding/single_document_packet/${packetId}/contact_information.json${queryParams}`,
      data
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const fetchPacketData = createAsyncThunk(
  'singleDocumentPacket/fetchPacketData',
  ({ packetId, queryParams }) =>
    fetchJSON(
      `/employee_onboarding/single_document_packet/${packetId}.json${queryParams}`
    )
);

export const completeForm = createAsyncThunk(
  'packetWorkFlow/completeForm',
  ({ packetId, data, queryParams }, { rejectWithValue }) =>
    postJSON(
      `/employee_onboarding/single_document_packet/${packetId}/complete_form${queryParams}`,
      data
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const completeCustomDocument = createAsyncThunk(
  'singleDocumentPacket/completeCustomDocument',
  ({ packetId, data, queryParams }, { rejectWithValue }) =>
    postJSON(
      // eslint-disable-next-line max-len
      `/employee_onboarding/single_document_packet/${packetId}/complete_custom_document${queryParams}`,
      data
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const completeDirectDepositForm = createAsyncThunk(
  'singleDocumentPacket/completeDirectDepositForm',
  ({ packetId, data, queryParams }, { rejectWithValue }) =>
    postJSON(
      // eslint-disable-next-line max-len
      `/employee_onboarding/single_document_packet/${packetId}/complete_direct_deposit_form${queryParams}`,
      data
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

const singleDocumentPacketSlice = createSlice({
  name: 'singleDocumentPacket',

  initialState: {
    packet: {},
    location: {},
    user: {},
  },

  extraReducers: builder => {
    builder.addCase(fetchPacketData.fulfilled, (state, action) => {
      state.packet = action.payload.packet;
      state.location = action.payload.location;
      state.user = action.payload.user;
    });

    builder.addCase(completeCustomDocument.fulfilled, (state, action) => {
      state.packet = action.payload.packet;
      state.user = action.payload.user;
    });

    builder.addCase(completeForm.fulfilled, (state, action) => {
      state.packet = action.payload.packet;
      state.user = action.payload.user;
    });

    builder.addCase(completeDirectDepositForm.fulfilled, (state, action) => {
      state.packet = action.payload.packet;
      state.user = action.payload.user;
    });

    builder.addCase(updateContactInformation.fulfilled, (state, action) => {
      state.serverErrors = [];
      state.packet = action.payload.packet;
      state.user = action.payload.user;
    });

    builder.addCase(updateContactInformation.rejected, (state, action) => {
      state.serverErrors = action.payload.errors;
    });
  },
});

export const singleDocumentPacketReducer = singleDocumentPacketSlice.reducer;
