import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';

const SvgAibolt = ({
  alt = 'AI Bolt',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g clipPath="url(#aibolt_svg__a)">
      <path
        fill="currentColor"
        d="M15.844 9.778 21 0H7.5L0 14.222h8.156L3 24h2l16-11.977 3-2.245z"
      />
    </g>
    <defs>
      <clipPath id="aibolt_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAibolt;
