import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON, postJSON, putJSON } from 'api/fetch';

import { setActiveTipPolicyId } from 'actions/session';

import { TALKDESK_DOWNGRADES_FLOW_ID } from 'features/monetization/talkdeskFlowIds';

import { DEFAULT_TIP_POLICY_PARAMS } from './constants';

export const fetchLocationTipPolicies = createAsyncThunk(
  'tipPoolingPolicies/fetchLocationTipPolicies',
  (_, { dispatch }) =>
    fetchJSON('/timesheets/tip_pooling_policies').then(response => {
      dispatch(setActiveTipPolicyId(response.tip_pooling_policies[0]?.id));
      return response;
    })
);

export const createTipPolicy = createAsyncThunk(
  'tipPoolingPolicies/createTipPolicy',
  (_, { rejectWithValue }) =>
    postJSON(
      `/timesheets/tip_pooling_policies/`,
      DEFAULT_TIP_POLICY_PARAMS
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const disableTipPolicy = createAsyncThunk(
  'tipPoolingPolicies/disableTipPolicy',
  ({ id, selected_payroll_date }, { dispatch, rejectWithValue }) => {
    dispatch(setActiveTipPolicyId(null));
    return putJSON(`/timesheets/tip_pooling_policies/${id}/`, {
      archived_at: selected_payroll_date,
      effective_date: selected_payroll_date,
    }).catch(err => err.response.json().then(body => rejectWithValue(body)));
  }
);

export const enableTipPolicy = createAsyncThunk(
  'tipPoolingPolicies/enableTipPolicy',
  ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setActiveTipPolicyId(id));
    return putJSON(`/timesheets/tip_pooling_policies/${id}/`, {
      archived_at: null,
    }).catch(err => err.response.json().then(body => rejectWithValue(body)));
  }
);

export const updateTipPolicy = createAsyncThunk(
  'tipPoolingPolicies/updateTipPolicy',
  ({ id, data }, { rejectWithValue }) =>
    putJSON(`/timesheets/tip_pooling_policies/${id}/`, data).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const addNewRole = createAsyncThunk(
  'tipPoolingPolicies/addNewRole',
  ({ roleName }, { rejectWithValue }) =>
    postJSON(`/roles`, { role: { name: roleName } }).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const requestCallbackToUnsubscribe = createAsyncThunk(
  'help/requestCallback',
  ({ phoneNumber, onSuccess }, { rejectWithValue }) =>
    postJSON('/callback_request', {
      callback: {
        selected_reason: 'tip_manager',
        phone_number: phoneNumber,
        flow_id: TALKDESK_DOWNGRADES_FLOW_ID,
      },
    })
      .then(() => onSuccess())
      .catch(error => rejectWithValue(error.body || error.message))
);
