import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

const IncognitoModeAlert = () => (
  <Box
    tcenter
    column
    justifyContentCenter
    gap={8}
    maxw={395}
    h="100vh"
    mh="auto"
  >
    <Text
      variant="heading3"
      color="mono900"
      i18n="integrations.common.clover_incognito.heading"
    />
    <Text
      variant="body"
      color="mono700"
      i18n="integrations.common.clover_incognito.body"
    />
  </Box>
);

export default IncognitoModeAlert;
