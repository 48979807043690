export const actionTypes = {
  CLOSE_MODAL: 'RETRIAL/CLOSE_MODAL',
  OPEN_MODAL: 'RETRIAL/OPEN_MODAL',
  ACTIVATE_RETRIAL: 'RETRIAL/ACTIVATE_RETRIAL',
};

export const closeModal = () => ({ type: actionTypes.CLOSE_MODAL });
export const openModal = () => ({ type: actionTypes.OPEN_MODAL });
export const activateRetrial = text => ({
  type: actionTypes.ACTIVATE_RETRIAL,
  payload: { text, showToolTip: true },
});
