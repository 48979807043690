function sortEmployeeInDepartmentByName(jobIdUserIdMap, userIdUserNameMap) {
  return function (jobIdA, jobIdB) {
    const userIdA = jobIdUserIdMap[jobIdA];
    const userIdB = jobIdUserIdMap[jobIdB];

    const userA = userIdUserNameMap[userIdA];
    const userB = userIdUserNameMap[userIdB];

    return userA?.fullName.toLowerCase() < userB?.fullName.toLowerCase()
      ? -1
      : 1;
  };
}

export default sortEmployeeInDepartmentByName;
