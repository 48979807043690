export const DOWNGRADE_BOTTOM_DRAWER = 'DOWNGRADE_BOTTOM_DRAWER';

export const MODULE_NAME = 'downgradeBottomDrawer';

export const ENDPOINTS = {
  POST_MONETIZATION_DOWNGRADE: '/monetization/downgrade',
  POST_MONETIZATION_ACCEPT_OFFER: '/monetization/accept_offer',
  POST_MONETIZATION_REQUEST_SUPPORT: '/monetization/request_support',
  GET_MONETIZATION_DOWNGRADE_WORKFLOW: '/monetization/downgrade_workflow.json',
};
