// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO[redux-toolkit-upgrade]: Fix action type after upgrade
import { isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import {
  createTask,
  createTaskList,
  deleteSingleTask,
  deleteTaskList,
  fetchDepartments,
  fetchEmployees,
  fetchHistoryTasks,
  fetchRoles,
  fetchTasks,
  fetchTodayTasks,
  getTaskById,
  updateSingleTask,
  updateTaskList,
} from 'features/taskManager/action';

import { ReducerBuilder } from './index.interface';

const createTaskReducer = (builder: ReducerBuilder) => {
  builder
    .addMatcher(isPending(createTask, createTaskList), state => ({
      ...state,
      isCreatingTask: true,
    }))
    .addMatcher(isRejected(createTask, createTaskList), state => ({
      ...state,
      isCreatingTask: false,
    }))
    .addMatcher(
      isFulfilled(createTask, createTaskList),
      (state, { payload: task }) => ({
        ...state,
        isCreatingTask: false,
        tasks: [task, ...state.tasks],
      })
    );
};

const fetchTasksReducer = (builder: ReducerBuilder) => {
  builder
    .addMatcher(fetchTasks.pending.match, state => ({
      ...state,
      isFetchingTasks: true,
    }))
    .addMatcher(fetchTasks.rejected.match, state => ({
      ...state,
      isFetchingTasks: false,
    }))
    .addMatcher(getTaskById.pending.match, state => ({
      ...state,
      isFetchingTask: true,
    }))
    .addMatcher(getTaskById.rejected.match, state => ({
      ...state,
      isFetchingTask: false,
    }))
    .addMatcher(
      fetchTasks.fulfilled.match,
      (state, { payload: { tasks, page_number, ...others } }) => ({
        ...state,
        tasks: page_number === 1 ? tasks : [...state.tasks].concat(tasks),
        pagination: { ...others, page_number },
        isFetchingTasks: false,
      })
    )
    .addMatcher(getTaskById.fulfilled.match, (state, { payload }) => {
      const newTasks = [...state.tasks];
      let index = newTasks.findIndex(task => task.id === payload.id);
      index = index === -1 ? newTasks.length : index;

      newTasks[index] = payload;

      return { ...state, tasks: newTasks, isFetchingTask: false };
    });
};

const deleteSingleTaskReducer = (builder: ReducerBuilder) => {
  builder.addMatcher(
    isFulfilled(deleteSingleTask, deleteTaskList),
    (state, { payload: id }) => ({
      ...state,
      tasks: [...state.tasks].filter(task => task.id !== id),
    })
  );
};

const updateSingleTaskReducer = (builder: ReducerBuilder) => {
  builder.addMatcher(
    isFulfilled(updateSingleTask, updateTaskList),
    (state, { payload: updatedTask }) => ({
      ...state,
      tasks: [...state.tasks].map(task => {
        if (task.id === updatedTask.id) return { ...task, ...updatedTask };
        return task;
      }),
    })
  );
};

const fetchDepartmentsReducer = (builder: ReducerBuilder) => {
  builder
    .addMatcher(
      fetchDepartments.fulfilled.match,
      (state, { payload: departments }) => ({
        ...state,
        departments,
        isFetchingDepartments: false,
      })
    )
    .addMatcher(fetchDepartments.pending.match, state => ({
      ...state,
      isFetchingDepartments: true,
    }))
    .addMatcher(fetchDepartments.rejected.match, state => ({
      ...state,
      isFetchingDepartments: false,
    }));
};

const fetchRolesReducer = (builder: ReducerBuilder) => {
  builder
    .addMatcher(fetchRoles.fulfilled.match, (state, { payload: roles }) => ({
      ...state,
      roles,
      isFetchingRoles: false,
    }))
    .addMatcher(fetchRoles.pending.match, state => ({
      ...state,
      isFetchingRoles: true,
    }))
    .addMatcher(fetchRoles.rejected.match, state => ({
      ...state,
      isFetchingRoles: false,
    }));
};

const fetchEmployeesReducer = (builder: ReducerBuilder) => {
  builder
    .addMatcher(
      fetchEmployees.fulfilled.match,
      (state, { payload: { users: employees } }) => ({
        ...state,
        employees,
        isFetchingEmployees: false,
      })
    )
    .addMatcher(fetchEmployees.pending.match, state => ({
      ...state,
      isFetchingEmployees: true,
    }))
    .addMatcher(fetchEmployees.rejected.match, state => ({
      ...state,
      isFetchingEmployees: false,
    }));
};

const fetchHistoryTasksReducer = (builder: ReducerBuilder) => {
  builder
    .addMatcher(
      fetchHistoryTasks.fulfilled.match,
      (state, { payload: historyTasks }) => ({
        ...state,
        historyTasks,
        isFetchingHistoryTasks: false,
      })
    )
    .addMatcher(fetchHistoryTasks.pending.match, state => ({
      ...state,
      isFetchingHistoryTasks: true,
    }))
    .addMatcher(fetchHistoryTasks.rejected.match, state => ({
      ...state,
      isFetchingHistoryTasks: false,
    }));
};

const fetchTodayTasksReducer = (builder: ReducerBuilder) => {
  builder
    .addMatcher(
      fetchTodayTasks.fulfilled.match,
      (state, { payload: todayTasks }) => ({
        ...state,
        todayTasks,
        isFetchingTodayTasks: false,
      })
    )
    .addMatcher(fetchTodayTasks.pending.match, state => ({
      ...state,
      isFetchingTodayTasks: true,
    }))
    .addMatcher(fetchTodayTasks.rejected.match, state => ({
      ...state,
      isFetchingTodayTasks: false,
    }));
};

export const extraReducers = (builder: ReducerBuilder) => {
  createTaskReducer(builder);
  fetchTasksReducer(builder);
  fetchRolesReducer(builder);
  deleteSingleTaskReducer(builder);
  updateSingleTaskReducer(builder);
  fetchDepartmentsReducer(builder);
  fetchEmployeesReducer(builder);
  fetchHistoryTasksReducer(builder);
  fetchTodayTasksReducer(builder);
};
