import React, { PureComponent } from 'react';

import { navigateToOpenShift } from 'features/scheduleBuilder/ScheduleBuilderView/utils/navigateToOpenShift';

import { toI18n } from 'util/i18n';
import { setOriginContext } from 'util/tracking';
import { TRACK_STARTING_PATHS } from 'util/tracking_constants';

import RequestsListItem from '../RequestsListItem';

export default class OpenShift extends PureComponent {
  handleApprove = () => {
    const openShiftId = this.props.item.get('id');

    this.props.closeDrawer();
    setOriginContext({ starting_path: TRACK_STARTING_PATHS.ALERTS });
    navigateToOpenShift(this.props.item.get('date'), openShiftId);
  };

  render() {
    return (
      <RequestsListItem
        body={toI18n('notifications.requests.open_shift.desc', {
          props: { date: this.props.item.get('date') },
        })}
        onApprove={this.handleApprove}
        acceptButtonCopy={toI18n('notifications.requests.open_shift.approve')}
        showDeclineButton={false}
        {...this.props}
      />
    );
  }
}
