import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Logo from 'fe-design-base/assets/Logo';
import { useIsBrowserOnMobile } from 'hooks/useIsBrowserOnMobile';
import PropTypes from 'prop-types';
import { dimensions } from 'theme/constants';

import * as navActions from 'actions/nav';

import * as sessionSelectors from 'selectors/session';

import BillingStateBanner from 'features/biller/billingStateBanner/BillingStateBanner';
import EnforcementBanner from 'features/enforcement/EnforcementBanner/EnforcementBanner';
import { enrollEntityInExperiment } from 'features/experiments/actions';
import { getViewLogic } from 'features/experiments/selectors';
import PredictedFundingFailureBanner from 'features/payroll/components/HeaderNavBanners/PredictedFundingFailureBanner';
import PayrollCountdownBanner from 'features/payroll/components/PayrollCountdownBanner';
import PayrollPreNoteValidationBanner from 'features/payroll/components/PayrollPreNoteValidationBanner';
import { FULL_SCREEN_PATHS } from 'features/payroll/constants';
import PayrollFailedPaymentBanner from 'features/payroll/FailedPaymentViews/PayrollFailedPaymentBanner';

import AnnounceKitButton from 'components/AnnounceKit/AnnounceKitButton';
import Box from 'components/Box';
import withCurrentRoute from 'components/hocs/withCurrentRoute';

import { cxHelpers } from 'util/className';
import { datadogLogInfo } from 'util/datadogLogs';
import { EVENT_CATEGORIES, PRODUCT_AREAS } from 'util/tracking_constants';
import { withUxRoot } from 'util/uxEvents';

import GlobalComponents from '../GlobalComponents';
import ActiveTrialNavItem from '../items/ActiveTrialNavItem';
import AvatarMenuNavItem from '../items/AvatarMenuNavItem';
import LocationsMenuNavItem from '../items/LocationsMenuNavItem';
import MessengerNavItem from '../items/MessengerNavItem';
import NotificationsNavItem from '../items/NotificationsNavItem';
import UpgradeButtonNavItem from '../items/UpgradeButtonNavItem';
import UserWebTimeclockNavItem from '../items/UserWebTimeclockNavItem';

import MobileHeaderNav from './MobileHeaderNav';

const { cx } = cxHelpers('NavView');

import { useIsLargeScreen } from 'fe-design-base/utils/useMediaQuery';
import { useIsEmbedded } from 'hooks/useIsEmbedded';

import SwitchToPayrollBanner from 'features/monetization/SwitchToPayrollBanner';

import { selectShouldShowPayrollEoyBanner } from '../../monetization/selectors';

export const HeaderNav = ({
  navType,
  isGodModing,
  currentUser,
  currentRoute,
  mobile,
  collapsedState,
  setCollapsedState,
  showPayrollCountdownBanner,
  whatsNewEnabled,
  onMount,
  shouldShowPayrollEoyBanner,
}) => {
  const isEmbedded = useIsEmbedded();
  const isLargeScreen = useIsLargeScreen();
  const isBrowserOnMobile = useIsBrowserOnMobile();

  useEffect(() => {
    onMount(['fe_whats_new']);
  }, [onMount]);

  useEffect(() => {
    if (window.Homebase.env !== 'production') {
      datadogLogInfo({
        message: `isBrowserOnMobile`,
        context: {
          currentUser: currentUser.get('id'),
          mobile,
          currentRoute,
          isGodModing,
          navType,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickLogo = useCallback(
    () => window.Homebase.RailsReactBridge.navigateToReactView('/home'),
    []
  );

  const handleCollapseLeftNav = useCallback(
    () => setCollapsedState(true),
    [setCollapsedState]
  );

  if (navType === 'hidden') {
    return <GlobalComponents />;
  }

  // For when they are running payroll
  if (FULL_SCREEN_PATHS.some(path => currentRoute.includes(path))) {
    return null;
  }

  // If we are embedded, we don't want to show the header
  if (isEmbedded) {
    return <GlobalComponents />;
  }

  if (isBrowserOnMobile) {
    return <GlobalComponents />;
  }

  return (
    <>
      <BillingStateBanner />
      <EnforcementBanner />
      {showPayrollCountdownBanner && <PayrollCountdownBanner />}
      <PayrollFailedPaymentBanner />
      <PayrollPreNoteValidationBanner />
      <PredictedFundingFailureBanner />
      {shouldShowPayrollEoyBanner && <SwitchToPayrollBanner />}
      {mobile ? (
        <MobileHeaderNav
          navType={navType}
          isGodModing={isGodModing}
          currentUser={currentUser}
          collapsedState={collapsedState}
          setCollapsedState={setCollapsedState}
        />
      ) : (
        <Box>
          <Box
            className={cx({}, 'js-nav')}
            minh={dimensions.navHeight}
            z={11}
            pl={32}
            vcenter
            bg={isGodModing ? 'black' : 'purpleXdark'}
            row
            spacebetween
          >
            <Box onClick={handleClickLogo} pointer>
              <Logo color="mono0" height={16} />
            </Box>

            <Box row vcenter>
              {whatsNewEnabled && isLargeScreen && (
                <Box mr={16}>
                  <AnnounceKitButton />
                </Box>
              )}

              <Box>
                <ActiveTrialNavItem fullText />
              </Box>
              <Box ph={8}>
                <UpgradeButtonNavItem
                  onCollapseLeftNav={handleCollapseLeftNav}
                  newHeader
                  fullText
                />
              </Box>
              <Box ph={8}>
                <UserWebTimeclockNavItem newHeader />
              </Box>
              <Box ph={8}>
                <MessengerNavItem newHeader />
              </Box>
              <Box ph={8}>
                <NotificationsNavItem newHeader />
              </Box>
              <Box ph={8}>
                <LocationsMenuNavItem newHeader mlxs />
              </Box>
              <Box ph={8}>
                <AvatarMenuNavItem newHeader />
              </Box>
            </Box>
          </Box>

          <GlobalComponents />
        </Box>
      )}
    </>
  );
};

HeaderNav.propTypes = {
  showPayrollCountdownBanner: PropTypes.bool,
};

export default connect(
  (state, props) => ({
    navType: sessionSelectors.getNavType(state, props),
    isGodModing: sessionSelectors.getIsGodModing(state, props),
    currentUser: sessionSelectors.getCurrentUser(state, props),
    showPayrollCountdownBanner:
      sessionSelectors.getShowPayrollCountdownBanner(state),
    collapsedState: state.getIn(['nav', 'leftNavCollapsed']),
    shouldShowPayrollEoyBanner: selectShouldShowPayrollEoyBanner(state),
    whatsNewEnabled: getViewLogic(state, 'fe_whats_new') === 1,
  }),
  {
    setCollapsedState: navActions.toggleLeftNav,
    onMount: enrollEntityInExperiment,
  }
)(
  withCurrentRoute(
    withUxRoot({
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventCategory: EVENT_CATEGORIES.NAV,
    })(HeaderNav)
  )
);
