import MuiSnackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Alert, { AlertProps } from 'fe-design-base/molecules/Alert';

export const DEFAULT_AUTO_HIDE_DURATION = 7000;

export interface ToastProps
  extends Omit<AlertProps, 'forceStacked' | 'secondaryAction'> {
  /** Open state of the snackbar */
  open: boolean;
  /** Callback when the snackbar is closed */
  onClose: () => void;
  /** If true, waits a default amount of time before automatically calling the onClose function */
  autoHide?: boolean;

  /** If uxElement prop provided, uxTracking will automatically trigger */
  uxElement?: string;
  hideCloseIcon?: boolean;
  isAI?: boolean;
}

const Toast = ({
  open,
  onClose,
  autoHide = true,
  hideCloseIcon = false,
  uxElement,
  action,
  isAI,
  ...alertProps
}: ToastProps) => {
  let autoHideDuration = autoHide ? DEFAULT_AUTO_HIDE_DURATION : null;
  if (action) {
    autoHideDuration = null;
  }

  return (
    <MuiSnackbar
      sx={{
        left: undefined,
        right: undefined,
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      disableWindowBlurListener
      onClose={onClose}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      anchorOrigin={
        { horizontal: 'center', vertical: 'bottom' } as SnackbarOrigin
      }
    >
      <div>
        <Alert
          {...alertProps}
          action={action}
          uxElement={uxElement}
          isToast
          onClose={!hideCloseIcon ? onClose : undefined}
          isAI={isAI}
        />
      </div>
    </MuiSnackbar>
  );
};

export default Toast;
