import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON, postJSON } from 'api/fetch';
import { fromJS } from 'immutable';

import { moment } from 'util/dateTime';

import { COLUMNS } from './constants';
import { getHasErrors } from './selectors';

export const fetchInitData = createAsyncThunk(
  'bulkTerminate/initData',
  () => fetchJSON('/employees/bulk_terminate_data'),
  {
    condition: (props, { getState }) =>
      getState().getIn(['bulkTerminate', 'employees']).size === 0,
  }
);

export const terminateEmployees = createAsyncThunk(
  'bulkTerminate/terminateEmployees',
  (props, { getState }) => {
    const state = getState();

    const terminatedEmployees = state
      .getIn(['bulkTerminate', 'selectedEmployees'])
      .map(e => ({
        user_id: e.getIn(['id', 'value']),
        archive_record: {
          eligible_for_rehire: e.getIn(['eligible_for_rehire', 'value']),
          notes: e.getIn(['notes', 'value']),
          reason: e.getIn(['reason', 'value']),
          archived_at: e.getIn(['archived_at', 'value']),
        },
      }))
      .toArray();

    return postJSON('/employees/bulk_terminate', {
      terminated_employees: terminatedEmployees,
    });
  },
  {
    condition: (props, { getState }) => !getHasErrors(getState()),
    dispatchConditionRejection: true,
  }
);

const initSelectedEmployees = (state, action) => {
  const defaultValues = {
    eligible_for_rehire: { value: true },
    archived_at: { value: moment().format('MM/DD/YYYY') },
  };

  const selectedEmployees = action.payload
    .map(e => ({
      ...defaultValues,
      employee: { value: e.get('name') },
      id: { value: e.get('user_id') },
    }))
    .toArray();

  return state.set('selectedEmployees', fromJS(selectedEmployees));
};

const updateValue = (state, action) =>
  state.setIn(
    [
      'selectedEmployees',
      action.payload.rowIndex,
      action.payload.columnName,
      'value',
    ],
    action.payload.value
  );

const validateTable = state => {
  const keys = Object.keys(COLUMNS).filter(c => COLUMNS[c].validations);

  return keys.reduce((newState, column) => {
    const validations = COLUMNS[column].validations;

    return newState
      .get('selectedEmployees')
      .reduce((newState2, employee, index) => {
        const value = employee.getIn([column, 'value']);
        const errorPath = ['selectedEmployees', index, column, 'error'];

        // eslint-disable-next-line
        for (const validation of validations) {
          const validationError = validation(value);
          if (validationError) {
            return newState2.setIn(errorPath, validationError);
          }
        }

        return newState2.deleteIn(errorPath);
      }, newState);
  }, state);
};

const validateValue = (state, action) => {
  const validations = COLUMNS[action.payload.columnName].validations || [];

  if (validations.length) {
    const value = state.getIn([
      'selectedEmployees',
      action.payload.rowIndex,
      action.payload.fieldName,
      'value',
    ]);

    const errorPath = [
      'selectedEmployees',
      action.payload.rowIndex,
      action.payload.fieldName,
      'error',
    ];

    // eslint-disable-next-line
    for (const validation of validations) {
      const validationError = validation(value);
      if (validationError) {
        return state.setIn(errorPath, validationError);
      }
    }

    return state.deleteIn(errorPath);
  }

  return state;
};

const hidePostSubmitTooltip = state => state.set('showTooltip', false);

const bulkTerminateSlice = createSlice({
  name: 'bulkTerminate',

  initialState: fromJS({
    employees: [],
    selectedEmployees: [],
  }),

  reducers: {
    initSelectedEmployees,
    updateValue,
    validateValue,
    validateTable,
    hidePostSubmitTooltip,
  },

  extraReducers: builder => {
    builder.addCase(terminateEmployees.fulfilled, state => {
      const ids = state
        .get('selectedEmployees')
        .map(e => e.getIn(['id', 'value']))
        .toSet();

      return state
        .set('selectedEmployees', fromJS([]))
        .set('showTooltip', true)
        .set(
          'employees',
          state.get('employees').filter(e => !ids.includes(e.get('user_id')))
        );
    });

    builder.addCase(fetchInitData.fulfilled, (state, action) =>
      state
        .set('employees', fromJS(action.payload.jobs))
        .set('archiveReasons', action.payload.possible_archive_reasons)
    );
  },
});

export const bulkTerminateReducer = bulkTerminateSlice.reducer;
export const bulkTerminateActions = bulkTerminateSlice.actions;
