import Add from './Add';
import AddressBook from './AddressBook';
import AddTime from './AddTime';
import AddUser from './AddUser';
import AddUserOutline from './AddUserOutline';
import AddWithCircle from './AddWithCircle';
import AIBolt from './AIBolt';
import Announcement from './Announcement';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import Attach from './Attach';
import AutoClockOut from './AutoClockOut';
import Ban from './Ban';
import Bank from './Bank';
import Boost from './Boost';
import Break from './Break';
import Broken from './Broken';
import BusinessAddress from './BusinessAddress';
import Calendar from './Calendar';
import Camera from './Camera';
import CaretDown from './CaretDown';
import CaretLeft from './CaretLeft';
import CaretRight from './CaretRight';
import CaretUp from './CaretUp';
import Chart from './Chart';
import ChartColumn from './ChartColumn';
import Chat from './Chat';
import Check from './Check';
import ChevronDown from './ChevronDown';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import ChevronUp from './ChevronUp';
import Clear from './Clear';
import Close from './Close';
import Collapse from './Collapse';
import CollapseLeft from './CollapseLeft';
import CollapseRight from './CollapseRight';
import ColorApple from './ColorApple';
import ColorDribbble from './ColorDribbble';
import ColorFacebook from './ColorFacebook';
import ColorFigma from './ColorFigma';
import ColorGoogle from './ColorGoogle';
import ColorInstagram from './ColorInstagram';
import ColorLinkedin from './ColorLinkedin';
import ColorPinterest from './ColorPinterest';
import ColorSpotify from './ColorSpotify';
import ColorTikTok from './ColorTikTok';
import ColorTwitter from './ColorTwitter';
import ColorYouTube from './ColorYouTube';
import Complete from './Complete';
import Copy from './Copy';
import CreditCard from './CreditCard';
import Critical from './Critical';
import CSV from './CSV';
import Dashboard from './Dashboard';
import DashboardOutline from './DashboardOutline';
import Delete from './Delete';
import Desktop from './Desktop';
import Dislike from './Dislike';
import Document from './Document';
import DocumentOutline from './DocumentOutline';
import Documents from './Documents';
import Download from './Download';
import Draft from './Draft';
import Edit from './Edit';
import Email from './Email';
import Expand from './Expand';
import ExternalLink from './ExternalLink';
import Filter from './Filter';
import Folder from './Folder';
import Folders from './Folders';
import Frown from './Frown';
import Gift from './Gift';
import Grid from './Grid';
import Grip from './Grip';
import Group from './Group';
import Handshake from './Handshake';
import Hide from './Hide';
import Home from './Home';
import Image from './Image';
import Import from './Import';
import Info from './Info';
import InstantPay from './InstantPay';
import InstantPayOutline from './InstantPayOutline';
import Invoice from './Invoice';
import Laptop from './Laptop';
import LightBulb from './LightBulb';
import Like from './Like';
import Link from './Link';
import List from './List';
import Location from './Location';
import Lock from './Lock';
import Love from './Love';
import MagicWand from './MagicWand';
import Menu from './Menu';
import Merge from './Merge';
import Minus from './Minus';
import MinusWithCircle from './MinusWithCircle';
import Mobile from './Mobile';
import Money from './Money';
import MoneyOutline from './MoneyOutline';
import MonoApple from './MonoApple';
import MonoDribbble from './MonoDribbble';
import MonoFacebook from './MonoFacebook';
import MonoFigma from './MonoFigma';
import MonoGoogle from './MonoGoogle';
import MonoInstagram from './MonoInstagram';
import MonoLinkedin from './MonoLinkedin';
import MonoPinterest from './MonoPinterest';
import MonoSpotify from './MonoSpotify';
import MonoTikTok from './MonoTikTok';
import MonoTwitter from './MonoTwitter';
import MonoYouTube from './MonoYouTube';
import MoreHorizontal from './MoreHorizontal';
import MoreVertical from './MoreVertical';
import Notification from './Notification';
import Overnight from './Overnight';
import Overtime from './Overtime';
import PDF from './PDF';
import Phone from './Phone';
import Pin from './Pin';
import Play from './Play';
import Print from './Print';
import Profile from './Profile';
import Progress from './Progress';
import Question from './Question';
import QuestionOutline from './QuestionOutline';
import Redo from './Redo';
import Refresh from './Refresh';
import Repair from './Repair';
import ReportDocument from './ReportDocument';
import Reports from './Reports';
import ReportsOutline from './ReportsOutline';
import Safety from './Safety';
import SafetyOutline from './SafetyOutline';
import Schedule from './Schedule';
import ScheduleOutline from './ScheduleOutline';
import Search from './Search';
import Security from './Security';
import Send from './Send';
import Settings from './Settings';
import SettingsOutline from './SettingsOutline';
import Show from './Show';
import SignOutIn from './SignOutIn';
import Smile from './Smile';
import Sort from './Sort';
import SortDown from './SortDown';
import SortUp from './SortUp';
import Split from './Split';
import Star from './Star';
import Success from './Success';
import Support from './Support';
import Tablet from './Tablet';
import Template from './Template';
import TimeClock from './TimeClock';
import TimeClockOutline from './TimeClockOutline';
import TimeOffApproved from './TimeOffApproved';
import TimeOffDenied from './TimeOffDenied';
import Undo from './Undo';
import Unlock from './Unlock';
import Upload from './Upload';
import User from './User';
import Users from './Users';
import UsersOutline from './UsersOutline';
import Warehouse from './Warehouse';
import Warning from './Warning';
import Watch from './Watch';

export {
  Add,
  AddressBook,
  AddTime,
  AddUser,
  AddUserOutline,
  AddWithCircle,
  AIBolt,
  Announcement,
  ArrowLeft,
  ArrowRight,
  Attach,
  AutoClockOut,
  Ban,
  Bank,
  Boost,
  Break,
  Broken,
  BusinessAddress,
  Calendar,
  Camera,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  Chart,
  ChartColumn,
  Chat,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clear,
  Close,
  Collapse,
  CollapseLeft,
  CollapseRight,
  ColorApple,
  ColorDribbble,
  ColorFacebook,
  ColorFigma,
  ColorGoogle,
  ColorInstagram,
  ColorLinkedin,
  ColorPinterest,
  ColorSpotify,
  ColorTikTok,
  ColorTwitter,
  ColorYouTube,
  Complete,
  Copy,
  CreditCard,
  Critical,
  CSV,
  Dashboard,
  DashboardOutline,
  Delete,
  Desktop,
  Dislike,
  Document,
  DocumentOutline,
  Documents,
  Download,
  Draft,
  Edit,
  Email,
  Expand,
  ExternalLink,
  Filter,
  Folder,
  Folders,
  Frown,
  Gift,
  Grid,
  Grip,
  Group,
  Handshake,
  Hide,
  Home,
  Image,
  Import,
  Info,
  InstantPay,
  InstantPayOutline,
  Invoice,
  Laptop,
  LightBulb,
  Like,
  Link,
  List,
  Location,
  Lock,
  Love,
  MagicWand,
  Menu,
  Merge,
  Minus,
  MinusWithCircle,
  Mobile,
  Money,
  MoneyOutline,
  MonoApple,
  MonoDribbble,
  MonoFacebook,
  MonoFigma,
  MonoGoogle,
  MonoInstagram,
  MonoLinkedin,
  MonoPinterest,
  MonoSpotify,
  MonoTikTok,
  MonoTwitter,
  MonoYouTube,
  MoreHorizontal,
  MoreVertical,
  Notification,
  Overnight,
  Overtime,
  PDF,
  Phone,
  Pin,
  Play,
  Print,
  Profile,
  Progress,
  Question,
  QuestionOutline,
  Redo,
  Refresh,
  Repair,
  ReportDocument,
  Reports,
  ReportsOutline,
  Safety,
  SafetyOutline,
  Schedule,
  ScheduleOutline,
  Search,
  Security,
  Send,
  Settings,
  SettingsOutline,
  Show,
  SignOutIn,
  Smile,
  Sort,
  SortDown,
  SortUp,
  Split,
  Star,
  Success,
  Support,
  Tablet,
  Template,
  TimeClock,
  TimeClockOutline,
  TimeOffApproved,
  TimeOffDenied,
  Undo,
  Unlock,
  Upload,
  User,
  Users,
  UsersOutline,
  Warehouse,
  Warning,
  Watch,
};
