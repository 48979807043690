import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postJSON } from 'api/fetch';

import { checkHours } from './actions';

export const requestCallback = createAsyncThunk(
  'help/requestCallback',
  ({ selectedReason, phoneNumber }) =>
    postJSON('/request_callback', {
      callback: { selected_reason: selectedReason, phone_number: phoneNumber },
    })
);

const helpSlice = createSlice({
  name: 'help',

  initialState: {
    withinBusinessHours: false,
    requestCallbackPending: false,
    requestCallbackSent: false,
    closestBusinessDay: undefined,

    // Used to determine when to invoke tracking
    isCheckHoursFulfilled: false,
  },

  reducers: {},

  extraReducers: builder => {
    builder.addCase(checkHours.pending, state => {
      state.withinBusinessHours = false;
      state.closestBusinessDay = undefined;
      state.isCheckHoursFulfilled = false;
    });

    builder.addCase(checkHours.fulfilled, (state, action) => {
      const {
        within_business_hours: withinBusinessHours,
        closest_business_day: closestBusinessDay,
      } = action.payload;

      state.withinBusinessHours = withinBusinessHours;
      state.closestBusinessDay = closestBusinessDay;
      state.isCheckHoursFulfilled = true;
    });

    builder.addCase(checkHours.rejected, state => {
      state.withinBusinessHours = false;
      state.closestBusinessDay = undefined;
      state.isCheckHoursFulfilled = true;
    });

    builder.addCase(requestCallback.pending, state => {
      state.requestCallbackPending = true;
    });

    builder.addCase(requestCallback.fulfilled, state => {
      state.requestCallbackPending = false;
      state.requestCallbackSent = true;
    });
  },
});

export const actions = helpSlice.actions;
export const reducer = helpSlice.reducer;
