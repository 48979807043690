import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { aggregateErrors } from '../../util';

import { SLICE_NAME } from './constants';
import {
  addHiringBoost,
  changePaymentMethod,
  changeTier,
  createHiringBoost,
  createSetupIntent,
  fetchAdminCreditCards,
  fetchCreditCards,
  fetchPaymentIntent,
  moduleUpdatePaymentMethod,
  updatePaymentMethod,
} from './thunks';

const slice = createSlice({
  name: SLICE_NAME,

  initialState: fromJS({
    creditCards: fromJS([]),
  }),

  extraReducers: builder => {
    builder.addCase(changeTier.pending, state =>
      state.set('changeTierPending', true)
    );
    builder.addCase(changeTier.fulfilled, state =>
      state.set('changeTierPending', false)
    );
    builder.addCase(changeTier.rejected, state =>
      state.set('changeTierPending', false)
    );

    builder.addCase(fetchCreditCards.pending, state =>
      state.merge({ creditCardsError: null, fetchCreditCardsPending: true })
    );

    builder.addCase(fetchCreditCards.fulfilled, (state, action) =>
      state.merge({
        creditCards: fromJS(action.payload),
        fetchCreditCardsPending: false,
      })
    );

    builder.addCase(fetchCreditCards.rejected, (state, action) =>
      state.merge({
        creditCardsError: aggregateErrors(action.payload),
        fetchCreditCardsPending: false,
      })
    );

    builder.addCase(changePaymentMethod.pending, state =>
      state.merge({
        changePaymentMethodPending: true,
        creditCardsError: null,
      })
    );

    builder.addCase(changePaymentMethod.fulfilled, state =>
      state.set('changePaymentMethodPending', false)
    );

    builder.addCase(changePaymentMethod.rejected, (state, action) =>
      state.merge({
        changePaymentMethodPending: false,
        creditCardsError: aggregateErrors(action.payload),
      })
    );

    builder.addCase(updatePaymentMethod.pending, state =>
      state.merge({
        updatePaymentMethodPending: true,
        updatePaymentMethodErrors: null,
      })
    );

    builder.addCase(updatePaymentMethod.fulfilled, state =>
      state.set('updatePaymentMethodPending', false)
    );

    builder.addCase(updatePaymentMethod.rejected, (state, action) =>
      state.merge({
        updatePaymentMethodPending: false,
        updatePaymentMethodErrors: aggregateErrors(action.payload),
      })
    );

    builder.addCase(moduleUpdatePaymentMethod.pending, state =>
      state.merge({
        moduleUpdatePaymentMethodPending: true,
        moduleUpdatePaymentMethodErrors: null,
      })
    );

    builder.addCase(moduleUpdatePaymentMethod.fulfilled, state =>
      state.set('moduleUpdatePaymentMethodPending', false)
    );

    builder.addCase(moduleUpdatePaymentMethod.rejected, (state, action) =>
      state.merge({
        moduleUpdatePaymentMethodPending: false,
        moduleUpdatePaymentMethodErrors: aggregateErrors(action.payload),
      })
    );

    builder.addCase(addHiringBoost.pending, state =>
      state.merge({
        addHiringBoostPending: true,
        creditCardsError: null,
        addHiringBoostError: null,
        // clearing the error msg
        createHiringBoostErrors: null,
        appliedDiscountError: null,
      })
    );

    builder.addCase(addHiringBoost.fulfilled, state =>
      state.set('addHiringBoostPending', false)
    );

    builder.addCase(addHiringBoost.rejected, (state, action) =>
      state.merge({
        addHiringBoostPending: false,
        addHiringBoostError: aggregateErrors(action.payload),
      })
    );

    builder.addCase(createHiringBoost.pending, state =>
      state.merge({
        createHiringBoostPending: true,
        createHiringBoostErrors: null,
      })
    );

    builder.addCase(createHiringBoost.fulfilled, state =>
      state.set('createHiringBoostPending', null)
    );

    builder.addCase(createHiringBoost.rejected, (state, action) =>
      state.merge({
        createHiringBoostPending: false,
        createHiringBoostErrors: aggregateErrors(action.payload),
      })
    );

    builder.addCase(fetchAdminCreditCards.pending, state =>
      state.set('creditCardsError', null)
    );

    builder.addCase(fetchAdminCreditCards.fulfilled, (state, action) =>
      state.set('creditCards', fromJS(action.payload))
    );

    builder.addCase(fetchAdminCreditCards.rejected, (state, action) =>
      state.set('creditCardsError', aggregateErrors(action.payload))
    );

    builder.addCase(createSetupIntent.pending, state =>
      state.merge({
        setupIntentPending: true,
        setupIntentStatus: null,
        setupIntentSecret: null,
        setupIntentErrors: null,
      })
    );

    builder.addCase(createSetupIntent.fulfilled, (state, action) =>
      state.merge({
        setupIntentPending: false,
        setupIntentStatus: action.payload.status,
        setupIntentSecret: action.payload.client_secret,
      })
    );

    builder.addCase(createSetupIntent.rejected, (state, action) =>
      state.merge({
        setupIntentPending: false,
        setupIntentErrors: aggregateErrors(action.payload),
      })
    );

    builder.addCase(fetchPaymentIntent.pending, state =>
      state.merge({
        paymentIntentPending: true,
        paymentIntentSecret: null,
        paymentIntentErrors: null,
      })
    );

    builder.addCase(fetchPaymentIntent.fulfilled, (state, action) =>
      state.merge({
        paymentIntentPending: false,
        paymentIntentSecret: action.payload.client_secret,
      })
    );

    builder.addCase(fetchPaymentIntent.rejected, (state, action) =>
      state.merge({
        paymentIntentPending: false,
        paymentIntentErrors: aggregateErrors(action.payload),
      })
    );
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
