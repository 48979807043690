import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'tipManagerCheckoutDrawer',

  initialState: {
    isOpen: false,
    initialViewKey: 'Checkout',
  },

  reducers: {
    openTipManagerCheckoutDrawer: (state, { payload }) => {
      state.isOpen = true;
      state.initialViewKey = payload?.initialViewKey || 'Checkout';
    },

    closeTipManagerCheckoutDrawer: state => {
      state.isOpen = false;
    },
  },
});
export const { openTipManagerCheckoutDrawer, closeTipManagerCheckoutDrawer } =
  slice.actions;
export default slice.reducer;
