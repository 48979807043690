import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { readRequest } from 'actions/notifications';

import { toI18n } from 'util/i18n';

import RequestsListItem from '../RequestsListItem';

@connect(() => ({}), { readRequest })
export default class Personal extends PureComponent {
  static propTypes = {
    readRequest: PropTypes.func.isRequired,
  };

  handleApprove = () => this.props.readRequest(this.props.item);

  renderBody = () => {
    const title = this.props.item.get('title');
    const body = this.props.item.get('body');

    if (title === 'alerts.shifts_sync_failure_alert_title') {
      return <div dangerouslySetInnerHTML={{ __html: body }} />;
    }

    return body;
  };

  render() {
    return (
      <RequestsListItem
        showDeclineButton={false}
        acceptButtonCopy={toI18n('actions.accept')}
        body={this.renderBody()}
        onApprove={this.handleApprove}
        titleI18n={this.props.item.get('title')}
        {...this.props}
      />
    );
  }
}
