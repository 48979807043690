import React from 'react';
import RadioButtonField from 'fe-design-base/molecules/RadioButtonField';
import RadioButtonGroup from 'fe-design-base/organisms/RadioButtonGroup';
import { Form, Formik } from 'formik';

import { toI18n } from 'util/i18n';

import { effectiveDateSelectorInitialValues } from '../util';

import { EffectiveDateSelectorProps } from './types';

export const I18N_PATH = 'checkout_drawer.success.effective_date_form';

const EffectiveDateSelector: React.FC<EffectiveDateSelectorProps> = ({
  selectorOptions,
  setSelectedValue,
}) => (
  <Formik
    initialValues={effectiveDateSelectorInitialValues}
    // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
    onSubmit={() => {}}
  >
    {(formikState: { values: { effectiveDate: string } }) => {
      setSelectedValue(formikState.values.effectiveDate);

      return (
        <Form>
          <RadioButtonGroup
            name="effectiveDate"
            label={toI18n(`${I18N_PATH}.title`)}
            helperText={toI18n(`${I18N_PATH}.subtitle`)}
            hasAsterisk
          >
            {selectorOptions.map(option => (
              <RadioButtonField
                key={option.key}
                label={option.label}
                value={option.value}
                helperText={option.helperText}
                data-testid="RadioButtonField"
              />
            ))}
          </RadioButtonGroup>
        </Form>
      );
    }}
  </Formik>
);

export default EffectiveDateSelector;
