import {
  addDays,
  dateFromTime,
  getTimeDifference,
  moment,
  monthDayYearTimeFromDate,
} from 'util/dateTime';
import { toI18n } from 'util/i18n';

import scheduleAvailabilitySortCompare from './ScheduleBuilderView/utils/scheduleAvailabilitySortCompare';
import {
  CREATE_ROLE_LABEL,
  DEFAULT_ROLE_COLOR,
  NO_ROLE_LABEL,
  ROLE_PICKER_TRANSLATION_PATH,
  TEMPLATE_DETAILS_PATH,
} from './constants';

const DAYS_IN_A_WEEK = 7;
const END_OF_WEEK = 6;

// This map is to map the day of the week to
// what we store on the backend for a location's start of week value
export const DAYS_MAP = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

export const daysOfWeek = startOfWeek => {
  const result = [];
  let start = Number(startOfWeek);

  while (result.length < DAYS_IN_A_WEEK) {
    if (start > END_OF_WEEK || start < 0) start = 0;

    result.push(start);
    start += 1;
  }

  return result;
};

export const defaultWageLabel = ({ name, wage }) =>
  toI18n(
    `${ROLE_PICKER_TRANSLATION_PATH}.${
      wage ? 'default_wage' : 'default_wage_no_wage'
    }`,
    {
      props: { name, wage: wage?.toFixed(2) },
    }
  );

// works with strings in format "2023-01-01"
export const isDateInRange = (date, start, end) => start <= date && date <= end;

export const getEntityCurrentDate = (entity, dateInCurrentRange) =>
  dateFromTime(entity.attributes.startAt) === dateInCurrentRange;

export const getScheduleAvailabilitiesIds = (
  scheduleAvailabilities,
  currentDate,
  title,
  jobIds
) =>
  scheduleAvailabilities
    .filter(
      scheduleAvailability =>
        getEntityCurrentDate(scheduleAvailability, currentDate) &&
        scheduleAvailability.attributes.title === title &&
        jobIds.includes(scheduleAvailability.relationships.job.data.id)
    )
    .sort(scheduleAvailabilitySortCompare(title === 'Unavailable'))
    .map(sa => sa.relationships.job.data.id);

export const userRolesLabel = name =>
  toI18n(`${ROLE_PICKER_TRANSLATION_PATH}.user_roles`, {
    props: { name },
  });

export const getTemplateDetails = ({
  lastModified,
  totalShifts,
  totalHours,
  departments,
}) => [
  [
    `${TEMPLATE_DETAILS_PATH}.last_modified`,
    monthDayYearTimeFromDate(lastModified),
  ],
  [`${TEMPLATE_DETAILS_PATH}.total_shifts`, totalShifts],
  [`${TEMPLATE_DETAILS_PATH}.total_hours`, totalHours?.toFixed(2)],
  [`${TEMPLATE_DETAILS_PATH}.departments`, departments],
];

// { '0' => '06/06/2022', '1' => '06/07/2022, ... }
export const getCurrentWeekDatesMap = datesInCurrentWeek => {
  const datesMap = {};

  datesInCurrentWeek.forEach(day => {
    const momentDay = moment(day);

    datesMap[DAYS_MAP[momentDay.format('dddd')]] =
      momentDay.format('MM/DD/YYYY');
  });

  return datesMap;
};

export const generateNextNWeeks = ({ start, numberOfWeeks }) => {
  const result = [];

  while (result.length < numberOfWeeks && start) {
    start = addDays(start, 1);
    const end = addDays(start, END_OF_WEEK);
    result.push([start, end]);
    start = end;
  }

  return result;
};

export const getFullName = (userEntity, withLastInitialOnly = false) => {
  if (!userEntity) return '';

  const { firstName, lastName } = userEntity.attributes;

  if (withLastInitialOnly) {
    return `${firstName} ${lastName ? `${lastName[0]}.` : ''}`;
  }

  return `${firstName} ${lastName || ''}`;
};

export const getFirstName = userEntity => {
  if (!userEntity) return '';
  const { firstName } = userEntity.attributes;
  return firstName;
};

export const getInbuiltOptions = canManageRoles => {
  const options = [{ label: NO_ROLE_LABEL, value: null, color: 'blue' }];

  if (canManageRoles) {
    options.push({
      label: CREATE_ROLE_LABEL,
      value: CREATE_ROLE_LABEL,
      color: DEFAULT_ROLE_COLOR,
    });
  }

  return options;
};

export const areDatesStartAndEndOfSameMonth = (start, end) => {
  if (!start || !end) {
    return false;
  }
  const startOfMonth = start.clone().startOf('month');
  const endOfMonth = end.clone().endOf('month');

  return (
    start.isSame(startOfMonth, 'day') &&
    end.isSame(endOfMonth, 'day') &&
    startOfMonth.format('MM/YYYY') === endOfMonth.format('MM/YYYY')
  );
};

export const areDatesInSameWeek = (start, end, startOfWeek) => {
  if (!start || !end || startOfWeek === undefined) {
    return false;
  }

  // for whole week, it returns 6, so condition < 7 is without equal
  const diff = getTimeDifference(start, end, 'days');
  const startDay = moment(start).day();

  if (startDay >= startOfWeek) {
    return startDay - startOfWeek + diff < 7;
  }
  return startDay + 7 - startOfWeek + diff < 7;
};

export const isShiftWithinRange = (
  shift,
  dateRangeStart,
  dateRangeEnd,
  parseFormat = 'YYYY/MM/DD'
) => {
  if (!shift || !dateRangeStart || !dateRangeEnd) {
    return false;
  }

  const rangeStart = Date.parse(dateRangeStart, parseFormat);
  const rangeEnd = Date.parse(dateRangeEnd, parseFormat);
  const shiftStart = Date.parse(shift.attributes.startAt, parseFormat);
  const shiftEnd = Date.parse(shift.attributes.endAt, parseFormat);

  return shiftStart >= rangeStart && shiftEnd <= rangeEnd;
};

export const isOvernight = (startAt, endAt) => {
  const startDate = new Date(startAt);
  const endDate = new Date(endAt);

  const startDay = startDate.getDay();
  const endDay = endDate.getDay();

  const startDayIsEndOfTheWeek = startDay === END_OF_WEEK;
  const endDayIsNotSameAsStartDay = startDay !== endDay;

  if (startDayIsEndOfTheWeek && endDayIsNotSameAsStartDay) {
    return true;
  }

  return startDay < endDay;
};
