import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

import { getCurrentUserTwoFaRequirementMet } from 'selectors/session';

import { INPUT_HOURS_TYPES } from 'features/payroll/components/Earnings/constants';

// Feature Flags
export const getPayrollFeatureEnabled = (state, key) =>
  !!state.getIn(['session', 'payroll_flags', key]);

export const getPayrollFeatureEnabledWithHooks = key => state =>
  getPayrollFeatureEnabled(state, key);

// Dashboard
export const getDashboardData = state =>
  state.getIn(['payroll']).payrollDashboard;
export const getIsPayrollPastDue = state =>
  state.getIn(['payroll']).payrollDashboard.isPayrollPastDue;
export const getPayrollNeedsReviewEmployees = state =>
  state.getIn(['payroll']).payrollDashboard.needsReviewEmployees;
export const getIsFetchingDashboardData = state =>
  state.get('payroll').payrollDashboard.isFetching;
export const getHasEligibleEmployees = state =>
  state.get('payroll').payrollDashboard.hasEligibleEmployees;
export const getSubmissionDeadlineDate = state =>
  state.getIn(['payroll']).payrollDashboard.submissionDeadlineDate;
export const getAutopayrollSubmissionTimeOptions = state =>
  state.getIn(['payroll']).payrollDashboard.autopayrollSubmissionTimeOptions;
export const getPrimaryLocationTimeZone = state =>
  state.getIn(['payroll']).payrollDashboard.primaryLocationTimeZone;
export const getIsPayrollDueToday = state =>
  state.getIn(['payroll']).payrollDashboard.isPayrollDueToday;
export const getSubmissionDeadlineDateWithYear = state =>
  state.getIn(['payroll']).payrollDashboard.submissionDeadlineDateWithYear;
export const getIsRunOffCyclePayrollButtonDisabled = state =>
  state.getIn(['payroll']).payrollDashboard.isRunOffCyclePayrollButtonDisabled;
export const getIsRunStandardPayrollButtonDisabled = state =>
  state.getIn(['payroll']).payrollDashboard.isRunStandardPayrollButtonDisabled;

export const getImplementationComponents = state =>
  state.get('payroll').payrollDashboard.implementationComponents;

export const getShowEoYSection = state =>
  state.get('payroll').payrollDashboard.implementationMetadata
    .showEndOfYearSection;

export const getPayrollImplementationCompanyType = state =>
  state.get('payroll').payrollDashboard.implementationMetadata.companyType;

export const getShowPreNoteValidationBanner = state =>
  state.getIn(['session', 'payroll', 'showPreNoteValidationBanner']);

export const getMailTaxFormsReduxData = state =>
  state.get('payroll').payrollDashboard.mailTaxFormsData;

export const getReferralModal = state =>
  state.get('payroll').payrollDashboard.referralModal;

export const getIsReferralSent = state =>
  state.get('payroll').payrollDashboard.isReferralSent;

export const getMailTaxFormsDataPending = state =>
  state.get('payroll').payrollDashboard.mailTaxFormsDataPending;

// Payroll Recent Runs
export const getPayrollHistory = state =>
  state.getIn(['payroll']).payrollHistory.payrollRuns;
export const getIsFetchingPayrollHistory = state =>
  state.getIn(['payroll']).payrollHistory.isFetching;

// Payroll Tax Returns
export const getPayrollTaxReturns = state =>
  state.getIn(['payroll']).payrollTaxReturns.taxList;
export const getPayrollTaxPayments = state =>
  state.getIn(['payroll']).payrollTaxReturns.taxPayments;
export const getIsFetchingPayrollTaxReturns = state =>
  state.getIn(['payroll']).payrollTaxReturns.isFetching;

// Payroll Run
export const getOpenPayrollRunId = state =>
  state.getIn(['payroll']).payrollRun.openPayrollRunId;
export const getErrors = state => state.getIn(['payroll']).payrollRun.errors;
export const getIsFetching = state =>
  state.getIn(['payroll']).payrollRun.isFetching;
export const getIsUpdatingPaymentMethod = state =>
  state.getIn(['payroll']).payrollRun.isUpdatingPaymentMethod;
export const getIsUpdating = state =>
  state.getIn(['payroll']).payrollRun.isUpdating;
export const getPayPeriod = state =>
  state.getIn(['payroll']).payrollRun.payPeriod;
export const getActiveStep = state =>
  state.getIn(['payroll']).payrollRun.activeStep;
export const getHeaderSubmissionDeadline = state =>
  state.getIn(['payroll']).payrollRun.headerSubmissionDeadline;
export const getActualSubmissionDeadlineCountdown = state =>
  state.get('payroll').payrollRun.actualSubmissionDeadlineCountdown;
export const getIsPastActualSubmissionDeadline = state =>
  state.get('payroll').payrollRun.isPastActualSubmissionDeadline;
export const getHeaderPayday = state =>
  state.getIn(['payroll']).payrollRun.headerPayday;
export const getPayrollRunType = state =>
  state.getIn(['payroll']).payrollRun.runType;
export const getInputHours = state =>
  state.getIn(['payroll']).payrollRun.inputHours;
export const getIsManualHours = createSelector(
  getInputHours,
  inputHours => inputHours === INPUT_HOURS_TYPES.manual
);
export const getIsPayrollRunEditDisabled = createSelector(
  [
    getHasEligibleEmployees,
    getCurrentUserTwoFaRequirementMet,
    getIsRunOffCyclePayrollButtonDisabled,
    getIsRunStandardPayrollButtonDisabled,
  ],
  (
      hasEligibleEmployees,
      currentUserTwoFaRequirementMet,
      isRunOffCyclePayrollButtonDisabled,
      isRunStandardPayrollButtonDisabled
    ) =>
    runType =>
      !hasEligibleEmployees ||
      !currentUserTwoFaRequirementMet ||
      (runType === 'off_cycle'
        ? isRunOffCyclePayrollButtonDisabled
        : isRunStandardPayrollButtonDisabled)
);

export const getPayrollRunRoles = state =>
  state.getIn(['payroll']).payrollRun.roles;

// Gross Calculations View
export const getPayrollColumns = state =>
  state.getIn(['payroll']).payrollRun.columns;
export const getPayrollPayments = state =>
  state.getIn(['payroll']).payrollRun.payrollPayments;
export const getPayrollTotals = state =>
  state.getIn(['payroll']).payrollRun.totals;

// Net Pay View
export const getNetPayColumns = state =>
  state.getIn(['payroll']).payrollRun.netPayColumns;
export const getNetPayPayments = state =>
  state.getIn(['payroll']).payrollRun.netPayPayments;
export const getNetPayTotals = state =>
  state.getIn(['payroll']).payrollRun.netPayTotals;

// Submit View
export const getReviewPayday = state =>
  state.getIn(['payroll']).payrollRun.submitView.reviewPayday;
export const getHasManualPayments = state =>
  state.getIn(['payroll']).payrollRun.submitView.hasManualPayments;
export const getHasPaperCheckPayments = state =>
  state.getIn(['payroll']).payrollRun.submitView.hasPaperCheckPayments;
export const getNonSufficientFunds = state =>
  state.getIn(['payroll']).payrollRun.submitView.nonSufficientFunds;
export const getTotalManualPayments = state =>
  state.getIn(['payroll']).payrollRun.submitView.totalManualPayments;
export const getTotalDebitPayments = state =>
  state.getIn(['payroll']).payrollRun.submitView.totalDebitPayments;
export const getManualPaymentsDue = state =>
  state.getIn(['payroll']).payrollRun.submitView.manualPaymentsDue;
export const getPaymentsAlreadyReceivedTotal = state =>
  state.getIn(['payroll']).payrollRun.submitView.paymentsAlreadyReceivedTotal;

// Timecard Modal
export const getTimecardModalColumns = state =>
  state.getIn(['payroll']).payrollRun.timecardModalColumns;
export const getPayPeriodStartDate = state =>
  state.getIn(['payroll']).payrollRun.payPeriodStartDate;
export const getPayPeriodEndDate = state =>
  state.getIn(['payroll']).payrollRun.payPeriodEndDate;
export const getTimecards = state =>
  state.getIn(['payroll']).payrollRun.timecards;
export const getTimecardPayrollLocations = state =>
  state.getIn(['payroll']).payrollRun.timecards.locations;
export const getTimecardRoles = state =>
  state.getIn(['payroll']).payrollRun.timecards.roles;

// Off Cycle //
export const getIsFetchingOffCycleRunData = state =>
  state.get('payroll').payrollRun.offCycle.isFetching;

// General
export const getDirectDepositDisabled = state =>
  state.getIn(['payroll']).payrollRun.directDepositDisabled;

// Setup Form
export const getEmployeesForOptions = state =>
  state.getIn(['payroll']).payrollRun.offCycle.setupForm.employeesForOptions;
export const getCompanyStartDate = state =>
  state.getIn(['payroll']).payrollRun.offCycle.setupForm.companyStartDate;
export const getNextPayrollDates = state =>
  state.getIn(['payroll']).payrollRun.offCycle.setupForm.nextPayrollDates;
export const getHolidayDates = state =>
  state.getIn(['payroll']).payrollRun.offCycle.setupForm.holidays;

const getCalendarPayrollPeriods = state =>
  state.getIn(['payroll']).payrollRun.offCycle.setupForm.calendarPayrollPeriods;

export const getSetupFormValues = state =>
  state.getIn(['payroll']).payrollRun.offCycle.setupForm.values;

export const getCalendarPayrollPeriodsAsImmutable = createSelector(
  getCalendarPayrollPeriods,
  payrollPeriods => fromJS(payrollPeriods)
);
