import './CalendlyModal.scss';

import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { connect } from 'react-redux';
import Immutable from 'immutable';

import { createOneTimeEvent } from 'actions/session';

import {
  buildPayrollSalesTeamCalendlyUrl,
  CalendlyOrigins,
  calendlyUrlBuilderCommand,
  incrementOpenedCalendlyMetric,
  incrementScheduledCalendlyMetric,
  QueryParamsType,
} from 'features/calendlyEnterprise';

type CalendlyEnterpriseModalProps = {
  url: string;
  open: boolean;
  origin: CalendlyOrigins;
  handleCloseModal: () => void;
  queryParams?: QueryParamsType;
  isImplementationDashboard?: boolean;
  currentUser?: Immutable.Map<string, string>;
  onCreateOneTimeEvent?: (event: string, company: string) => void;
};

export const CalendlyEnterpriseModal = ({
  url,
  open,
  origin,
  queryParams,
  currentUser,
  handleCloseModal,
  onCreateOneTimeEvent,
  isImplementationDashboard = false,
}: CalendlyEnterpriseModalProps) => {
  useCalendlyEventListener({
    onEventTypeViewed: () => {
      incrementOpenedCalendlyMetric(origin);
    },
    onEventScheduled: () => {
      if (isImplementationDashboard) {
        onCreateOneTimeEvent?.('schedule_call_finish', 'Company');
      }
      incrementScheduledCalendlyMetric(origin);
    },
  });

  const root = document.getElementById('react-app-root') as HTMLElement;

  const calendlyUrl = currentUser
    ? buildPayrollSalesTeamCalendlyUrl(
        calendlyUrlBuilderCommand({ url, currentUser, queryParams })
      )
    : url;

  return (
    <PopupModal
      open={open}
      url={calendlyUrl}
      rootElement={root}
      onModalClose={handleCloseModal}
      data-testid="calendly-enterprise-modal"
    />
  );
};

export default connect(null, {
  onCreateOneTimeEvent: createOneTimeEvent,
})(CalendlyEnterpriseModal);
