import { createSlice } from '@reduxjs/toolkit';

import { SLICE_NAME } from './constants';
import { downgradeTier } from './thunks';

const INITIAL_STATE = {
  shopifyUrl: '',
  shopifyTierDowngradeError: '',
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState: INITIAL_STATE,

  reducers: {
    closeShopifyTierDowngradedBanner: () => INITIAL_STATE,
  },

  extraReducers: builder => {
    builder.addCase(downgradeTier.fulfilled, (state, { payload }) => {
      state.shopifyUrl = payload.url;
    });

    builder.addCase(downgradeTier.rejected, (state, { payload }) => {
      state.shopifyUrl = payload.url;
      state.shopifyTierDowngradeError = payload.error;
    });
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
