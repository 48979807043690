import { createSelector } from 'reselect';

import {
  getCurrentUserHasUsedMobile,
  getCurrentUserIsManagerial,
  getCurrentUserIsOwner,
} from 'selectors/session';

export const getShowDownloadAppWidgetInMyWeek = createSelector(
  getCurrentUserIsOwner,
  getCurrentUserIsManagerial,
  getCurrentUserHasUsedMobile,
  (isOwner, isManagerial, hasUsedMobile) =>
    !isOwner && !isManagerial && !hasUsedMobile
);
