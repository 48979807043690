import { getTimeDifference } from 'util/dateTime';

function shiftSortCompare(a, b) {
  const t1 = a.attributes.startAt;
  const t2 = b.attributes.startAt;

  if (t1 !== t2) {
    return new Date(t1) < new Date(t2) ? -1 : 1;
  }

  const td1 = getTimeDifference(a.attributes.startAt, a.attributes.endAt);
  const td2 = getTimeDifference(b.attributes.startAt, b.attributes.endAt);

  if (t1 === t2 && td1 !== td2) {
    return td1 > td2 ? -1 : 1;
  }

  if (a.attributes.isOpen !== b.attributes.isOpen) {
    if (a.attributes.isOpen) {
      return -1;
    } else if (b.attributes.isOpen) {
      return 1;
    }
  }

  if (a.attributes.roleName !== b.attributes.roleName) {
    if (!a.attributes.roleName) {
      return -1;
    } else if (!b.attributes.roleName) {
      return 1;
    }
    return a.attributes.roleName.localeCompare(b.attributes.roleName);
  }

  return parseInt(a.id, 10) < parseInt(b.id, 10) ? -1 : 1;
}

export default shiftSortCompare;
