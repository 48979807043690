import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';
import { fromJS } from 'immutable';

import { fetchPayrollIneligibleEmployees } from 'features/payroll/DashboardView/payrollDashboardSlice';

import { deleteTimecard, submitTimecardData } from '../TimecardModal/slices';

import * as thunks from './thunks';

export const fetchTimesheetsInitialData = createAsyncThunk(
  'dailyReview/fetchTimesheetsInitialData',
  () => fetchJSON(`/timesheets/timesheets_initial_data`)
);

const slice = createSlice({
  name: 'dailyReview',

  initialState: fromJS({
    date: null,
    rows: [],
    columns: [],
    locationDepartments: [],
    isInitialDataLoading: true,
    isRowDataLoading: true,
    firstTimeGuideModalOpen: false,
    firstTimeGuideTooltipOpen: false,
    filters: {
      roles: [],
      employee: null,
      issues: [],
    },
  }),

  reducers: {
    setFirstTimeGuideModalOpen: (state, { payload }) =>
      state.set('firstTimeGuideModalOpen', payload),

    setFirstTimeGuideTooltipOpen: (state, { payload }) =>
      state.set('firstTimeGuideTooltipOpen', payload),

    setInitialDate: (state, { payload }) => state.set('date', payload),

    deleteDailyReviewRow: (state, { payload }) => {
      const row = state.get('rows').findIndex(r => r.get('id') === payload);
      if (row === -1) {
        return state;
      }
      return state.set('rows', state.get('rows').delete(row));
    },

    setFilters: (state, { payload }) => {
      const localStorageFilters = JSON.parse(
        localStorage.getItem('dailyReviewFilters') || '{}'
      );
      const newFilters = { ...localStorageFilters, ...payload };
      localStorage.setItem('dailyReviewFilters', JSON.stringify(newFilters));

      return state.mergeIn(['filters'], fromJS(payload));
    },
    // initialize filters from local storage
    initializeFilters: state =>
      state.mergeIn(
        ['filters'],
        fromJS(JSON.parse(localStorage.getItem('dailyReviewFilters')) || {})
      ),
  },

  extraReducers: builder => {
    builder.addCase(
      thunks.fetchDailyReviewRows.fulfilled,
      (state, { payload }) =>
        state
          .set('rows', fromJS(payload.rows))
          .set('locationEmployees', fromJS(payload.employees))
          .set(
            'firstTimeGuideModalOpen',
            payload.show_first_time_daily_review_page_tooltip
          )
          .set('isRowDataLoading', false)
    );

    builder.addCase(thunks.fetchDailyReviewRows.pending, state =>
      state.set('isRowDataLoading', true)
    );

    builder.addCase(
      fetchTimesheetsInitialData.fulfilled,
      (state, { payload }) =>
        state
          .set('locationRoles', fromJS(payload.roles))
          .set('locationIssues', fromJS(payload.issues))
    );

    builder.addCase(thunks.fetchDailyReviewLocationData.pending, state =>
      state.set('isInitialDataLoading', true)
    );

    builder.addCase(thunks.fetchDailyReviewLocationData.fulfilled, state =>
      state.set('isInitialDataLoading', false)
    );

    builder.addCase(thunks.fetchDailyReviewLocationData.rejected, state =>
      state.set('isInitialDataLoading', false)
    );

    builder.addCase(
      fetchPayrollIneligibleEmployees.fulfilled,
      (state, action) =>
        state.set('needsReviewEmployees', fromJS(action.payload))
    );

    builder.addCase(thunks.updateDate.fulfilled, (state, { payload }) =>
      state.set('date', payload)
    );

    builder.addCase(
      thunks.updateDailyReviewRow.fulfilled,
      (state, { payload }) => {
        const row = state
          .get('rows')
          .findIndex(r => r.get('id') === payload.id);
        if (row === -1) {
          return state;
        }
        return state.setIn(['rows', row], fromJS(payload));
      }
    );

    builder.addCase(submitTimecardData.fulfilled, (state, action) => {
      const row = state
        .get('rows')
        .findIndex(r => r.get('id') === action.payload.id);

      if (row === -1) {
        return state.set(
          'rows',
          state.get('rows').push(fromJS(action.payload))
        );
      }

      return state.setIn(['rows', row], fromJS(action.payload));
    });

    builder.addCase(deleteTimecard.fulfilled, (state, action) => {
      if (!action.payload) return state;
      const row = state
        .get('rows')
        .findIndex(r => r.get('id') === action.payload.id);
      if (row === -1) {
        return state;
      }
      if (!action.payload.user_id)
        return state.set('rows', state.get('rows').delete(row));

      return state.setIn(['rows', row], fromJS(action.payload));
    });

    builder.addCase('TIMECARD/APPROVE_TIMECARD_SUCCESS', (state, action) => {
      const { shiftIds, approval } = action.meta;

      shiftIds.forEach(id => {
        const row = state.get('rows').findIndex(r => r.get('id') === id);
        if (row === -1) {
          return state;
        }
        state = state.mergeIn(['rows', row], {
          approved: approval,
        });
      });

      return state;
    });
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
