import { ACTION_CREATE } from 'fe-core/module-constants';
import { createResource } from 'fe-core/Resources';
import { create } from 'fe-core/Resources/actions';

const ScheduleTemplate = createResource({
  name: 'scheduleTemplate',
  path: '/schedule_builder/templates',
});

ScheduleTemplate.apply = ({ id, payload }) =>
  create({
    path: `/schedule_builder/templates/${id}/apply`,
    name: 'scheduleTemplate',
    action: ACTION_CREATE,
    payload,
  });

ScheduleTemplate.repeat = payload =>
  create({
    path: `/schedule_builder/templates/repeat`,
    name: 'scheduleTemplate',
    action: ACTION_CREATE,
    payload,
  });

export default ScheduleTemplate;
